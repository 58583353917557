import React from 'react'
import { Container } from '../../../../UIHelpers/Container'
import { Suptitle } from '../../../../UIHelpers/Suptitle'
import { MainDishes } from '../MainDishes.section/MainDishes'
import * as Styles from './MainMenu.styles'

interface Props {}

export const MainMenu = ({}: Props) => {
  return (
    <>
      <Styles.MainMenu>
        <Container>
          <Suptitle content="Основное меню" />
          <MainDishes />
        </Container>
      </Styles.MainMenu>
    </>
  )
}
