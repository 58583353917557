import { all } from 'redux-saga/effects'
import { categorySaga } from './ducks/category/sagas'
import { categorysSaga } from './ducks/categorys/sagas'
import { goodSaga } from './ducks/good/sagas'
import { goodsSaga } from './ducks/goods/sagas'
import { postSaga } from './ducks/post/sagas'
import { postsSaga } from './ducks/posts/sagas'
import { saleSaga } from './ducks/sale/sagas'
import { salesSaga } from './ducks/sales/sagas'
import { userSaga } from './ducks/user/sagas'

export default function* rootSaga(): any {
  yield all([
    postsSaga(),
    postSaga(),
    goodsSaga(),
    goodSaga(),
    salesSaga(),
    saleSaga(),
    categorysSaga(),
    categorySaga(),
    userSaga(),
  ])
}
