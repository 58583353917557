import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const QuestionForm = styled.section`
  padding: 0 0 140px 0;
  color: #171819;
  background: transparent;
  @media (max-width: 768px) {
    padding: 0 0 100 0;
  }
  @media (max-width: 480px) {
    padding: 0 0 140px 0;
  }
`
export const BookingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #171819;
  @media (max-width: 1440px) {
    justify-content: center;
  }
`

export const Title = styled.h2`
  font-size: 30px;
  line-height: 150%;
  font-weight: normal;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  max-width: 1088px;
  margin: 0 0 40px 0;
`

export const Part1 = styled.div`
  max-width: 655px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1440px) {
    max-width: none;
    width: 780px;
    text-align: center;
  }
`
export const Info = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  @media (max-width: 1440px) {
    justify-content: space-between;
  }
`

export const PartText = styled.div`
  font-size: 20px;
  line-height: 150%;
  color: #171819;
  font-style: normal;
  font-weight: 300;
  margin-bottom: 100px;
`
export const Advantages = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1440px) {
    justify-content: space-between;
    margin-bottom: 100px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

export const Part2 = styled.div`
  max-width: 780px;
  form {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 900px;
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const InputWrapper = styled.div<{ query: string }>`
  input[type='tel'] {
    background: none;
    outline: none;
    width: 380px;
    box-sizing: border-box;
    padding-bottom: 15px;
    border: none;
    color: #171819;
    border-bottom: 1px solid #171819;
    @media (max-width: 480px) {
      width: 100%;
    }
  }

  position: relative;
  margin-right: 20px;

  &:nth-of-type(2n) {
    margin-right: 0px;
  }

  .MuiFormControl-root {
    position: absolute;
    top: -34px;
    margin-left: 0;

    .MuiInput-underline {
      &::before,
      &::after {
        display: none !important;
      }
      border-bottom: none !important;
    }
    .MuiFormHelperText-root {
      margin-top: 8px;
      font-family: 'Commissioner', sans-serif !important;
    }
    .MuiInputBase-inputAdornedEnd {
      border: none !important;
    }
    .MuiInputBase-input,
    .MuiInput-input {
      color: #171819;
      border-bottom: none !important;
      &::before,
      &::after {
        display: none !important;
      }
      @media (max-width: 480px) {
        width: 100%;
      }
    }
    .MuiSvgIcon-root {
      color: #171819 !important;
    }
    .MuiFormLabel-filled {
      color: #171819;
      font-size: 16px;
      width: 320px;
      margin-bottom: 40px;
      font-family: 'Commissioner', sans-serif !important;
    }
  }

  margin-bottom: 50px;
  ${({ query }) => {
    if (query) {
      return css`
        input {
          border-bottom: 1px solid #171819;
        }
        input ~ label {
          font-size: 12px;
          line-height: 150%;
          color: #171819;
          margin: 0;
          margin-bottom: 22px;
        }
      `
    }
    return css``
  }}

  input[type='text']:focus ~ label {
    font-size: 12px;
    line-height: 150%;
    color: #171819;
    margin: 0;
    margin-bottom: 22px;
  }

  input[type='text']:focus {
    border-bottom: 1px solid #171819;
  }

  @media (max-width: 420px) {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    margin-right: 0;
  }
`

export const CommentWrapper = styled.div<{ query: string }>`
  input {
    background: none;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 15px;
    border: none;
    color: #171819;
    border-bottom: 1px solid #171819;
    @media (max-width: 480px) {
      width: 100%;
    }
  }

  position: relative;

  &:nth-of-type(2n) {
    margin-right: 0px;
  }

  .MuiFormControl-root {
    position: absolute;
    top: -34px;
    margin-left: 0;

    .MuiInput-underline {
      &::before,
      &::after {
        display: none !important;
      }
      border-bottom: none !important;
    }
    .MuiFormHelperText-root {
      margin-top: 8px;
      font-family: 'Commissioner', sans-serif !important;
    }
    .MuiInputBase-inputAdornedEnd {
      border: none !important;
    }
    .MuiInputBase-input,
    .MuiInput-input {
      color: #171819;
      border-bottom: none !important;
      &::before,
      &::after {
        display: none !important;
      }
      @media (max-width: 480px) {
        width: 100%;
      }
    }
    .MuiSvgIcon-root {
      color: #171819 !important;
    }
    .MuiFormLabel-filled {
      color: #171819;
      font-size: 16px;
      width: 320px;
      margin-bottom: 40px;
      font-family: 'Commissioner', sans-serif !important;
    }
  }

  margin-bottom: 50px;
  ${({ query }) => {
    if (query) {
      return css`
        input {
          border-bottom: 1px solid #171819;
        }
        input ~ label {
          font-size: 12px;
          line-height: 150%;
          color: #171819;
          margin: 0;
          margin-bottom: 22px;
        }
      `
    }
    return css``
  }}

  input[type='text']:focus ~ label {
    font-size: 12px;
    line-height: 150%;
    color: #171819;
    margin: 0;
    margin-bottom: 22px;
  }

  input[type='text']:focus {
    border-bottom: 1px solid #171819;
  }

  @media (max-width: 420px) {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    margin-right: 0;
  }
`
export const InputsWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

export const Label = styled.label`
  position: absolute;
  bottom: 65%;
  left: 0;
  pointer-events: none;
  cursor: text;
  user-select: none;
  font-weight: 300;
  z-index: 0;
  line-height: 150%;
  font-size: 16px;
  transition: 0.2s;
  color: #171819;
`

export const Socials = styled.div`
  display: flex;
  justify-content: space-between;
  width: 317px;
  margin-bottom: 15px;
  position: relative;
  @media (max-width: 480px) {
    width: 100%;
  }
  @media (max-width: 1440px) {
    margin-bottom: 30px;
  }
`

export const Popup = styled.div`
  position: absolute;
  top: 65px;
  left: 5%;
  z-index: 100;
  width: 129px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('/images/contacts/form/popup.svg');
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #f1f1f1;
  @media (max-width: 768px) {
    width: 55px;
    height: 27px;
    padding-top: 3px;
    font-size: 10px;
    left: 0;
  }
`

export const Social = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 1px solid #171819;
  width: 56px;
  height: 56px;
  box-sizing: border-box;
  transition: 0.3s;
  .icons {
    width: 28px;
    height: 28px;
    fill: #171819;
  }
  cursor: pointer;
  .popup {
    transition: 0.3s;
    opacity: 0;
  }
  &:hover {
    .popup {
      opacity: 1 !important;
    }
    background: #414141;
  }
`

export const Error = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 150%;
  color: tomato;
  font-weight: normal;
  margin: 0;
  margin-top: 4px;
  @media (max-width: 1440px) {
    margin-top: 14px;
  }
`

export const ErrorSocial = styled.p`
  position: absolute;
  top: -40px;
  margin: 0;
  font-size: 12px;
  line-height: 150%;
  font-weight: normal;
  margin: 0;
  margin-top: 4px;
`

export const Input = styled.input`
  background: none;
  outline: none;
  width: 380px;
  box-sizing: border-box;
  padding-bottom: 15px;
  border: none;
  color: #171819;
  border-bottom: 1px solid #171819;
  @media (max-width: 480px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Checkbox = styled.div`
  margin-left: 83px;

  @media (max-width: 1440px) {
    margin-left: 0;
    width: 260px;
    margin-bottom: 10px;
  }
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
  label {
    font-weight: normal;
    font-size: 10px;
    color: #171819;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #f25003;
    padding: 10px;
    display: inline-block;
    position: relative;
    background: transparent;
    transition: 0.2s;
    cursor: pointer;
    width: 22px;
    margin-right: 20px;
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #ffffff;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  input:checked + label:before {
    background: #f25003;
    width: 22px;
  }
`
