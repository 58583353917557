import React from 'react';
import * as Styles from './Preloader.styles';

export const Preloader = () => {
  return (
    <Styles.Preloader>
      <div className="container">
        <div className="spinner">
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
          <div className="spinner-item"></div>
        </div>
      </div>
    </Styles.Preloader>
  );
};
