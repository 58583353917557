import styled from '@emotion/styled'

export const Wrapper = styled.div`
  * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url('/images/shared/default-background.webp');
  font-family: 'GTEestiProDisplay' sans-serif;
  h2 {
    font-size: 30px;
    text-transform: uppercase;
    line-height: 150%;
    @media (max-width: 768px) {
      font-size: 26px;
    }
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
`
