import React, { useState } from 'react'

import * as Styles from './CardsContainer.styles'

interface Props {
  children: any
  categoryName: string
}

export const CardsContainer = ({ children, categoryName }: Props) => {
  const childrenReact = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return child
    }
  })

  const height = document.documentElement.clientWidth <= 768 ? 650 : 1050

  const [activeMore, setActiveMore] = useState<boolean>(true)

  return (
    <>
      {childrenReact.length > 6 && (
        <Styles.ShowMore
          style={
            childrenReact.length > 6 && !activeMore
              ? {
                  color: '#f25003',
                }
              : {}
          }
          onClick={() => setActiveMore(!activeMore)}
        >
          <svg
            className="show-more"
            style={
              childrenReact.length > 6 && !activeMore
                ? {
                    transform: 'rotate(180deg)',
                    fill: '#f25003',
                  }
                : {}
            }
          >
            <use href="/icons/menu/sprite.svg#show-more"></use>
          </svg>
          {activeMore ? 'Показать больше' : 'Показать меньше'}
        </Styles.ShowMore>
      )}
      <Styles.CardsContainer
        style={
          childrenReact.length > 6 && activeMore
            ? { overflow: 'hidden', maxHeight: `${height}px` }
            : {}
        }
      >
        {childrenReact.length ? (
          childrenReact
        ) : (
          <div style={{ marginTop: '-70px' }}>{categoryName} пока нет</div>
        )}
      </Styles.CardsContainer>
    </>
  )
}
