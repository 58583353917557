import { call, put, takeEvery } from 'redux-saga/effects'
import { PostsApi } from '../../../services/api/postsApi'
import { LoadingStatus } from '../../types'
import { IPost } from '../posts/contracts/state'
import {
  setDeletePostData,
  setPostData,
  setPostLoadingStatus,
} from './actionCreators'
import {
  AddPostActionInterface,
  DeletePostDataActionInterface,
  FetchPostDataActionInterface,
  PostActionsType,
  UpdatePostDataActionInterface,
} from './contracts/actionTypes'

export function* fetchPostDataRequest({
  payload: postId,
}: FetchPostDataActionInterface): any {
  try {
    const data: IPost = yield call(PostsApi.fetchPostData, postId)
    yield put(setPostData(data))
  } catch (error) {
    yield put(setPostLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* updatePostDataRequest({
  id: postId,
  payload: IPost,
}: UpdatePostDataActionInterface): any {
  try {
    const data: IPost = yield call(PostsApi.updatePostData, IPost, postId)
    yield put(setPostData(data))
  } catch (error) {
    yield put(setPostLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* addPostRequest({ payload: IPost }: AddPostActionInterface) {
  try {
    const data: IPost = yield call(PostsApi.addPost, IPost)
    yield put(setPostData(data))
  } catch (error) {
    yield put(setPostLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* deletePostDataRequest({
  payload: postId,
}: DeletePostDataActionInterface) {
  try {
    yield call(PostsApi.deletePost, postId)
    yield put(setDeletePostData())
  } catch (error) {
    yield put(setPostLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* postSaga() {
  yield takeEvery(PostActionsType.FETCH_POST_DATA, fetchPostDataRequest)
  yield takeEvery(PostActionsType.UPDATE_POST_DATA, updatePostDataRequest)
  yield takeEvery(PostActionsType.ADD_POST, addPostRequest)
  yield takeEvery(PostActionsType.DELETE_POST_DATA, deletePostDataRequest)
}
