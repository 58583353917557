import styled from '@emotion/styled'

export const Contacts = styled.section`
  padding: 100px 0 100px 0;
  color: #171819;
  background: #171819;
  @media (max-width: 768px) {
    padding: 80px 0 80px 0;
  }
  @media (max-width: 480px) {
    padding: 60px 0 60px 0;
  }
`
export const ContactsWrapper = styled.div`
  color: #f1f1f1;
`
export const SupTitle = styled.div`
  color: rgba(23, 24, 25, 0.4);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-bottom: 40px;
  color: rgba(241, 241, 241, 0.4);
  @media (max-width: 768px) {
    font-size: 12px;
    margin: 0 0 30px 0;
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
`
export const Title = styled.h2`
  line-height: 150%;
  font-weight: normal;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  max-width: 1088px;
  margin: 0 0 20px 0;
  color: #f1f1f1;
  @media (max-width: 768px) {
    margin: 0 0 25px 0;
  }
`
export const ContactsBlocks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1439px) {
    flex-wrap: wrap;
  }
`
export const ContactsBlock = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 657px;
  &:last-of-type {
    max-width: unset;
    width: 781px;
  }
  @media (max-width: 1024px) {
    &:first-of-type {
      margin-bottom: 80px;
    }
  }
`

export const BookingText = styled.div`
  margin-bottom: 60px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 150%;
  color: rgba(241, 241, 241, 0.8);
  @media (max-width: 768px) {
    font-size: 16px;
    margin: 0 0 40px 0;
    max-width: 280px;
  }
`
export const PhoneBlock = styled.div`
  display: flex;
  margin-bottom: 54px;
  align-items: center;
  text-decoration: none;

  @media (max-width: 480px) {
    margin-bottom: 36px;
  }
`
export const PhoneText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 60px;
  line-height: 100%;
  color: #f1f1f1;
  height: fit-content;
  text-decoration: none;
  transition: 0.3s;
  @media (min-width: 1024px) {
    &:hover {
      color: #f25003;
    }
  }
  @media (max-width: 768px) {
    font-size: 42px;
  }
  @media (max-width: 480px) {
    font-size: 21px;
  }
`
export const AdditionalInfoBlock = styled.div`
  @media (max-width: 768px) {
    &:first-of-type {
      margin-right: 60px;
    }
  }
  @media (max-width: 480px) {
    &:first-of-type {
      margin: 0 0 30px 0;
    }
  }
`
export const AdditionalInfoBlocks = styled.div`
  display: flex;
  @media (min-width: 1024px) {
    justify-content: space-between;
  }
  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const BlockText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  color: #f1f1f1;
  @media (max-width: 1440px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`
export const BlockSup = styled.div`
  margin-bottom: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: rgba(241, 241, 241, 0.6);
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
`
