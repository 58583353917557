import React from 'react';
import { IPost } from '../../../../../../store/ducks/posts/contracts/state';
import { useHistory } from 'react-router';

interface Props {
  post: IPost;
  setId: any;
}

import * as Styles from './PostPreview.styles';
import { formatDate } from '../../../../../../utils/formatDate';
import { Link } from 'react-router-dom';
import { getMediaLink } from '../../../../../../utils/mediaHelper';

export const Post: React.FC<Props> = ({ post, setId }: Props) => {
  const onChange = (e: any): void => {
    const checked = (e.target as HTMLInputElement).checked;
    if (checked) {
      setId(() => ({
        id: post._id,
        type: 'add',
      }));
    } else {
      setId(() => ({
        id: post._id,
        type: 'delete',
      }));
    }
  };

  return (
    <Styles.Post>
      <div>
        <Styles.TitleWrap>
          <Link to={`/admin/post/edit/${post._id}`}>
            <Styles.Title>{post.title}</Styles.Title>
          </Link>
          <Styles.InputWrap>
            <Styles.Input id={post._id} onChange={onChange} type="checkbox" />
            <Styles.Delete htmlFor={post._id}>Удалить</Styles.Delete>
          </Styles.InputWrap>
        </Styles.TitleWrap>
        <Link to={`/admin/post/edit/${post._id}`}>
          <Styles.Img src={getMediaLink(post.title_photo)} alt="" />
        </Link>
      </div>
      <div>
        <Styles.CreatedAt>
          Создано {formatDate(new Date(post.createdAt || '1'))} назад
        </Styles.CreatedAt>

        <Styles.UpdatedAt>
          Обновлено {formatDate(new Date(post.updatedAt || '1'))} назад
        </Styles.UpdatedAt>
      </div>
    </Styles.Post>
  );
};
