import React from "react";

interface Props {
  isErrorPage?: boolean;
}
import * as Styles from "./Footer.styles";
import { Container } from "../../../UIHelpers/Container";
import { Button } from "../../../UIHelpers/Buttons";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { links } from "../Header/Header";

export const Footer = ({ isErrorPage }: Props) => {
  return (
    <>
      <Styles.Footer>
        <Styles.FooterWrapper>
          <Container>
            <Styles.Part1>
              <Styles.LogoWrapper>
                <Link to="/">
                  <Styles.Logo src="/images/shared/logo.webp"></Styles.Logo>
                </Link>
              </Styles.LogoWrapper>
              <Styles.Navbar>
                {links.map((link) => (
                  <Styles.NavbarItem key={link.name}>
                    <Link
                      style={
                        !link.link
                          ? { color: "#757575", cursor: "default" }
                          : {}
                      }
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </Styles.NavbarItem>
                ))}
              </Styles.Navbar>

              <Styles.PhoneNumber>
                <a href="tel:+7(964)-322-48-49">+7 (964) 322-48-49</a>
              </Styles.PhoneNumber>

              <Styles.ButtonsContainer>
                <ScrollLink to="contacts">
                  <Button
                    typeButton="rounded"
                    background="#F1F1F1 url('/icons/shared/phone.svg') center no-repeat"
                    backgroundSize="16px 16px"
                    backgroundSizeMobile="14px 14px"
                    hoverBackground="#f25003 url('/icons/shared/phone-white.svg') center no-repeat"
                    additionalStyles="margin-right: 20px;  @media (max-width: 480px) {
                    display:none
                  }"
                  />
                </ScrollLink>

                <ScrollLink to="map">
                  <Button
                    typeButton="rounded"
                    background="#F1F1F1 url('/icons/shared/map-mark.svg') center no-repeat"
                    img="/icons/home/mapMark.svg"
                    additionalStyles="margin-right: 20px;  @media (max-width: 480px) {
        display:none
      }"
                    backgroundSize="20px 20px"
                    backgroundSizeMobile="17px 17px"
                    hoverBackground="#f25003 url('/icons/shared/map-mark-white.svg') center no-repeat"
                  ></Button>
                </ScrollLink>
                {isErrorPage ? (
                  <Link to="/#booking">
                    <Button
                      typeButton="normal"
                      background="#F1F1F1"
                      color="#171819"
                      additionalStyles="line-height: 150%; letter-spacing: 0.08em; font-weight: 700; @media (max-width: 768px) {
                    width:210px;
                    height:40px;
                  };"
                      content="ЗАБРОНИРОВАТЬ СТОЛИК"
                      hoverBackground="#f25003"
                      hoverColor="#F1F1F1"
                    ></Button>
                  </Link>
                ) : (
                  <ScrollLink to="booking">
                    <Button
                      typeButton="normal"
                      background="#F1F1F1"
                      color="#171819"
                      additionalStyles="line-height: 150%; letter-spacing: 0.08em; font-weight: 700; @media (max-width: 768px) {
                    width:210px;
                    height:40px;
                  };"
                      content="ЗАБРОНИРОВАТЬ СТОЛИК"
                      hoverBackground="#f25003"
                      hoverColor="#F1F1F1"
                    ></Button>
                  </ScrollLink>
                )}
              </Styles.ButtonsContainer>
            </Styles.Part1>
          </Container>
          <Styles.Part2>
            <Container className="container">
              <Styles.FooterTextBlock>
                <Styles.FooterText>
                  © 2020 - BRICK GASTRO BAR. Все права защищены
                </Styles.FooterText>
                <Styles.FooterText>
                  <Link target="_blank" to="/privacy">
                    Политика конфиденциальности
                  </Link>
                </Styles.FooterText>
              </Styles.FooterTextBlock>
              <Styles.FooterTextBlock>
                <Styles.FooterTextDev>
                  <a target="_blank" href="https://newshift.ru/">
                    Дизайн и разработка:
                    <img src="/icons/logo.svg" alt="newShift" />
                  </a>
                </Styles.FooterTextDev>
              </Styles.FooterTextBlock>
            </Container>
            <Container>
              <Styles.AppsLinks>
                <a href="https://play.google.com/store/apps/details?id=ru.brickbar.app">
                  <img src="icons/bonus/googleplay.svg" alt="" />
                </a>
                  <a href="https://apps.apple.com/ru/app/%D0%B3%D0%B0%D1%81%D1%82%D1%80%D0%BE%D0%B1%D0%B0%D1%80-brick/id1574355571?l=ru">
                      <img src="icons/bonus/appstore.svg" alt="" />
                  </a>
                <a href="/brick-bar-v1.1.10.apk" download>
                  <img src="icons/bonus/apk-download.png" alt="Direct APK download" />
                </a>
              </Styles.AppsLinks>
            </Container>
          </Styles.Part2>
        </Styles.FooterWrapper>
      </Styles.Footer>
    </>
  );
};
