import React from 'react'
import { Footer } from '../../../shared/containers/Footer'
import { Header } from '../../../shared/containers/Header'
import { Main } from '../components/Main'
import MetaTags from 'react-meta-tags'
interface Props {}

import * as Styles from './ErrorPage.styles'
export const ErrorPage: React.FC = ({}: Props) => {
  return (
    <>
      <MetaTags>
        <title>BRICK GASTRO BAR | Страница 404</title>
      </MetaTags>
      <Styles.Wrapper>
        <Header isErrorPage={true} />
        <Main />
        <Footer isErrorPage={true} />
      </Styles.Wrapper>
    </>
  )
}
