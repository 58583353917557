import React, { useEffect, useRef, useState } from 'react';

import * as Styles from './MainDishes.styles';

import { IGood } from '../../../../store/ducks/goods/contracts/state';
import { Card } from '../Card';
import { MyLoader } from '../Preloader';
import { DataContext } from '../../../../Context/DataContext';
import { CardsContainer } from '../CardsContainer/';
import { Button } from '../../../../UIHelpers/Buttons';
import { Link } from 'react-scroll';

const filter1 = [
  {
    name: 'Холодные Закуски',
    filterType: 'PLACEHOLDER0',
  },
  {
    name: 'Горячие закуски',
    filterType: 'PLACEHOLDER123',
  },
  {
    name: 'Салаты',
    filterType: 'PLACEHOLDER1',
  },
  {
    name: 'Супы',
    filterType: 'PLACEHOLDER2',
  },
  {
    name: 'Бургеры',
    filterType: 'PLACEHOLDER3',
  },
  {
    name: 'Горячие блюда',
    filterType: 'PLACEHOLDER4',
  },
  {
    name: 'Стейки',
    filterType: 'PLACEHOLDER5',
  },
  {
    name: 'Шашлыки',
    filterType: 'PLACEHOLDER51',
  },
  {
    name: 'Гарниры',
    filterType: 'PLACEHOLDER25',
  },
  {
    name: 'Пивные закуски',
    filterType: 'PLACEHOLDER6',
  },
  {
    name: 'Десерты',
    filterType: 'PLACEHOLDER6',
  },
  {
    name: 'Соусы',
    filterType: 'PLACEHOLDER7',
  },
];

const filter2 = [
  {
    name: 'НА КРАНАХ (1/2 PINT / PINT)',
    filterType: 'PLACEHOLDER0',
  },
];

const filter3 = [
  {
    name: 'Виски',
    filterType: 'PLACEHOLDER0',
  },
  {
    name: 'БРЕНДИ / ГРАППА / КОНЬЯК / ПИСКО',
    filterType: 'PLACEHOLDER2',
  },
  {
    name: 'ВЕРМУТ',
    filterType: 'PLACEHOLDER3',
  },
  {
    name: 'ВОДКА',
    filterType: 'PLACEHOLDER4',
  },
  {
    name: 'ДЖИН',
    filterType: 'PLACEHOLDER5',
  },
  {
    name: 'РОМ',
    filterType: 'PLACEHOLDER6',
  },
  {
    name: 'ЛИКЁРЫ',
    filterType: 'PLACEHOLDER7',
  },
  {
    name: 'ТЕКИЛА',
    filterType: 'PLACEHOLDER8',
  },
  {
    name: 'ХЕРЕС',
    filterType: 'PLACEHOLDER9',
  },
  {
    name: 'Вино',
    filterType: 'PLACEHOLDER10',
  },
  {
    name: 'Коктейли',
    filterType: 'PLACEHOLDER12',
  },
  {
    name: 'Сеты шотов',
    filterType: 'PLACEHOLDER13',
  },
  {
    name: 'Настойки',
    filterType: 'PLACEHOLDER15',
  },
  {
    name: 'Шампанское',
    filterType: 'PLACEHOLDER14',
  },
];

const filter4 = [
  {
    name: 'Соки',
    filterType: 'PLACEHOLDER0',
  },
  {
    name: 'Свежевыжатые соки',
    filterType: 'PLACEHOLDER1',
  },
  {
    name: 'Содовая',
    filterType: 'PLACEHOLDER2',
  },
  {
    name: 'Чай',
    filterType: 'PLACEHOLDER3',
  },
  {
    name: 'Кофе',
    filterType: 'PLACEHOLDER4',
  },
  {
    name: 'Авторские Лимонады',
    filterType: 'PLACEHOLDER5',
  },
];

interface Props {}

export const MainDishes = ({}: Props) => {
  const { isLoadingGoods, goodData } = React.useContext(DataContext);

  const el1 = useRef<any>();
  const el2 = useRef<any>();
  const el3 = useRef<any>();
  const el4 = useRef<any>();

  const path = window.location.href;
  useEffect(() => {
    const currentElementId = path.split('#')[1]?.split('-')[0];

    switch (currentElementId) {
      case 'kitchen':
        el1?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'beer':
        el2?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'alcohol':
        el3?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      case 'beverages':
        el4?.current?.scrollIntoView({ behavior: 'smooth' });
        break;
      default:
        window.scrollTo(0, 0);
        break;
    }
  }, [path]);

  const [activeCategory1, setActiveCategory1] = useState({
    name: 'холодные закуски',
    active: 0,
  });
  const [activeCategory2, setActiveCategory2] = useState({
    name: 'Пиво на кранах',
    active: 0,
  });
  const [activeCategory3, setActiveCategory3] = useState({
    name: 'Виски',
    active: 0,
  });
  const [activeCategory4, setActiveCategory4] = useState({
    name: 'Соки',
    active: 0,
  });

  function categoryHandler(
    i: number,
    category: string,
    setActiveCategory: any
  ): void {
    setActiveCategory({
      active: i,
      name: category,
    });
  }

  function renderCards(activeCategory: any) {
    return [...goodData]
      ?.sort((a, b) => (a.order || 0) - (b.order || 0))
      .map((good: IGood) => {
        if (
          good.category.value.toLowerCase().trim() ===
          activeCategory.name.toLowerCase().trim()
        ) {
          return (
            <Card
              good={good}
              key={good._id + activeCategory.name.toLowerCase().trim()}
            />
          );
        }
      });
  }

  function renderFilters(
    filter: any,
    i: number,
    setActiveCategory: any,
    activeCategory: any
  ) {
    return (
      <Styles.SelectorItem
        className={activeCategory.active === i ? 'active' : 'no-active'}
        onClick={e => {
          categoryHandler(
            i,
            filter.name.toLowerCase().trim(),
            setActiveCategory
          );
        }}
        key={filter.name + filter.filterType}
      >
        {filter.name}
      </Styles.SelectorItem>
    );
  }

  return (
    <>
      <Styles.MainDishes>
        <Styles.ScrollBlock id="kitchen"></Styles.ScrollBlock>
        <Styles.Block>
          <Styles.Title>
            ШЕДЕВРЫ НАШИХ ПОВАРОВ — ТО, ЧТО НУЖНО ОБЯЗАТЕЛЬНО ПОПРОБОВАТЬ!
          </Styles.Title>
          <Link to="booking">
            <Button
              typeButton="normal"
              background="#F25003"
              color="#F1F1F1"
              hoverBackground="#f89577"
              content="забронировать столик"
              additionalStyles="margin-top: 30px;"
            />
          </Link>
        </Styles.Block>
        <Styles.Selector ref={el1}>
          {filter1.map((filter: any, i: number) =>
            renderFilters(filter, i, setActiveCategory1, activeCategory1)
          )}
        </Styles.Selector>

        <Styles.CardsContainer>
          {isLoadingGoods ? (
            new Array(3)
              .fill('')
              .map((_, i) => <MyLoader key={i * Math.random()} />)
          ) : (
            <CardsContainer
              nameFilter="kitchen"
              children={renderCards(activeCategory1)}
            />
          )}
        </Styles.CardsContainer>

        <Styles.ScrollBlock ref={el2} id="beer"></Styles.ScrollBlock>
        <Styles.Title>Пивная карта</Styles.Title>
        <Styles.Selector>
          {filter2.map((filter: any, i: number) =>
            renderFilters(filter, i, setActiveCategory2, activeCategory2)
          )}
        </Styles.Selector>

        <Styles.CardsContainer>
          {isLoadingGoods ? (
            new Array(3)
              .fill('')
              .map((_, i) => <MyLoader key={i * Math.random()} />)
          ) : (
            <CardsContainer
              nameFilter="beer"
              children={renderCards(activeCategory2)}
            />
          )}
        </Styles.CardsContainer>

        <Styles.ScrollBlock ref={el3} id="alcohol"></Styles.ScrollBlock>
        <Styles.Title>Крепкий алкоголь</Styles.Title>
        <Styles.Selector>
          {filter3.map((filter: any, i: number) =>
            renderFilters(filter, i, setActiveCategory3, activeCategory3)
          )}
        </Styles.Selector>

        <Styles.CardsContainer>
          {isLoadingGoods ? (
            new Array(3)
              .fill('')
              .map((_, i) => <MyLoader key={i * Math.random()} />)
          ) : (
            <CardsContainer
              nameFilter="alcohol"
              children={renderCards(activeCategory3)}
            />
          )}
        </Styles.CardsContainer>

        <Styles.ScrollBlock id="beverages" ref={el4}></Styles.ScrollBlock>
        <Styles.Title>Безалкогольные напитки</Styles.Title>
        <Styles.Selector>
          {filter4.map((filter: any, i: number) =>
            renderFilters(filter, i, setActiveCategory4, activeCategory4)
          )}
        </Styles.Selector>

        <Styles.CardsContainer>
          {isLoadingGoods ? (
            new Array(3)
              .fill('')
              .map((_, i) => <MyLoader key={i * Math.random()} />)
          ) : (
            <CardsContainer
              nameFilter="beverages"
              children={renderCards(activeCategory4)}
            />
          )}
        </Styles.CardsContainer>
      </Styles.MainDishes>
      <Styles.BottomBlock>
        <img src="/icons/menu/star.svg" />
        <span>
          Приведённая на данной странице информация не является публичной
          офертой. Актуальные цены вы можете уточнить непосредственно в нашем
          баре BRICK GASTRO BAR.
        </span>
      </Styles.BottomBlock>
      <Styles.BottomBlock2>
        <img src="/icons/menu/star.svg" />
        <span>Чрезмерное употребление алкоголя вредит Вашему здоровью.</span>
      </Styles.BottomBlock2>
    </>
  );
};
