import styled from '@emotion/styled'

export const Terms = styled.section`
  background: transparent;
  padding: 140px 0 210px 0;
  color: #171819;
  @media (max-width: 1600px) {
    padding: 140px 0 100px 0;
  }
  @media (max-width: 768px) {
    padding: 80px 0 40px 0;
  }
  @media (max-width: 480px) {
    padding: 60px 0 60px 0;
  }
`
export const TermsWrapper = styled.div`
  color: #171819;
`
export const SupTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-bottom: 40px;
  color: rgba(23, 24, 25, 0.4);
  @media (max-width: 768px) {
    font-size: 12px;
    margin: 0 0 30px 0;
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
`
export const Title = styled.h2`
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  max-width: 1088px;
  margin: 0 0 40px 0;
  color: #171819;
  @media (max-width: 768px) {
    margin: 0 0 25px 0;
  }
`
export const SubTitle = styled.div`
  line-height: 150%;
  font-weight: normal;
  letter-spacing: 0.08em;
  max-width: 748px;
  margin: 0 0 120px 0;
  color: rgba(23, 24, 25, 0.8);
  @media (max-width: 768px) {
    margin: 0 0 80px 0;
    font-size: 16px;
    max-width: 100%;
  }
  @media (max-width: 480px) {
    margin: 0 0 60px 0;
    font-size: 14px;
    max-width: 100%;
  }
`
export const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
export const Card = styled.div`
  display: flex;
  width: 513px;
  flex-direction: column;
  margin-bottom: 90px;
  @media (max-width: 1440px) {
    width: 420px;
  }
  @media (max-width: 768px) {
    width: 354px;
    margin: 0 0 60px 0;
  }
  @media (max-width: 480px) {
    width: 100%;
    margin: 0 0 30px 0;
  }
`
export const CardIcon = styled.img`
  display: block;
  margin-bottom: 30px;
  max-width: 86px;
  @media (max-width: 768px) {
    max-width: 60px;
    margin: 0 0 20px 0;
  }
  @media (max-width: 480px) {
    max-width: 57px;
  }
`
export const CardText = styled.div`
  font-size: 19px;
  color: rgba(23, 24, 25, 0.8);
  font-weight: normal;
  line-height: 150%;
  @media (max-width: 768px) {
    font-size: 15px;
  }
  @media (max-width: 480px) {
    font-size: 13px;
  }
`
