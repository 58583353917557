import { call, put, takeEvery } from 'redux-saga/effects'
import { GoodsApi } from '../../../services/api/goodsApi'
import { LoadingStatus } from '../../types'
import { IGood } from '../goods/contracts/state'
import {
  setDeleteGoodData,
  setGoodData,
  setGoodLoadingStatus,
} from './actionCreators'
import {
  AddGoodActionInterface,
  DeleteGoodDataActionInterface,
  FetchGoodDataActionInterface,
  GoodActionsType,
  UpdateGoodDataActionInterface,
} from './contracts/actionTypes'

export function* fetchGoodDataRequest({
  payload: goodId,
}: FetchGoodDataActionInterface): any {
  try {
    const data: IGood = yield call(GoodsApi.fetchGoodData, goodId)
    yield put(setGoodData(data))
  } catch (error) {
    yield put(setGoodLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* updateGoodDataRequest({
  id: goodId,
  payload: IGood,
}: UpdateGoodDataActionInterface) {
  try {
    const data: IGood = yield call(GoodsApi.updateGoodData, IGood, goodId)
    yield put(setGoodData(data))
  } catch (error) {
    yield put(setGoodLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* addGoodRequest({ payload: IGood }: AddGoodActionInterface) {
  try {
    const data: IGood = yield call(GoodsApi.addGood, IGood)
    yield put(setGoodData(data))
  } catch (error) {
    yield put(setGoodLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* deleteGoodDataRequest({
  payload: goodId,
}: DeleteGoodDataActionInterface) {
  try {
    yield call(GoodsApi.deleteGood, goodId)
    yield put(setDeleteGoodData())
  } catch (error) {
    yield put(setGoodLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* goodSaga() {
  yield takeEvery(GoodActionsType.FETCH_GOOD_DATA, fetchGoodDataRequest)
  yield takeEvery(GoodActionsType.UPDATE_GOOD_DATA, updateGoodDataRequest)
  yield takeEvery(GoodActionsType.ADD_GOOD, addGoodRequest)
  yield takeEvery(GoodActionsType.DELETE_GOOD_DATA, deleteGoodDataRequest)
}
