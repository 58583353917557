import produce, { Draft } from 'immer'
import { PostsActions, PostsActionType } from './contracts/actionTypes'
import { LoadingStatus } from '../../types'
import { PostsState } from './contracts/state'

const initialPostsState: PostsState = {
  items: [],
  loadingState: LoadingStatus.NEVER,
}

export const postsReducer = produce(
  (draft: Draft<PostsState>, action: PostsActions) => {
    switch (action.type) {
      case PostsActionType.SET_POSTS:
        draft.items = action.payload
        draft.loadingState = LoadingStatus.LOADED
        break

      case PostsActionType.FETCH_POSTS:
        draft.items = []
        draft.loadingState = LoadingStatus.LOADING
        break

      case PostsActionType.SET_LOADING_STATE:
        draft.loadingState = action.payload
        break

      default:
        break
    }
  },
  initialPostsState
)
