import { LinearProgress } from '@material-ui/core'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectIsPostLoading } from '../../../../../store/ducks/post/selectors'
import { fetchPosts } from '../../../../../store/ducks/posts/actionCreators'
import {
  selectIsPostsLoading,
  selectPostsItems,
} from '../../../../../store/ducks/posts/selectors'
import { Button } from '../../../components/Buttons'
import { Container } from '../../../components/Container'
import { Header } from '../../../components/Header'
import { Post } from '../components/PostPreview/PostPreview'

import MetaTags from 'react-meta-tags'

import * as Styles from './Posts.styles'

interface Props {}

interface ISetId {
  type: string
  id: string
}

export const PostList = ({}: Props) => {
  const dispatch = useDispatch()
  const posts = useSelector(selectPostsItems)
  const isLoading = useSelector(selectIsPostsLoading)
  const deleteLoading = useSelector(selectIsPostLoading)

  const [id, setId] = useState<ISetId>({
    type: '',
    id: '',
  })

  const [idDeleted, setIdDeleted] = useState<string[]>([])

  const [_, forceUpdate] = useReducer(x => x + 1, 0)

  useEffect(() => {
    dispatch(fetchPosts())
  }, [dispatch, deleteLoading])

  useEffect(() => {
    if (id.type === 'add') {
      idDeleted.push(id.id)
    } else {
      idDeleted.forEach((idD: string, i: number) => {
        if (idD === id.id) {
          idDeleted.splice(i, 1)
        }
      })
    }
    setIdDeleted(idDeleted)
    forceUpdate()
  }, [id])

  if (isLoading) {
    return (
      <>
        <MetaTags>
          <title>Админка | Загрузка...</title>
        </MetaTags>

        <Header collection={'post'} />

        <Container>
          <Styles.Loading>
            <p>{id.id ? 'Удаление постов' : 'Загрузка постов'}</p>
            <LinearProgress />
          </Styles.Loading>
        </Container>
      </>
    )
  }

  if (!posts.length) {
    return (
      <>
        <Header collection={'post'} />

        <Container>
          <Styles.Loading>
            <h1>Постов нет, добавьте посты</h1>
            <Link to={`/admin/post/add`}>
              <Button type={'add'} />
            </Link>
          </Styles.Loading>
        </Container>
      </>
    )
  }

  return (
    <>
      <MetaTags>
        <title>Админка | Посты</title>
      </MetaTags>

      <Header
        idDeleted={idDeleted}
        setIdDeleted={setIdDeleted}
        collection={'post'}
      />

      <Container>
        <Styles.List>
          {posts.map(post => (
            <Post setId={setId} key={post._id} post={post} />
          ))}
        </Styles.List>
      </Container>
    </>
  )
}
