import { axios } from '../../core/axios'
import { ICategory } from '../../store/ducks/categorys/contracts/state'

interface Response<T> {
  status: string
  data: T
}

export const CategorysApi = {
  async fetchCategorys(): Promise<ICategory[]> {
    const { data } = await axios.get<Response<ICategory[]>>('/categorys')
    return data.data
  },

  async fetchCategoryData(id: string): Promise<ICategory> {
    const { data } = await axios.get<Response<ICategory>>('/categorys/' + id)
    return data.data
  },

  async addCategory(payload: ICategory): Promise<ICategory> {
    const { data } = await axios.post<Response<ICategory>>(
      '/categorys',
      payload
    )
    return data.data
  },

  async updateCategoryData(
    payload: ICategory,
    id: string
  ): Promise<Response<ICategory>> {
    const { data } = await axios.patch('/categorys/' + id, payload)
    return data
  },

  async deleteCategory(id: string): Promise<Response<ICategory>> {
    const { data } = await axios.delete('/categorys/' + id)
    return data
  },
}
