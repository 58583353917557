import React from 'react';

interface Props { }
import * as Styles from './Terms.styles';
import { Container } from '../../../../UIHelpers/Container';

export const Terms = () => {
  return (
    <>
      <Styles.Terms>
        <Container>
          <Styles.TermsWrapper>
            <Styles.SupTitle>Контакты</Styles.SupTitle>
            <Styles.Title>Мы всегда вам рады!</Styles.Title>
            <Styles.SubTitle>
              Инструкция по добавлению нашей карты лояльности:
            </Styles.SubTitle>
            <Styles.CardsContainer>
              <Styles.Card>
                <Styles.CardIcon src="/icons/bonus/term-1.svg" />
                <Styles.CardText>
                  На телефоне перейдите в Play Market или AppStore для загрузки приложения "Гастробар Brick".
                </Styles.CardText>
              </Styles.Card>
              <Styles.Card>
                <Styles.CardIcon src="/icons/bonus/term-2.svg" />
                <Styles.CardText>
                  Заполните анкету на экране регистрации. Поля со звездочкой  — обязательные для заполнения.
                </Styles.CardText>
              </Styles.Card>
              <Styles.Card>
                <Styles.CardIcon src="/icons/bonus/term-3.svg" />
                <Styles.CardText>
                  Поздравляем! Теперь вы участник нашей бонусной программы и можете накапливать и тратить баллы на еду и напитки в гастробаре Brick
                </Styles.CardText>
              </Styles.Card>
            </Styles.CardsContainer>
          </Styles.TermsWrapper>
        </Container>
      </Styles.Terms>
    </>
  );
};
