import React, { useEffect } from 'react';
import { Booking } from '../../shared/containers/Booking';
import { Footer } from '../../shared/containers/Footer';
import { Map } from '../../shared/containers/Map';
import { NewsSlider } from '../../shared/containers/NewsSlider';
import { Header } from '../../shared/containers/Header';
import { Container } from '../../UIHelpers/Container';
import { Link, Link as RouterLink, useParams } from 'react-router-dom';
interface Props {}

import MetaTags from 'react-meta-tags';

import * as Styles from './Page.styles';
import { Contacts } from '../../shared/containers/Contacts';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPostData,
  setPostData,
} from '../../store/ducks/post/actionCreators';
import {
  selectIsPostLoading,
  selectPostData,
} from '../../store/ducks/post/selectors';
import Moment from 'react-moment';
import { LinearProgress } from '@material-ui/core';
import { getMediaLink } from '../../utils/mediaHelper';

export const Page: React.FC = ({}: Props) => {
  const dispatch = useDispatch();
  const postData = useSelector(selectPostData);
  const isLoading = useSelector(selectIsPostLoading);
  const params: { id?: string } = useParams();
  const id = params.id;

  window.scrollTo(0, 0);

  useEffect(() => {
    if (id) {
      dispatch(fetchPostData(id));
    }
    return () => {
      dispatch(setPostData(undefined));
    };
  }, [dispatch, id]);

  const dateToFormat = new Date(postData?.createdAt || '');

  return (
    <>
      <MetaTags>
        <title>
          BRICK GASTRO BAR | {isLoading ? 'Загрузка...' : postData?.title}
        </title>
        <meta name="keywords" content={postData?.keywords_meta || ''} />
        <meta name="description" content={postData?.description_meta || ''} />
      </MetaTags>

      <Styles.Wrapper>
        <Header />
        <Styles.MainBg background={getMediaLink(postData?.title_photo || '')}>
          <Styles.Main>
            <Container>
              <Styles.MainWrapper>
                <Styles.RouteItems>
                  <Styles.RouteItem>
                    <RouterLink to="/">Главная</RouterLink>
                  </Styles.RouteItem>
                  <Styles.RouteArrow src="/icons/menu/arrow.svg" />

                  <Styles.RouteItem>
                    <RouterLink to="/news">Новости и акции</RouterLink>
                  </Styles.RouteItem>
                  <Styles.RouteArrow src="/icons/menu/arrow.svg" />
                  <Styles.RouteItem>
                    <Link
                      to={`/news/${id}`}
                      onClick={() => alert('Вы и так тут :))')}
                    >
                      {postData?.title}
                    </Link>
                  </Styles.RouteItem>
                </Styles.RouteItems>
                <Styles.PostDate>
                  <Moment format="DD.MM.YYYY" date={dateToFormat} />
                </Styles.PostDate>
                <Styles.Title>{postData?.title}</Styles.Title>
                <Styles.Info>{postData?.title_preview}</Styles.Info>
              </Styles.MainWrapper>
            </Container>
          </Styles.Main>
        </Styles.MainBg>
        <Container style={{ padding: 0, paddingLeft: '20px' }}>
          <Styles.PageContent>
            <Styles.ContentSuptitle>
              {postData?.type.value || 'Подождите...'}
            </Styles.ContentSuptitle>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <Styles.PostContent
                dangerouslySetInnerHTML={{
                  __html: postData?.post_content || '',
                }}
              />
            )}
          </Styles.PageContent>
        </Container>
        <Booking />
        <NewsSlider />
        <Contacts />
        <Map />
        <Footer />
      </Styles.Wrapper>
    </>
  );
};
