import { Action } from 'redux'
import { CategorysState } from './state'
import { LoadingStatus } from '../../../types'

export enum CategorysActionType {
  SET_CATEGORYS = 'categorys/SET_CATEGORYS',
  FETCH_CATEGORYS = 'categorys/FETCH_CATEGORYS',
  SET_LOADING_STATE = 'categorys/SET_LOADING_STATE',
}

export interface ISetCategorysAction extends Action<CategorysActionType> {
  type: CategorysActionType.SET_CATEGORYS
  payload: CategorysState['items']
}

export interface IFetchCategorysAction extends Action<CategorysActionType> {
  type: CategorysActionType.FETCH_CATEGORYS
}

export interface ISetCategorysLoadingAction
  extends Action<CategorysActionType> {
  type: CategorysActionType.SET_LOADING_STATE
  payload: LoadingStatus
}

export type CategorysActions =
  | ISetCategorysAction
  | ISetCategorysLoadingAction
  | IFetchCategorysAction
