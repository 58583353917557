import { LoadingStatus } from '../../types';
import {
  IFetchGoodsAction,
  ISetGoodsLoadingAction,
  ISetGoodsAction,
  IFetchGoodsByCategoryAction,
  GoodsActionType,
} from './contracts/actionTypes';
import { GoodsState } from './contracts/state';

export const setGoods = (payload: GoodsState['items']): ISetGoodsAction => ({
  type: GoodsActionType.SET_GOODS,
  payload,
});

export const setGoodsLoadingStatus = (
  payload: LoadingStatus
): ISetGoodsLoadingAction => ({
  type: GoodsActionType.SET_LOADING_STATE,
  payload,
});

export const fetchGoods = (): IFetchGoodsAction => ({
  type: GoodsActionType.FETCH_GOODS,
});

export const fetchGoodsByCategory = (
  payload: string
): IFetchGoodsByCategoryAction => ({
  type: GoodsActionType.FETCH_GOODS_BY_CATEGORY,
  payload,
});
