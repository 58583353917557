import { ICategory } from '../categorys/contracts/state'
import { LoadingStatus } from '../../types'

import {
  FetchCategoryDataActionInterface,
  SetCategoryDataActionInterface,
  SetCategoryLoadingStatusActionInterface,
  CategoryActionsType,
  UpdateCategoryDataActionInterface,
  AddCategoryActionInterface,
  DeleteCategoryDataActionInterface,
  SetDeleteCategoryDataActionInterface,
} from './contracts/actionTypes'
import { CategoryState } from './contracts/state'

export const setCategoryData = (
  payload: CategoryState['data']
): SetCategoryDataActionInterface => ({
  type: CategoryActionsType.SET_CATEGORY_DATA,
  payload,
})

export const setCategoryLoadingStatus = (
  payload: LoadingStatus
): SetCategoryLoadingStatusActionInterface => ({
  type: CategoryActionsType.SET_LOADING_STATE,
  payload,
})

export const fetchCategoryData = (
  payload: string
): FetchCategoryDataActionInterface => ({
  type: CategoryActionsType.FETCH_CATEGORY_DATA,
  payload,
})

export const addCategory = (
  payload: ICategory
): AddCategoryActionInterface => ({
  type: CategoryActionsType.ADD_CATEGORY,
  payload,
})

export const deleteCategoryData = (
  payload: string
): DeleteCategoryDataActionInterface => ({
  type: CategoryActionsType.DELETE_CATEGORY_DATA,
  payload,
})

export const setDeleteCategoryData = (): SetDeleteCategoryDataActionInterface => ({
  type: CategoryActionsType.SET_LOADING_STATE,
})

export const updateCategoryData = (
  payload: ICategory,
  id: string
): UpdateCategoryDataActionInterface => ({
  type: CategoryActionsType.UPDATE_CATEGORY_DATA,
  payload,
  id,
})

export type CategoryActions =
  | SetCategoryDataActionInterface
  | FetchCategoryDataActionInterface
  | SetCategoryLoadingStatusActionInterface
  | UpdateCategoryDataActionInterface
  | DeleteCategoryDataActionInterface
  | AddCategoryActionInterface
