import styled from '@emotion/styled'

export const Popup = styled.div`
  padding: 31px 165px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 116px;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  background: #f1f1f1;
  @media (max-width: 768px) {
    height: auto;
    padding: 31px 15px;
    align-items: flex-start;
  }
`

export const Info = styled.p`
  font-size: 13px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #000000;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 150%;
  }
`

export const CookiesImg = styled.img`
  margin-right: 20px;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }
`

export const BtnConfirm = styled.button`
  border: none;
  border-radius: 50%;
  border: 1px solid #171819;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    img {
      width: 12px;
      height: 12px;
    }
  }
`

export const Link = styled.div`
  display: inline-block;
  a {
    text-decoration: none;
    color: #000;
    border-bottom: 1px solid #171819;
    cursor: pointer;
  }
`
