import styled from '@emotion/styled'

export const Menu = styled.section`
  padding: 120px 0 150px 0;
  color: #171819;
  background: #171819;
  @media (max-width: 768px) {
    padding: 80px 0 80px 0;
  }
  @media (max-width: 480px) {
    padding: 40px 0 40px 0;
  }
`
export const MenuWrapper = styled.div`
  text-align: center;
  color: #171819;
`
export const Title = styled.h2`
  font-size: 30px;
  line-height: 150%;
  font-weight: normal;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  max-width: 1088px;
  margin: 0 auto 120px auto;
  color: #f1f1f1;
  @media (max-width: 768px) {
    margin: 0 auto 80px auto;
    font-size: 26px;
  }
  @media (max-width: 480px) {
    margin: 0 auto 60px auto;
    max-width: 335px;
    text-align: center;
  }
`
export const SupTitle = styled.div`
  text-transform: uppercase;
  margin: 0 0 40px 0;
  color: rgba(241, 241, 241, 0.4);
  font-weight: 600;
  letter-spacing: 0.08em;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 480px) {
    font-size: 10px;
    margin: 0 auto 20px auto;
  }
`
export const MenuItems = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-transform: uppercase;
  color: rgba(241, 241, 241, 0.4);
  font-weight: bold;
  font-size: 14px;
  @media (max-width: 480px) {
    justify-content: center;
  }
`
export const MenuItem = styled.div`
  max-width: 513px;
  max-height: 540px;
  @media (min-width: 1024px) {
    &:hover {
      img {
        transform: scale(1.1);
      }
      span {
        color: #fff;
      }
    }
  }
  text-align: center;
  a {
    text-decoration: none;
  }
  @media (max-width: 1600px) {
    max-width: 450px;
  }
  @media (max-width: 1024px) {
    max-width: 300px;
  }
  @media (max-width: 768px) {
    max-width: 230px;
  }
  @media (max-width: 480px) {
    max-width: 335px;
    margin-bottom: 40px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`
export const ItemImgWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  max-height: 100%;
  max-width: 100%;
  margin: 0 0 40px 0;
  @media (max-width: 768px) {
    margin: 0 0 30px 0;
  }
  @media (max-width: 480px) {
    max-height: 180px;
    margin: 0 0 20px 0;
  }
`
export const ItemImg = styled.img`
  display: block;
  max-width: none;
  max-height: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  transition: 0.5s;
`
export const ItemText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  transition: 0.3s;

  letter-spacing: 0.08em;
  text-transform: uppercase;

  color: #f25003;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
  }
`
