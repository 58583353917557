import styled from '@emotion/styled';

export const Background = styled.div`
  content: '';
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  @media (max-width: 768px) {
    background: rgba(0, 0, 0, 0);
  }
`;

export const Popup = styled.div`
  padding: 58px 133px;
  width: 780px;
  position: fixed;
  z-index: 9999;
  left: calc(50% - 390px);
  height: 529px;
  top: calc(50% - 264px);
  background: #f1f1f1;
  @media (max-width: 768px) {
    width: 90%;
    height: 529px;
    padding: 20px 29px;
    left: 5%;
    top: calc(50% - 254px);
  }
`;

export const Title = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 65px;
  text-align: center;
  color: #f25003;
  margin: 0;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 48px;
  }
`;
export const Strong = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 120%;
  margin: 0;
  margin-bottom: 20px;
  color: #171819;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

export const Info = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  margin-bottom: 40px;
  color: #171819;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 30px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Button = styled.button`
  border: 1px solid #f25003;
  box-sizing: border-box;
  border-radius: 60px;
  border: 0;
  width: 250px;
  height: 56px;
  cursor: pointer;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #f1f1f1;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    width: 180px;
    height: 45px;
    font-size: 10px;
    margin-right: 10px;
  }
`;

export const BtnNo = styled(Button)`
  background: #f25003;
`;

export const BtnYes = styled(Button)`
  background: #1c1c1f;
`;
