import React from 'react'
import { Footer } from '../../shared/containers/Footer'
import { Header } from '../../shared/containers/Header'
import { Container } from '../../UIHelpers/Container'
import { Link as RouterLink } from 'react-router-dom'
import MetaTags from 'react-meta-tags'

interface Props { }

import * as Styles from './AppPolicy.styles'

export const AppPolicy: React.FC = ({ }: Props) => {
  window.scrollTo(0, 0)
  return (
    <>
      <MetaTags>
        <title>BRICK GASTRO BAR | Политика конфиденциальности приложения</title>
      </MetaTags>

      <Styles.Wrapper>
        <Header isErrorPage={true} />
        <Styles.MainBg>
          <Styles.Main>
            <Container>
              <Styles.MainWrapper>
                <Styles.RouteItems>
                  <Styles.RouteItem>
                    <RouterLink to="/">Главная</RouterLink>
                  </Styles.RouteItem>
                  <Styles.RouteArrow src="/icons/menu/arrow.svg" />
                  <Styles.RouteItem>
                    <RouterLink to="/app-privacy">
                      Политика конфиденциальности приложения
                    </RouterLink>
                  </Styles.RouteItem>
                </Styles.RouteItems>
                <Styles.Title>Политика конфиденциальности приложения</Styles.Title>
              </Styles.MainWrapper>
            </Container>
          </Styles.Main>
        </Styles.MainBg>
        <Styles.PolicyWrapper>
          <Container>
            <Styles.PolicyHeader>
              ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ДЛЯ МОБИЛЬНОГО ПРИЛОЖЕНИЯ
              “ГАСТРОБАР BRICK"
            </Styles.PolicyHeader>
            <Styles.PolicyText>
              1. ОПРЕДЕЛЕНИЕ ПОНЯТИЙ
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>"Закон"</b> — Федеральный закон Российской Федерации "О персональных данных" со всеми изменениями и дополнениями, а также иные законодательные акты Российской Федерации.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>"Мобильное приложение"</b> — программное обеспечение (со всеми существующими дополнениями и улучшениями), предназначенное для работы на смартфонах, планшетах, часах и других мобильных устройствах, и разработанное для конкретной платформы (iOS, Android, Windows Phone и т. д.).
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Для целей настоящей Политики под Мобильным приложением подразумевается следующее программное обеспечение: ГАСТРОБАР BRICK.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>"Персональные данные"</b> — совокупность личных данных и/или неперсонифицированной информации о Пользователе, предоставляемую им самим Правообладателю и/или автоматически собираемую Правообладателем и/или третьими лицами.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>"Политика"</b> — настоящую Политику конфиденциальности мобильного приложения (со всеми существующими дополнениями и изменениями).
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>"Пользователь"</b> — юридическое или физическое лицо, которое загрузило Мобильное приложение на смартфон, планшет, часы или любое другое мобильное устройство и/или осуществило активацию такого Мобильного приложения на одном из указанных устройств.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>"Пользовательское соглашение"</b> — соглашение, заключаемое между Правообладателем и Пользователем в отношении порядка, правил и особенностей использования Пользователем Мобильного приложения. Пользователь присоединяется к такому соглашению и не имеет права вносить и/или требовать внесения в него каких-либо изменений или дополнений.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>"Правообладатель"</b> — лицо, которому принадлежат исключительные права владения Мобильным приложением. Правообладателем является ООО «Легенда», адрес: 191014, г. Санкт-Петербург, ул. Жуковского, д. 7-9, лит. А, ПОМ 5Н, контактные данные: admin@brickbar.ru
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>"Файлы куки"</b> — небольшие файлы, отправляемые каким-либо мобильным приложением или сайтом, и размещаемые на смартфонах, планшетах, часах и других мобильных устройствах Пользователя, для улучшения работы таких приложений или сайтов, а также качества размещенного в них контента.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Права пользователя по защите персональных данных</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              В связи с предоставлением Персональных данных Пользователь автоматически получает следующие права:
              <Styles.br></Styles.br>
              1. Получать данные, касающиеся их обработки (основания и цели такой обработки, применяемые способы обработки, сведения о лицах, которые имеют доступ к ним или которым они могут быть раскрыты на основании договора или Закона).
              <Styles.br></Styles.br>
              2. Получать данные о месте нахождения и идентификационных данных лиц, совершающих обработку Персональных данных.
              <Styles.br></Styles.br>
              3. Получать данные о сроках хранения Персональных данных.
              <Styles.br></Styles.br>
              4. Получать данные об осуществленной или о предполагаемой трансграничной передаче Персональных данных.
              <Styles.br></Styles.br>
              5. Обжаловать действия или бездействие Правообладателя в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.
              <Styles.br></Styles.br>
              6. Получать возмещение убытков и/или компенсацию морального вреда в судебном порядке вследствие допущенных Правообладателем и/или третьими лицами нарушений прав Пользователя на охрану и защиту его Персональных данных.
              <Styles.br></Styles.br>
              7. Реализовывать иные права в области защиты персональных данных, предусмотренные Законом или положениями данной Политики.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>

              3. ПЕРЕЧЕНЬ СОБИРАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Неперсонифицированная информация о пользователях</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              В связи с использованием Мобильного приложения Правообладатель может автоматически собирать и обрабатывать следующую неперсонифицированную информацию о Пользователе:
              <Styles.br></Styles.br>
              1. Информацию о трафике, возможном количестве совершенных кликов, логи и другие данные.
              <Styles.br></Styles.br>
              2. Информацию об устройстве (идентификационный номер, сеть мобильного оператора), с которого выполняется вход, операционная система, платформа, тип браузера и другая информация о браузере, IP адрес.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Личные данные о пользователях</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Пользователь предоставляет Правообладателю о себе следующие личные данные:
              <Styles.br></Styles.br>
              2.1. Дату рождения и возраст.
              <Styles.br></Styles.br>
              2.2. Адрес электронной почты.
              <Styles.br></Styles.br>
              2.3. Номер мобильного телефона.
              <Styles.br></Styles.br>
              2.4. Пол Пользователя.
              <Styles.br></Styles.br>
              2.5. Все фотографии, видеоклипы, аудиофайлы и другие виды медиа, которые хранятся на устройстве, с которого выполняется вход в Мобильное приложение.
              <Styles.br></Styles.br>
              2.6. Данные, которые содержатся в личном кабинете (профиле) Пользователя, все внутренние переписки Пользователя (если есть), а также иная активность личного кабинета (профиля) Пользователя.
              <Styles.br></Styles.br>
              2.7. Данные и информация, получаемые в результате объединения тех или иных Персональных данных конкретного Пользователя, а также данные и информация, получаемые от третьих лиц (партнеров, маркетологов, исследователей).
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Пользователь является единственным ответственным лицом за полноту предоставляемых личных (персональных) данных и обязан осуществлять своевременное их изменение (обновление, проверку, корректировку) на регулярной основе.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Правообладатель исходит из того, что все предоставленные Пользователем личные (персональные) данные являются достоверными и Пользователь поддерживает такую информацию в актуальном состоянии.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Использование файлов куки</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Данное Мобильное приложение применяет определенные Файлы куки для сохранения IP-адреса, предпочтений Пользователей или типа используемого устройства с целью (1) ведения статистики посещений и трафика сайта, и (2) персонализации выводимых на экран Пользователя данных, и (3) сохранения данных, необходимых для идентификации Пользователя, в том числе при доступе с разных устройств, и (4) показа рекламы в соответствии с интересами и предпочтениями Пользователя. Мобильное приложение может использовать как собственные Файлы куки, принадлежащие Правообладателю, так и Файлы куки третьих лиц.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Мобильное приложение использует следующие Файлы куки:
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              1. Технические (функциональные) Файлы куки, которые нужны для контроля трафика и передачи данных, для идентификации Пользователей и предоставления Пользователю доступа к контенту Мобильного приложения и без которых использование Мобильного приложения является функционально ограниченным, а также для предотвращения предоставления рекомендаций, не соответствующих интересам Пользователя.
              <Styles.br></Styles.br>
              2. Статистические Файлы куки, которые нужны для отслеживания частоты посещаемости сайта Пользователями и выявления способов использования Пользователем Мобильного приложения, а также для выявления типа и вида контента, который является популярным или интересным для Пользователя.
              <Styles.br></Styles.br>
              3. Файлы куки третьих лиц, которые устанавливаются третьими лицами с разрешения Пользователя и предназначены для проведения статистических исследований, касающихся поведения Пользователя в сети Интернет и/или направления персонализированных рекламных или маркетинговых материалов Пользователю и/или предоставления товаров или услуг.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Пользователь имеет право в любой момент отключить в Мобильном приложении Файлы куки путем изменения определенных настроек в своем смартфоне, планшете, часах или другом мобильном устройстве. Такое отключение не влечет за собой ограничение или изменение доступа Пользователя к функциональным возможностям Мобильного приложения и/или контенту.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              4. ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Определение целей обработки</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Сбор и обработка Персональных данных осуществляется в следующих целях:
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              1. Для анализа поведения Пользователя, а также выявления предпочтений Пользователя к определенному виду контента.
              <Styles.br></Styles.br>
              2. Для оперативной и корректной работы Мобильного приложения, улучшения функционирования работы Мобильного приложения, улучшения контента Мобильного приложения, улучшения внутренней архитектуры и функциональности Мобильного приложения.
              <Styles.br></Styles.br>
              3. Для идентификации Пользователя.
              <Styles.br></Styles.br>
              4. Для направления персонализированных рекламных и маркетинговых материалов на указанный электронный адрес и/или мобильный телефон Пользователя.
              <Styles.br></Styles.br>
              5. Для соблюдения требований Закона.
              <Styles.br></Styles.br>
              6. Для технической поддержки Мобильного приложения, выявления проблем в его работе и их устранение.
              <Styles.br></Styles.br>
              7. Для поддержания связи с Пользователем (коммуникация).
              <Styles.br></Styles.br>
              8. Для выполнения иных обязательств Правообладателя, которые возникли перед Пользователем.
              <Styles.br></Styles.br>
              9. Для проведения статистических исследований.
              <Styles.br></Styles.br>
              10. Для любых других целей, при условии получения отдельного согласия от Пользователя.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Обработка Персональных данных осуществляется на основе следующих принципов: законность целей и способов обработки, добросовестность, соответствие целей обработки Персональных данных целям, заранее определенным и заявленным при сборе таких Персональных данных, соответствие объема и характера обрабатываемых Персональных данных заявленным целям их обработки.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Условия обработки персональных данных</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Обработка Персональных данных проводится в следующих случаях: получение согласия от Пользователя, достижение Правообладателем целей, предусмотренных международным договором или Законом, предоставление Пользователем своих Персональных данных неограниченному кругу лицу, выполнение иных обязательств Правообладателя перед Пользователем, включая, однако не ограничиваясь, предоставление определенного контента Пользователю, спасение жизни или здоровья Пользователя, когда согласие на обработку его Персональных данных не удается получить заблаговременно.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              В случае обезличивания Персональных данных, что не позволяет прямо или опосредованно определить Пользователя, последующее использование и раскрытие таких данных третьим лицам допускается и в отношении них более не применяются правила данной Политики.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Правообладатель принимает все возможные меры для защиты конфиденциальности полученных Персональных данных, за исключением случаев, когда Пользователь сделал такие данные общедоступными.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Обработка Персональных данных осуществляется с использованием средств автоматизации и без использования таких средств автоматизации.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              5. ДОСТУП ТРЕТЬИХ ЛИЦ К ПЕРСОНАЛЬНЫМ ДАННЫМ
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Использование аналитических платформ</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Правообладатель использует аналитическую платформу Yandex для отслеживания частоты посещаемости сайта Пользователями, отслеживания способов использования Пользователем Мобильного приложения и/или его контента, выявления типа и вида контента, который является популярными среди Пользователей, определения места нахождения Пользователя. Пользователь также дает свое согласие Правообладателю на использование им информации, полученной о Пользователе от Yandex.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Для указанных целей аналитическая платформа Yandex может собирать данные об IP адресе, геолокации, поведении Пользователя, а также его предпочтениях и интересе в отношении определенного контента.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Аналитическая платформа Yandex получает доступ к Персональным данным с тем, чтобы предоставить Правообладателю понимание того, насколько эффективно работает его Мобильное приложение, какой именно контент является популярным, насколько эффективно размещение в нем той или иной рекламы, а также для целей разработки и/или улучшения существующей маркетинговой стратегии Правообладателя.
              Посредством установки Мобильного приложения Пользователь соглашается с политикой конфиденциальности (Privacy Policy) Yandex, а также с автоматической установкой на устройство Пользователя соответствующих Файлов куки.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Раскрытие персональных данных третьим лицам</b>
              
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Правообладатель имеет право раскрывать Персональные данные своим аффилированным лицам, филиалам и представительствам, открытым как на территории Российской Федерации, так и на территории других государств; правопреемникам Правообладателя, которые возникли в результате его ликвидации, реорганизации или банкротства, и которые получили исключительные права владения Мобильным приложением; третьим лицам исключительно для целей оказания услуги получения Пользователем определенного контента или доступа к нему; третьим лицам, когда Пользователем было дано согласие на раскрытие, передачу или обработку своих Персональных данных, а также в иных случаях, прямо предусмотренных Законом или данной Политикой.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Правообладатель раскрывает Персональные данные только в том случае, если уверен, что третьи лица будут соблюдать условия данной Политики и предпринимать такие же меры по защите конфиденциальности Персональных данных, которые предпринимает сам Правообладатель, и согласие на такое раскрытие было предварительно выражено Пользователем и/или допускается на основании Закона.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Рассылка рекламных материалов пользователям</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Пользователь автоматически с установкой Мобильного приложения на устройство соглашается с правом третьих лиц отправлять на предоставленный электронный адрес и/или мобильный телефон персонализированные рекламные и маркетинговые материалы.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Пользователь имеет право в любой момент отказаться от получения таких рекламных и маркетинговых материалов посредством выполнения следующих действий:
              Направить соответствующее письмо с отказом от участия в рассылке на электронную почту admin@brickbar.ru
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              6. РАЗМЕЩЕНИЕ РЕКЛАМЫ
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Реклама в мобильном приложении</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Правообладатель не размещает рекламу в Мобильном приложении.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              7. НАПРАВЛЕНИЕ ЖАЛОБ И ЗАПРОСОВ ПРАВООБЛАДАТЕЛЮ
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Требование о прекращении обработки персональных данных</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Каждый Пользователь имеет право выразить свое возражение Правообладателю против обработки и/или хранения его Персональных данных. Такое возражение может быть выражено следующим образом:
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Запрос должен быть отправлен Правообладателю на электронную почту admin@brickbar.ru
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Запрос на получение информации о персональных данных</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Если у Пользователя возникают вопросы, связанные с порядком применения или использования настоящей Политики, порядком и/или способом обработки Персональных данных, Пользователь может задать такой вопрос следующим образом:
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Вопрос должен быть направлен Правообладателю на электронную почту admin@brickbar.ru
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Изменение (обновление, дополнение, корректировка) или удаление персональных данных</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Пользователь имеет право в любой момент самостоятельно изменить или удалить свои Персональные данные, за исключением случаев, когда такое изменение или удаление может привести к нарушению правил настоящей Политики, к нарушению Закона, характер таких Персональных данных является доказательством в каком-либо судебном процессе, возникшем между Правообладателем и Пользователем. Для этого Пользователю требуется удалить свой личный аккаунт (профиль) в Мобильном приложении.
              Правообладатель имеет право в любой момент удалить личный аккаунт/профиль Пользователя, а также все Персональные данные о Пользователе, если он нарушил условия данной Политики и/или Пользовательского соглашения.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              8. СРОКИ И ПОРЯДОК ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Хранение осуществляется самостоятельно Правообладателем.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Хранение осуществляется в течение следующего срока: 50 лет от момента получения такой информации.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              После окончания срока хранения Персональных данных, такие данные подлежат немедленному их уничтожению или обезличиванию, если только иное не запрещено Законом или положениями данной Политики.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              9. ДОСТУП НЕСОВЕРШЕННОЛЕТНИХ К МОБИЛЬНОМУ ПРИЛОЖЕНИЮ
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Использование Мобильного приложения предназначено для лиц старше 18 лет, которые получают к нему доступ только при условии предоставления предварительного согласия на обработку их Персональных данных. Правообладатель проверяет возраст Пользователя следующим образом:
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Пользователю потребуется ввести дату своего рождения в специальное поле.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Если Пользователь является несовершеннолетним лицом, в таком случае он должен немедленно прекратить использование данного Мобильного приложения.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              10. ПОРЯДОК ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Защита конфиденциальности Персональных данных является первостепенной и важной задачей для Правообладателя. Правообладатель придерживается всех требуемых международных стандартов, правил и рекомендаций по защите Персональных данных.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Правообладатель внедрил ряд технических и организационных методов, направленных на защиту Персональных данных от разглашения или несанкционированного доступа к ним третьих лиц.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Доступность текста политики для ознакомления</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Пользователи могут ознакомиться с условиями данной Политики по следующей ссылке: https://brickbar.ru/app-privacy.
              Данная редакция Политики действует от 1 июля 2021 годa.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Изменение и дополнение политики</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Настоящая Политика может быть изменена Правообладателем. Правообладатель не несет какой-либо ответственности перед Пользователем за изменение условий данной Политики без разрешения и/или согласия Пользователя.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Пользователь сам обязуется на регулярной основе проверять положения данной Политики на предмет ее возможного изменения или дополнения.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Применимое законодательство</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Настоящая Политика разработана в соответствие с действующим законодательством о защите персональных данных Российской Федерации, в частности, с нормами Федерального закона от 27 июля 2006 года № 152-ФЗ "О персональных данных" (со всеми дополнениями и изменениями), Федерального закона от 21 июля 2014 года № 242-ФЗ "О внесении изменений в отдельные законодательные акты Российской Федерации в части уточнения порядка обработки персональных данных в информационно-телекоммуникационных сетях" (со всеми дополнениями и изменениями).
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              <b>Риск разглашения</b>
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
              Вне зависимости от предпринимаемых Правообладателем мер защиты конфиденциальности получаемых персональных данных, Пользователь настоящим считается должным образом ознакомлен с тем, что любая передача Персональных данных в сети Интернет не может быть гарантировано безопасной, а потому Пользователь принимает на себя все возможные риски.
              <Styles.br></Styles.br>
              <Styles.br></Styles.br>
            </Styles.PolicyText>
          </Container>
        </Styles.PolicyWrapper>
        <Footer isErrorPage={true} />
      </Styles.Wrapper>
    </>
  )
}
