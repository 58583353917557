import { Action } from 'redux';
import { GoodsState } from './state';
import { LoadingStatus } from '../../../types';

export enum GoodsActionType {
  SET_GOODS = 'goods/SET_GOODS',
  FETCH_GOODS = 'goods/FETCH_GOODS',
  SET_LOADING_STATE = 'goods/SET_LOADING_STATE',
  FETCH_GOODS_BY_CATEGORY = 'goods/FETCH_GOODS_BY_CATEGORY',
}

export interface ISetGoodsAction extends Action<GoodsActionType> {
  type: GoodsActionType.SET_GOODS;
  payload: GoodsState['items'];
}

export interface IFetchGoodsAction extends Action<GoodsActionType> {
  type: GoodsActionType.FETCH_GOODS;
}
export interface IFetchGoodsByCategoryAction extends Action<GoodsActionType> {
  type: GoodsActionType.FETCH_GOODS_BY_CATEGORY;
  payload: string;
}

export interface ISetGoodsLoadingAction extends Action<GoodsActionType> {
  type: GoodsActionType.SET_LOADING_STATE;
  payload: LoadingStatus;
}

export type GoodsActions =
  | ISetGoodsAction
  | ISetGoodsLoadingAction
  | IFetchGoodsAction
  | IFetchGoodsByCategoryAction;
