import { RootState } from '../../store'
import { LoadingStatus } from '../../types'
import { ISale } from '../sales/contracts/state'
import { SaleState } from './contracts/state'

export const selectSale = (state: RootState): SaleState => state.sale

export const selectLoadingStatus = (state: RootState): LoadingStatus =>
  selectSale(state).LoadingStatus

export const selectIsSaleLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING

export const selectIsDeleteSaleLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING

export const selectIsSaleLoaded = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADED

export const selectSaleData = (state: RootState): ISale | undefined => {
  return selectSale(state).data
}
