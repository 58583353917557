import produce, { Draft } from 'immer'
import { LoadingStatus } from '../../types'
import { SaleActions } from './actionCreators'
import { SaleActionsType } from './contracts/actionTypes'
import { SaleState } from './contracts/state'

const initialSaleState: SaleState = {
  data: undefined,
  LoadingStatus: LoadingStatus.NEVER,
}

export const saleReducer = produce(
  (draft: Draft<SaleState>, action: SaleActions) => {
    switch (action.type) {
      case SaleActionsType.SET_SALE_DATA:
        draft.data = action.payload
        draft.LoadingStatus = LoadingStatus.LOADED
        break

      case SaleActionsType.FETCH_SALE_DATA:
        draft.data = undefined
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      case SaleActionsType.UPDATE_SALE_DATA:
        draft.data = undefined
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      case SaleActionsType.SET_LOADING_STATE:
        draft.LoadingStatus = action.payload
        break
      case SaleActionsType.DELETE_SALE_DATA:
        draft.LoadingStatus = LoadingStatus.LOADING
        break
      case SaleActionsType.ADD_SALE:
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      default:
        break
    }
  },
  initialSaleState
)
