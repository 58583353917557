import { Action } from 'redux'
import { LoadingStatus } from '../../../types'
import { ISale } from '../../sales/contracts/state'
import { SaleState } from './state'

export enum SaleActionsType {
  SET_SALE_DATA = 'sale/SET_SALE_DATA',
  FETCH_SALE_DATA = 'sale/FETCH_SALE_DATA',
  UPDATE_SALE_DATA = 'sale/UPDATE_SALE_DATA',
  DELETE_SALE_DATA = 'sale/DELETE_SALE_DATA',
  ADD_SALE = 'sale/ADD_SALE',
  SET_LOADING_STATE = 'sale/SET_LOADING_STATE',
}

export interface SetSaleDataActionInterface extends Action<SaleActionsType> {
  type: SaleActionsType.SET_SALE_DATA
  payload: SaleState['data']
}

export interface FetchSaleDataActionInterface extends Action<SaleActionsType> {
  type: SaleActionsType.FETCH_SALE_DATA
  payload: string
}

export interface UpdateSaleDataActionInterface extends Action<SaleActionsType> {
  type: SaleActionsType.UPDATE_SALE_DATA
  payload: ISale
  id: string
}

export interface AddSaleActionInterface extends Action<SaleActionsType> {
  type: SaleActionsType.ADD_SALE
  payload: ISale
}

export interface DeleteSaleDataActionInterface extends Action<SaleActionsType> {
  type: SaleActionsType.DELETE_SALE_DATA
  payload: string
}

export interface SetDeleteSaleDataActionInterface
  extends Action<SaleActionsType> {
  type: SaleActionsType.SET_LOADING_STATE
}

export interface SetSaleLoadingStatusActionInterface
  extends Action<SaleActionsType> {
  type: SaleActionsType.SET_LOADING_STATE
  payload: LoadingStatus
}
