import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Layout = styled.div`
  margin-top: calc(50% - 250px);
  @media (min-width: 1280px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const Title = styled.span`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  color: #333;
  margin-bottom: 15px;
  @media (min-width: 1280px) {
    font-size: 24px;
    line-height: 39px;
  }
`

export const FinishFTitle = styled.h1`
  margin: 0;
  font-weight: 500;
  font-size: 23px;
  text-align: center;
  color: #19385c;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`

export const FormInputLabel = styled.label`
  position: absolute;
  left: 13px;
  top: 22%;
  color: #bcc2c7;
  transition: 0.2s;
  font-family: Montserrat;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  cursor: text;
  color: #bcc2c7;
  @media (max-width: 768px) {
    font-size: 12px;
    top: 16%;
  }
`

export const FormSelectBlock = styled.div`
  width: 100%;
`

export const FormInputBlock = styled.div`
  position: relative;
  margin-bottom: 15px;
  p {
    margin-top: 8px;
    font-size: 12px;
    color: tomato;
  }
  input[type='text'] {
    position: relative;
  }

  input[type='text']:focus ~ label {
    top: 3px;
    font-size: 12px;
    color: #3f5d7f;
    @media (max-width: 768px) {
      opacity: 0 !important;
    }
  }

  input[type='password']:focus ~ label {
    top: 3px;
    font-size: 12px;
    color: #3f5d7f;
    @media (max-width: 768px) {
      opacity: 0 !important;
    }
  }

  textarea:focus ~ label {
    top: 3px;
    font-size: 12px;
    color: #3f5d7f;
    @media (max-width: 768px) {
      opacity: 0 !important;
    }
  }

  .empty {
    left: 13px;
    top: 18px;
    color: #bcc2c7;
    @media (max-width: 768px) {
      font-size: 12px;
      top: 7%;
    }
  }

  .empty-textarea {
    left: 13px;
    top: 10%;
    color: #bcc2c7;
    @media (max-width: 768px) {
      font-size: 12px;
      top: 3%;
    }
  }

  .no-empty {
    top: 3px;
    font-size: 12px;
    color: #3f5d7f;
    @media (max-width: 768px) {
      opacity: 0 !important;
    }
  }
`

export const Text = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;

  @media (min-width: 1280px) {
    font-size: 18px;
    line-height: 24px;
  }
`

export const FormLayout = styled.div`
  width: 400px;

  border-radius: 10px;
  @media (min-width: 1280px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    padding: 10px 15px;
    background: #fff;
  }

  .hidden {
    display: none;
  }

  .visible {
    display: initial;
  }
`

export const FormInput = styled.input`
  box-sizing: border-box;
  width: 350px;
  height: 32px;
  outline: none;
  border: 0;
  border-radius: 5px;
  box-shadow: 4px 4px 10px rgb(208 208 208 / 50%);
  padding: 8px;
  background: #fff;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  @media (min-width: 1280px) {
    height: 60px;
    padding: 14px;
    padding-bottom: 0px;
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    width: 100%;

    &::placeholder {
      font-size: 12px;
      color: #bcc2c7;
    }
    margin-bottom: 25px;
  }
`

export const FormButton = styled.button`
  border: none;
  outline: none;
  height: 50px;
  width: 50%;
  box-shadow: 4px 4px 10px rgb(208 208 208 / 50%);
  border-radius: 5px;
  background: #333;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    background: #666;
  }
  @media (max-width: 768px) {
    height: 35px;
    font-size: 16px;
    &:before {
      border-radius: 0 !important;
    }
  }
`

export const Message = styled.div<{ status: string }>`
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  @media (min-width: 1280px) {
    font-size: 16px;
    line-height: 25px;
  }

  ${({ status }) => {
    if (status === 'success') {
      return css`
        color: #0bb56e;
      `
    }

    if (status === 'error') {
      return css`
        color: #eb5757;
      `
    }

    return null
  }}
`
