import { axios } from '../../core/axios'
import { ISale } from '../../store/ducks/sales/contracts/state'

interface Response<T> {
  status: string
  data: T
}

export const SalesApi = {
  async fetchSales(): Promise<ISale[]> {
    const { data } = await axios.get<Response<ISale[]>>('/sales')
    return data.data
  },

  async fetchSaleData(id: string): Promise<ISale> {
    const { data } = await axios.get<Response<ISale>>('/sales/' + id)
    return data.data
  },

  async addSale(payload: ISale): Promise<ISale> {
    const { data } = await axios.post<Response<ISale>>('/sales', payload)
    return data.data
  },

  async updateSaleData(payload: ISale, id: string): Promise<Response<ISale>> {
    const { data } = await axios.patch('/sales/' + id, payload)
    return data
  },

  async deleteSale(id: string): Promise<Response<ISale>> {
    const { data } = await axios.delete('/sales/' + id)
    return data
  },
}
