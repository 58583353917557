import {
  IFetchPostsAction,
  ISetPostsLoadingAction,
  ISetPostsAction,
  PostsActionType,
} from './contracts/actionTypes'
import { LoadingStatus } from '../../types'
import { PostsState } from './contracts/state'

export const setPosts = (payload: PostsState['items']): ISetPostsAction => ({
  type: PostsActionType.SET_POSTS,
  payload,
})

export const setPostsLoadingStatus = (
  payload: LoadingStatus
): ISetPostsLoadingAction => ({
  type: PostsActionType.SET_LOADING_STATE,
  payload,
})

export const fetchPosts = (): IFetchPostsAction => ({
  type: PostsActionType.FETCH_POSTS,
})
