import styled from '@emotion/styled'

export const MainMenu = styled.section`
  padding-top: 140px;
  @media (max-width: 768px) {
    padding: 100px 0 80px 0;
  }
  @media (max-width: 480px) {
    padding: 65px 0;
  }
`
export const Title = styled.h2`
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.08em;
  max-width: 700px;
  margin: 30px 0 0 0;
  color: #171819;
  @media (max-width: 768px) {
    margin: 0 0 40px 0;
  }
`
export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
  font-weight: bold;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 80px;
    align-items: flex-start;
  }
`
