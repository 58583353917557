import { createSelector } from 'reselect'
import { LoadingStatus } from '../../types'
import { GoodsState } from './contracts/state'
import { RootState } from '../../store'

export const selectGoods = (state: RootState): GoodsState => state.goods

export const selectGoodsItems = createSelector(
  selectGoods,
  goods => goods.items
)

export const selectLoadingStatus = (state: RootState) =>
  selectGoods(state).loadingState

export const selectIsGoodsLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING
