import styled from '@emotion/styled'

export const SelectBlock = styled.div`
  margin-bottom: 28px;
  p {
    margin-top: 8px;
    font-size: 12px;
    color: tomato;
  }
  width: 100%;
  .basic-multi-select {
    margin-top: 10px;
    .select__control {
      border: none;
      min-height: 60px;
      box-shadow: 4px 4px 10px rgb(208 208 208 / 50%);
      @media (max-width: 768px) {
        min-height: 32px;
      }
    }
    .select__menu {
      z-index: 1000 !important;
    }
    .select__placeholder {
      color: #bcc2c7;
      font-size: 16px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .select__single-value {
      font-size: 16px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .select__indicator-separator {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`

export const Label = styled.label``
