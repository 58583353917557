import React from 'react';

interface Props {}
import * as Styles from './AboutUs.styles';
import { Container } from '../../../../UIHelpers/Container';
import { Link } from 'react-router-dom';
export const AboutUs = (props: Props) => {
  return (
    <>
      <Styles.AboutUs>
        <Container>
          <Styles.AboutUsWrapper>
            <Styles.InfoBlock>
              <Styles.SupTitle>О нашем баре</Styles.SupTitle>
              <Styles.Title>
                BRICK GASTRO BAR — это новый кирпич в барном фундаменте улицы
                Жуковского.
              </Styles.Title>
              <Styles.AboutText>
                Мы сочетаем гастрономический «кэжуал» и актуальный дизайн
                интерьера, чтобы у вас был бар «на каждый день». Классическая
                европейская кухня без концептуальных перегибов, широкая линейка
                пива из кранов и в бутылках, универсальные крепкие напитки.
              </Styles.AboutText>
              <Styles.AboutText>
                Brick — это интерпретация знакомого формата в XXI веке, паб
                новой волны, место для тех, для кого бар — это форма досуга.
              </Styles.AboutText>
              <Styles.Advantages>
                <Styles.Advantage>
                  <Styles.AdvantageNumber>МНОГО</Styles.AdvantageNumber>
                  <a target="_blank" href="https://vk.com/brick_spb">
                    <Styles.AdvantageText>
                      отзывов в нашей группе ВКонтакте
                    </Styles.AdvantageText>
                  </a>
                </Styles.Advantage>
                <Styles.Advantage>
                  <Styles.AdvantageNumber>15</Styles.AdvantageNumber>
                  <Styles.AdvantageText>
                    сортов пива на кранах
                  </Styles.AdvantageText>
                </Styles.Advantage>
              </Styles.Advantages>
            </Styles.InfoBlock>
            <Styles.ImageWrapper>
              <Styles.Image src="/images/interiors/photo2.webp" />
            </Styles.ImageWrapper>
          </Styles.AboutUsWrapper>
        </Container>
      </Styles.AboutUs>
    </>
  );
};
