import React from 'react';

interface Props {
  good: IGood;
  setId: any;
}

import * as Styles from './GoodPreview.styles';
import { formatDate } from '../../../../../../utils/formatDate';
import { IGood } from '../../../../../../store/ducks/goods/contracts/state';
import { Link } from 'react-router-dom';
import { getMediaLink } from '../../../../../../utils/mediaHelper';

export const Good: React.FC<Props> = ({ good, setId }: Props) => {
  const onChange = (e: any): void => {
    const checked = (e.target as HTMLInputElement).checked;
    if (checked) {
      setId(() => ({
        id: good._id,
        type: 'add',
      }));
    } else {
      setId(() => ({
        id: good._id,
        type: 'delete',
      }));
    }
  };

  return (
    <Styles.Good>
      <Styles.WrapperRow>
        <Link to={`/admin/good/edit/${good._id}`}>
          <Styles.Img src={getMediaLink(good.photo || '')} alt="" />
        </Link>

        <Styles.Wrapper>
          <Styles.TitleWrap>
            <Link to={`/admin/good/edit/${good._id}`}>
              <Styles.Title>{good.title}</Styles.Title>
            </Link>
            <Styles.CreatedAt>
              Создано {formatDate(new Date(good.createdAt || '1'))} назад
            </Styles.CreatedAt>
          </Styles.TitleWrap>
        </Styles.Wrapper>
      </Styles.WrapperRow>

      <Styles.Wrapper>
        <Styles.InputWrap>
          <Styles.Input id={good._id} onChange={onChange} type="checkbox" />
          <Styles.Delete htmlFor={good._id}>Удалить</Styles.Delete>
        </Styles.InputWrap>
        <Styles.BottomWrap>
          <Styles.Price>{good.price}р</Styles.Price>
        </Styles.BottomWrap>
      </Styles.Wrapper>
    </Styles.Good>
  );
};
