import React from 'react'

import * as Styles from './Input.styles'

interface Props {
  label?: string
  placeholder?: string
  id?: string
  type: string
  onChange?: any
  value?: string
  refProp?: any
  errors?: any
  nameProps?: string
}

export const Input = ({
  label,
  placeholder,
  id,
  type,
  onChange,
  value,
  refProp,
  nameProps,
  errors,
}: Props) => {
  return (
    <>
      <Styles.InputBlock>
        <Styles.Label htmlFor={id}>{label}</Styles.Label>
        <Styles.Input
          id={id}
          name={nameProps}
          ref={refProp}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          defaultValue={value}
        />
        <p>{errors && errors[nameProps || '']?.message}</p>
      </Styles.InputBlock>
    </>
  )
}
