import React, { useState } from 'react';
import * as Styles from './Card.styles';
import { IGood } from '../../../../store/ducks/goods/contracts/state';
import { PopUp } from '../PopUp';
import { getMediaLink } from '../../../../utils/mediaHelper';
interface Props {
  good: IGood;
}

export const Card = ({ good }: Props) => {
  const { title, description, photo, price } = good;
  const [popup, setPopup] = useState<boolean>(false);

  if (photo) {
    return (
      <>
        {popup ? <PopUp good={good} setPopup={setPopup} /> : ''}
        <Styles.Card
          onClick={() => {
            document.body.style.overflow = 'hidden';
            setPopup(!popup);
          }}
        >
          <Styles.ImgWrapper>
            <Styles.Img src={getMediaLink(photo)} />
          </Styles.ImgWrapper>
          <Styles.Title>{title}</Styles.Title>
          <Styles.Info>{description}</Styles.Info>
          <Styles.PriceWrapper>
            <Styles.Price>{price} ₽</Styles.Price>
          </Styles.PriceWrapper>
        </Styles.Card>
      </>
    );
  }

  return (
    <>
      <Styles.CardAlco>
        <Styles.TitleAlco>{title}</Styles.TitleAlco>
        <Styles.PriceWrapperAlco>
          <Styles.InfoAlco>{description}</Styles.InfoAlco>
          <Styles.Price2>{price} ₽</Styles.Price2>
        </Styles.PriceWrapperAlco>
      </Styles.CardAlco>
    </>
  );
};
