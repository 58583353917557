import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  addCategory,
  deleteCategoryData,
  fetchCategoryData,
  setCategoryData,
  updateCategoryData,
} from '../../../../../store/ducks/category/actionCreators';
import {
  selectIsCategoryLoading,
  selectCategoryData,
} from '../../../../../store/ducks/category/selectors';
import { ICategory } from '../../../../../store/ducks/categorys/contracts/state';
import { Container } from '../../../components/Container';
import { Header } from '../../../components/Header';
import { Input } from '../../../components/Inputs';
import LinearProgress from '@material-ui/core/LinearProgress';

import * as Styles from './Category.styles';
import { Button } from '../../../components/Buttons';

import MetaTags from 'react-meta-tags';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { SelectBlock } from '../../../components/Select';

interface Props {
  type: string;
}

interface ICategoryForm {
  value: string;
}

export const Category: React.FC<Props> = ({ type }: Props) => {
  const dispatch = useDispatch();
  const categoryData = useSelector(selectCategoryData);
  const isLoading = useSelector(selectIsCategoryLoading);
  const history = useHistory();

  const params: { id?: string } = useParams();
  const id = params.id;

  const [deleted, setDeleted] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [query, setQuery] = useState<ICategory>({
    value: '',
    label: '',

    parent: {
      value: '',
      label: '',
    },
  });

  const categoryFormSchema = yup.object().shape({
    value: yup.string().required('Категория обязательна'),
  });

  const { register, handleSubmit, formState } = useForm<ICategoryForm>({
    resolver: yupResolver(categoryFormSchema),
  });

  const updateHandler = useCallback(() => {
    if (query?._id) {
      dispatch(updateCategoryData(query, query?._id));
      setUpdate(!update);
    }
  }, [query]);

  const deleteHandler = useCallback(() => {
    const confirmed = confirm('Удалить категорию?');
    if (confirmed) {
      dispatch(deleteCategoryData(categoryData?._id || 'error'));
      setQuery((prev: ICategory) => ({
        ...prev,
        value: '',
        label: '',
      }));

      setDeleted(!deleted);
    }
  }, [categoryData]);

  const addHandler = useCallback(() => {
    dispatch(addCategory(query));
    setAdd(!add);
  }, [query]);

  useEffect(() => {
    if (id) {
      dispatch(fetchCategoryData(id));
    }
    return () => {
      dispatch(setCategoryData(undefined));
    };
  }, [dispatch, id, update]);

  useEffect(() => {
    setQuery((prev: ICategory) => ({
      ...prev,
      ...categoryData,
    }));
  }, [categoryData]);

  useEffect(() => {
    if (type === 'add' && categoryData?._id && add) {
      history.push(`/admin/category/edit/${categoryData?._id}`);
    }

    if (type === 'add') {
      setQuery(() => ({
        value: '',
        label: '',
        child: {
          value: '',
          label: '',
        },
        parent: {
          value: '',
          label: '',
        },
      }));
    }
  }, [add, categoryData]);

  useEffect(() => {
    if (type === 'update' && deleted) {
      history.push(`/admin/category/list`);
    }
  }, [deleted]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    onFieldChange(e.target.id, e.target.value);
  };

  const onFieldChange = (id: string, value: any): void => {
    setQuery({
      ...query,
      [id]: value,
    });
  };

  const onSubmit = async (data: ICategoryForm) => {
    query['label'] = data.value.toLocaleLowerCase();
    query['value'] = data.value.toLocaleLowerCase();
    type === 'update' ? updateHandler() : addHandler();
  };

  if (isLoading) {
    return (
      <>
        <MetaTags>
          <title>Админка | Загрузка...</title>
        </MetaTags>

        <Header collection={'category'} />

        <Container>
          <Styles.Loading>
            <p>
              {type === 'update'
                ? 'Обновление категории'
                : 'Добавление категории'}
            </p>
            <LinearProgress />
          </Styles.Loading>
        </Container>
      </>
    );
  }

  return (
    <>
      <MetaTags>
        <title>Админка | {categoryData?.value || 'Добавить категорию'}</title>
      </MetaTags>

      <Header collection={'category'} />

      <Container>
        <Styles.Category onSubmit={handleSubmit(onSubmit)}>
          <Styles.HeaderCategory>
            <Styles.Title>
              {type === 'add' ? 'Добавление категории' : 'Обновление категории'}
            </Styles.Title>
            <div>
              {type === 'update' && (
                <Button onClick={deleteHandler} type={'delete'} />
              )}
              <Button
                typeButton={'submit'}
                type={type === 'add' ? 'create' : 'update'}
              />
            </div>
          </Styles.HeaderCategory>

          <Input
            label="Название категории"
            placeholder="Введите название"
            id="value"
            type="text"
            nameProps="value"
            refProp={register}
            errors={formState.errors}
            onChange={onChange}
            value={query['value']}
          />

          <SelectBlock
            isMulti={false}
            setSelect={setQuery}
            select={query.parent.value ? query.parent : null}
            title={'Родитель'}
            name={'parent'}
            disabledValue={query.value}
          />

          <Button
            typeButton={'submit'}
            type={type === 'add' ? 'create' : 'update'}
          />
        </Styles.Category>
      </Container>
    </>
  );
};
