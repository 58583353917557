import React from 'react'

import * as Styles from './Map.styles'

interface Props {}

export const Map = (props: Props) => {
  return (
    <>
      <Styles.Map id="map">
        <iframe
          width="100%"
          height="100%"
          scrolling="no"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1998.779408731373!2d30.34919881617334!3d59.93580246928934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x469631a14610702f%3A0x5605482c4225eff9!2z0YPQuy4g0JbRg9C60L7QstGB0LrQvtCz0L4sIDctOSwg0KHQsNC90LrRgi3Qn9C10YLQtdGA0LHRg9GA0LMsIDE5MTAxNA!5e0!3m2!1sru!2sru!4v1612140523074!5m2!1sru!2sru"
        ></iframe>
      </Styles.Map>
    </>
  )
}
