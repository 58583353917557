import React, { useEffect } from 'react'

interface Props {}
import * as Styles from './Main.styles'
import { Button } from '../../../../UIHelpers/Buttons'
import { SliderBlock } from './SlickSlider'
import { Header } from '../../../../shared/containers/Header/Header'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { DataContext } from '../../../../Context/DataContext'

export const Main = ({}: Props) => {
  const { isLoadingSales, saleData } = React.useContext(DataContext)

  return (
    <>
      <Header />
      <Styles.Main>
        <Styles.MainWrapper>
          <SliderBlock data={saleData || []} loadingData={isLoadingSales} />
          <Styles.SocialMedia>
            <a href="https://t.me/brickgastrobar/" target="_blank">
              <Button
                typeButton="rounded"
                background="#F1F1F1 url('/icons/shared/telegram-logo.svg') center no-repeat"
                additionalStyles="
                    width: 40px;height: 40px;"
                backgroundSize="20px 18px"
                hoverBackground="#F25003 url('/icons/shared/telegram-logo-white.svg') center no-repeat;"
              />
            </a>
            <a href="https://vk.com/brick_spb" target="_blank">
              <Button
                typeButton="rounded"
                background="#F1F1F1 url('/icons/shared/vk-logo.svg') center no-repeat"
                additionalStyles="
                  width: 40px;height: 40px;"
                backgroundSize="15px 8px"
                hoverBackground="#F25003 url('/icons/shared/vk-logo-white.svg') center no-repeat;"
              />
            </a>
            <a href="https://www.facebook.com/BrickSt.P" target="_blank">
              <Button
                typeButton="rounded"
                background="#F1F1F1 url('/icons/shared/facebook-logo.svg') center no-repeat"
                additionalStyles="width: 40px;height: 40px;"
                backgroundSize="15px 15px"
                hoverBackground="#F25003 url('/icons/shared/facebook-logo-white.svg') center no-repeat;"
              />
            </a>
          </Styles.SocialMedia>
        </Styles.MainWrapper>
      </Styles.Main>
    </>
  )
}
