import { Action } from 'redux'
import { IGood } from '../../goods/contracts/state'
import { GoodState } from './state'
import { LoadingStatus } from '../../../types'

export enum GoodActionsType {
  SET_GOOD_DATA = 'good/SET_GOOD_DATA',
  FETCH_GOOD_DATA = 'good/FETCH_GOOD_DATA',
  UPDATE_GOOD_DATA = 'good/UPDATE_GOOD_DATA',
  DELETE_GOOD_DATA = 'good/DELETE_GOOD_DATA',
  ADD_GOOD = 'good/ADD_GOOD',
  SET_LOADING_STATE = 'good/SET_LOADING_STATE',
}

export interface SetGoodDataActionInterface extends Action<GoodActionsType> {
  type: GoodActionsType.SET_GOOD_DATA
  payload: GoodState['data']
}

export interface FetchGoodDataActionInterface extends Action<GoodActionsType> {
  type: GoodActionsType.FETCH_GOOD_DATA
  payload: string
}

export interface UpdateGoodDataActionInterface extends Action<GoodActionsType> {
  type: GoodActionsType.UPDATE_GOOD_DATA
  payload: IGood
  id: string
}

export interface AddGoodActionInterface extends Action<GoodActionsType> {
  type: GoodActionsType.ADD_GOOD
  payload: IGood
}

export interface DeleteGoodDataActionInterface extends Action<GoodActionsType> {
  type: GoodActionsType.DELETE_GOOD_DATA
  payload: string
}

export interface SetDeleteGoodDataActionInterface
  extends Action<GoodActionsType> {
  type: GoodActionsType.SET_LOADING_STATE
}

export interface SetGoodLoadingStatusActionInterface
  extends Action<GoodActionsType> {
  type: GoodActionsType.SET_LOADING_STATE
  payload: LoadingStatus
}
