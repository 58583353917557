import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as Styles from './AdultPopup.styles'

export function AdultPopup() {
  const [confirmation, setConfirmation] = useState<boolean>(
    JSON.parse(localStorage.getItem('confirm') || 'false') || false
  )

  useEffect(() => {
    document.body.style.overflow = confirmation ? 'auto' : 'hidden'
  }, [confirmation])

  const history = useHistory()

  function handleConfirm() {
    setConfirmation(true)
    document.body.style.overflow = 'auto'
    localStorage.setItem('confirm', JSON.stringify(true))
  }

  function handleRefuse() {
    setConfirmation(false)
    document.body.style.overflow = 'auto'
    history.push('/')
  }

  return (
    <Styles.Background
      style={{ visibility: confirmation ? 'hidden' : 'visible' }}
    >
      <Styles.Popup>
        <Styles.Title>18+</Styles.Title>
        <Styles.Strong>
          Добро пожаловать на сайт Питерского гастро бара Brick Bar! Для доступа
          к нашему меню необходимо подтвердить, что вам больше 18 лет
        </Styles.Strong>
        <Styles.Info>
          Сайт содержит информацию для лиц совершеннолетнего возраста. Сведения,
          размещенные на сайте, не являются рекламой, носят исключительно
          информационный характер, и предназначены только для личного
          использования.
        </Styles.Info>

        <Styles.ButtonWrapper>
          <Styles.BtnNo onClick={handleRefuse}>
            Нет, я младше 18 лет
          </Styles.BtnNo>
          <Styles.BtnYes onClick={handleConfirm}>
            Да, мне больше 18 лет
          </Styles.BtnYes>
        </Styles.ButtonWrapper>
      </Styles.Popup>
    </Styles.Background>
  )
}
