import { LoadingStatus } from '../../types'
import { IPost } from '../posts/contracts/state'

import {
  FetchPostDataActionInterface,
  SetPostDataActionInterface,
  SetPostLoadingStatusActionInterface,
  PostActionsType,
  UpdatePostDataActionInterface,
  AddPostActionInterface,
  DeletePostDataActionInterface,
  SetDeletePostDataActionInterface,
} from './contracts/actionTypes'
import { PostState } from './contracts/state'

export const setPostData = (
  payload: PostState['data']
): SetPostDataActionInterface => ({
  type: PostActionsType.SET_POST_DATA,
  payload,
})

export const setPostLoadingStatus = (
  payload: LoadingStatus
): SetPostLoadingStatusActionInterface => ({
  type: PostActionsType.SET_LOADING_STATE,
  payload,
})

export const fetchPostData = (
  payload: string
): FetchPostDataActionInterface => ({
  type: PostActionsType.FETCH_POST_DATA,
  payload,
})

export const addPost = (payload: IPost): AddPostActionInterface => ({
  type: PostActionsType.ADD_POST,
  payload,
})

export const deletePostData = (
  payload: string
): DeletePostDataActionInterface => ({
  type: PostActionsType.DELETE_POST_DATA,
  payload,
})

export const setDeletePostData = (): SetDeletePostDataActionInterface => ({
  type: PostActionsType.SET_LOADING_STATE,
})

export const updatePostData = (
  payload: IPost,
  id: string
): UpdatePostDataActionInterface => ({
  type: PostActionsType.UPDATE_POST_DATA,
  payload,
  id,
})

export type PostActions =
  | SetPostDataActionInterface
  | FetchPostDataActionInterface
  | SetPostLoadingStatusActionInterface
  | UpdatePostDataActionInterface
  | DeletePostDataActionInterface
  | AddPostActionInterface
