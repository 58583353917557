import styled from '@emotion/styled'

export const Sale = styled.form`
  margin-top: 120px;
  margin-bottom: 20px;
`
export const InputFileWrapper = styled.div`
  box-shadow: rgba(208, 208, 208, 0.5) 4px 4px 10px;
  border-radius: 5px;
  padding-left: 14px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  img {
    max-width: 400px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
`

export const Title = styled.h1`
  color: #42a5f5;
  margin: 0;
  font-weight: 500;
  margin-bottom: 40px;
`

export const HeaderSale = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    button {
      margin-left: 10px;
    }
  }
`

export const Loading = styled.div`
  margin-top: 120px;
`
