import { Action } from 'redux'
import { LoginFormProps } from '../../../../pages/Admin/pages/Auth/Auth'
import { LoadingStatus } from '../../../types'
import { IUser } from './state'

export enum UserActionsType {
  SET_USER_DATA = 'user/SET_USER_DATA',
  FETCH_SIGN_IN = 'user/FETCH_SIGN_IN',
  FETCH_SIGN_UP = 'user/FETCH_SIGN_UP',
  SET_LOADING_STATE = 'user/SET_LOADING_STATE',
}

export interface FetchSignInActionInterface extends Action<UserActionsType> {
  type: UserActionsType.FETCH_SIGN_IN
  payload: LoginFormProps
}

export interface SetUserDataActionInterface extends Action<UserActionsType> {
  type: UserActionsType.SET_USER_DATA
  payload: IUser | undefined
}

export interface SetUserLoadingStatusActionInterface
  extends Action<UserActionsType> {
  type: UserActionsType.SET_LOADING_STATE
  payload: LoadingStatus
}
