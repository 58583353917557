import styled from '@emotion/styled'

export const MainBg = styled.div`
  background: url('/images/404/main-bg-pc-gradient.webp') center no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    background: url('/images/404/main-bg-tablet-gradient.webp') center no-repeat;
    background-size: cover;
  }
  @media (max-width: 480px) {
    background: url('/images/404/main-bg-phone-gradient.webp') center no-repeat;
    background-size: cover;
  }
`
export const Main = styled.section`
  padding: 0 0 79px 0;
  @media (max-width: 768px) {
    padding: 84px 0 190px 0;
  }
  @media (max-width: 480px) {
    padding: 80px 0 165px 0;
  }
  @media (max-width: 375px) {
    padding: 80px 0 100px 0;
  }
`
export const BgImg = styled.div`
  margin: 0 auto;
  background: url('/images/404/main-bg-pc.webp') center center no-repeat;
  background-size: cover;
  width: 770px;
  height: 500px;
  @media (max-width: 768px) {
    width: 100%;
    height: 480px;
  }
  @media (max-width: 480px) {
    height: 225px;
  }
  @media (max-width: 320px) {
    height: 182px;
  }
`

export const MainWrapper = styled.div`
  text-align: center;
`
export const RouteItem = styled.div`
  &:first-of-type {
    margin-right: 14px;
  }
  a {
    @media (min-width: 1024px) {
      &:hover {
        color: #f25003;
      }
      transition: 0.2s;
    }
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: #f1f1f1;
    text-decoration: none;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`
export const RouteArrow = styled.img`
  margin-right: 14px;
  width: 20px;
  height: 15px;
  margin-top: 5px;
`
export const Title = styled.h1`
  font-weight: normal;
  text-transform: uppercase;
  line-height: 150%;
  font-size: 42px;
  color: #f1f1f1;
  margin: 0 0 30px 0;
  @media (max-width: 768px) {
    font-size: 34px;
  }
  @media (max-width: 480px) {
    margin: 0 auto 20px auto;
    font-size: 24px;
  }
`
export const Info = styled.div`
  font-size: 24px;
  line-height: 150%;
  font-weight: normal;
  color: #f1f1f1;
  max-width: 890px;
  margin: 0 auto 60px auto;
  @media (max-width: 768px) {
    max-width: 655px;
    margin: 0 auto 80px auto;
    font-size: 18px;
  }
  @media (max-width: 480px) {
    max-width: 335px;
    margin: 0 auto 40px auto;
    text-align: center;
    font-size: 14px;
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1120px;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    width: 100%;
  }
  @media (max-width: 320px) {
    justify-content: center;
  }
`
