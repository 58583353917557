import styled from "@emotion/styled";
export const Footer = styled.footer`
  background: #171819;
  margin-top: auto;
`;
export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #f1f1f1;
`;
export const Part1 = styled.div`
  padding: 80px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 480px) {
    padding: 40px 0;
    flex-direction: column;
    align-items: center;
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const LogoWrapper = styled.div`
  max-width: 121px;
  max-height: 56px;
  margin-right: 50px;
  @media (max-width: 768px) {
    margin-right: 34px;
  }
  @media (max-width: 480px) {
    margin-right: 0;
    margin-bottom: 30px;
  }
`;

export const Logo = styled.img`
  max-width: 100%;
`;
export const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 645px;
  font-size: 14px;
  margin-right: 155px;
  @media (max-width: 1600px) {
    margin-right: 5px;
  }
  @media (max-width: 1439px) {
    order: 6;
    width: 100%;
    margin: 64px 0 0 0;
    margin-right: 0 !important;
  }
  @media (max-width: 1500px) {
    margin-right: 144px;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    width: auto;
    margin: 30px 0 0 0;
    height: 160px;
  }
`;
export const NavbarItem = styled.div`
  a {
    color: #fff;
    text-decoration: none;
    @media (min-width: 1024px) {
      transition: 0.1s;
      &:hover {
        color: #f25003;
      }
    }
  }
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.08em;
  line-height: 150%;
`;
export const PhoneNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: normal;
  margin-right: 40px;
  a {
    transition: 0.2s;
    &:hover {
      color: #f25003;
    }
    text-decoration: none;
    color: #fff;
  }
  transition: 0.3s;

  @media (max-width: 1500px) {
    display: none;
  }
  @media (max-width: 1439px) {
    margin-right: 550px;
  }
  @media (max-width: 1200px) {
    margin-right: 200px;
  }
  @media (max-width: 768px) {
    margin-right: 34px;
  }
  @media (max-width: 480px) {
    margin-right: 0;
    font-size: 28px;
    margin-bottom: 30px;
  }
`;

export const Part2 = styled.div`
  border-top: 1px solid rgba(241, 241, 241, 0.2);

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: rgba(241, 241, 241, 0.4);
    @media (max-width: 768px) {
      font-size: 12px;
    }
    @media (max-width: 480px) {
      font-size: 8px;
      flex-direction: column;
      align-items: center;
      padding: 30px 0 30px 0;

      padding-bottom: 0px;
    }
  }
`;

export const FooterTextBlock = styled.div`
  display: flex;
  a {
    color: rgba(241, 241, 241, 0.4);
    text-decoration: none !important;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterText = styled.div`
  color: rgba(241, 241, 241, 0.4);
  text-decoration: none !important;
  a {
    color: rgba(241, 241, 241, 0.4);
    @media (min-width: 1024px) {
      transition: 0.2s;
      &:hover {
        color: #fff;
      }
    }
    text-decoration: none !important;
  }
  margin-right: 40px;
  &:last-of-type {
    margin-right: 0;
  }
  @media (max-width: 480px) {
    margin: 0 0 10px 0;
  }
`;
export const FooterTextDev = styled.div`
  color: rgba(241, 241, 241, 0.4);
  text-decoration: none !important;
  a {
    color: rgba(241, 241, 241, 0.4);
    text-decoration: none !important;
    display: flex;
    align-items: center;
    img {
      width: 120px;
      margin-left: 15px;
      height: 60px;
    }
    @media (min-width: 1024px) {
      transition: 0.2s;
      &:hover {
        color: #1adbad;
      }
    }
  }
  margin-right: 40px;
  margin-right: 0;
  @media (max-width: 480px) {
    margin: 0 0 10px 0;
    img {
      width: 50px;
      margin-left: 10px;
      height: 25px;
    }
  }
`;

export const AppsLinks = styled.div`
  padding: 0 0 35px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    img {
      margin: 0 20px 0 0;
      height: 50px;

      @media (max-width: 420px) {
        margin: 0;
        height: 50px;
      }

      @media (max-width: 330px) {
        margin: 0;
        height: 40px;
      }
    }

    @media (max-width: 575px) {
      margin: 0 0 15px 0;
    }

    @media (max-width: 420px) {
      margin: 0 0 15px 0;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 420px) {
    padding: 0 0 20px 0;

    justify-content: space-between;
  }
`;
