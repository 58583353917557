import React, { useRef, useState } from 'react';
import InputMask from 'react-input-mask';

import TextField from '@material-ui/core/TextField';

import * as Styles from './Booking.styles';
import { Container } from '../../../UIHelpers/Container';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from '../../../UIHelpers/Buttons';

import { bookingApi } from '../../../services/api/emailApi';

import CircularProgress from '@material-ui/core/CircularProgress';

import DateFnsAdapter from '@material-ui/pickers/adapter/date-fns';
import ruLocale from 'date-fns/locale/ru';
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from '@material-ui/pickers';
import { Theme } from '@emotion/react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface IBookingForm {
  nameBooking: string;
  date: any;
  time: any;
  phone: string;
  guests: string;
  commentBooking: string;
  social: string;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      color: '#fff !important',
    },
  })
);

export const Booking = () => {
  const classes = useStyles();
  const socials = [
    {
      img: 's-1',
      msg: 'Телефон',
    },
    {
      img: 's-2',
      msg: 'Whatsapp',
    },
    {
      img: 's-3',
      msg: 'Viber',
    },
    {
      img: 's-4',
      msg: 'Telegram',
    },
  ];

  const nameBooking: any = document.getElementById('nameBooking');
  const guests: any = document.getElementById('guests');
  const commentBooking: any = document.getElementById('commentBooking');

  const [query, setQuery] = useState<IBookingForm>({
    nameBooking: '',
    date: new Date(),
    time: new Date().setMilliseconds(1 * 60 * 60 * 1000),
    phone: '',
    guests: '',
    commentBooking: '',
    social: 'Телефон',
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<boolean>(true);
  const [active, setActive] = useState(0);

  const [errorsQuery, setErrorsQuery] = useState({
    time: false,
    date: false,
    phone: false,
  });

  const bookingFormSchema = yup.object().shape({
    nameBooking: yup.string().required('Имя обязательно'),
    guests: yup
      .number()
      .required('Кол-во гостей обязательно')
      .positive('Кол-во гостей должно быть больше 0')
      .typeError('Кол-во гостей должно быть числом'),
  });

  const { register, handleSubmit, formState, setValue } = useForm<IBookingForm>(
    {
      resolver: yupResolver(bookingFormSchema),
    }
  );

  const onFieldChange = (id: string, value: any): void => {
    setQuery(prevQuery => ({
      ...prevQuery,
      [id]: value,
    }));
    setValue(id, value);
  };

  const onChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    onFieldChange(event.target.id, event.target.value);
  };

  const onSubmit = async (_: IBookingForm) => {
    console.log('query', query);

    if (!query['time']) {
      setErrorsQuery(prev => ({
        ...prev,
        time: true,
      }));
      return;
    } else {
      setErrorsQuery(prev => ({
        ...prev,
        time: false,
      }));
    }
    if (!query['date']) {
      setErrorsQuery(prev => ({
        ...prev,
        date: true,
      }));
      return;
    } else {
      setErrorsQuery(prev => ({
        ...prev,
        date: false,
      }));
    }

    if (!checkbox) {
      return;
    }

    if (
      !query['phone'] ||
      query['phone'].split('').filter(el => el === '_').length != 0
    ) {
      setErrorsQuery(prev => ({
        ...prev,
        phone: true,
      }));
      return;
    } else {
      setErrorsQuery(prev => ({
        ...prev,
        phone: false,
      }));
    }

    setLoading(true);
    const response: any = await bookingApi.sendBooking(query);
    setLoading(false);
    setMessage(response.success === true ? true : false);
    setTimeout(() => {
      setMessage(false);
    }, 3000);

    guests.value = '';
    nameBooking.value = '';
    commentBooking.value = '';

    setQuery(prev => ({
      ...prev,
      nameBooking: '',
      date: new Date(),
      time: new Date().setMilliseconds(1 * 60 * 60 * 1000),
      phone: '',
      guests: '',
      commentBooking: '',
    }));
  };

  const handleDateChange = (date: Date | null) => {
    setQuery(prev => ({
      ...prev,
      date,
    }));
  };
  const handleTimeChange = (time: Date | null) => {
    setQuery(prev => ({
      ...prev,
      time,
    }));
  };

  function setContentBtn() {
    if (loading) {
      return <CircularProgress className={classes.root} />;
    }
    if (message) {
      return <div style={{ color: '#000000' }}>Отправлено!</div>;
    }
    return 'Отправить';
  }
  return (
    <>
      <Styles.Booking id="booking">
        <Container>
          <Styles.BookingWrapper>
            <Styles.Part1>
              <Styles.Title>Забронировать столик</Styles.Title>
              <Styles.PartText>
                Выберите удобный способ связи. Наш менеджер свяжется с вами,
                <br />
                чтобы обсудить детали заказа или ответить на ваши вопросы.
              </Styles.PartText>
              <Styles.Advantages>
                <Styles.Advantage>
                  <Styles.AdvantageNumber>МНОГО</Styles.AdvantageNumber>
                  <a target="_blank" href="https://vk.com/brick_spb">
                    <Styles.AdvantageText>
                      отзывов в нашей группе ВКонтакте
                    </Styles.AdvantageText>
                  </a>
                </Styles.Advantage>
                <Styles.Advantage>
                  <Styles.AdvantageNumber>15</Styles.AdvantageNumber>
                  <Styles.AdvantageText>
                    сортов пива на кранах
                  </Styles.AdvantageText>
                </Styles.Advantage>
              </Styles.Advantages>
            </Styles.Part1>

            <Styles.Part2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Styles.InputsWrapper>
                  <Styles.InputWrapper query={query['nameBooking']}>
                    <Styles.Input
                      {...register('nameBooking')}
                      id="nameBooking"
                      name="nameBooking"
                      type="text"
                      value={query['nameBooking']}
                      onChange={onChange}
                    />
                    <Styles.Label>Ваше имя</Styles.Label>
                    <Styles.Error>
                      {formState.errors['nameBooking']?.message}
                    </Styles.Error>
                  </Styles.InputWrapper>

                  <Styles.InputWrapper query={query['date']}>
                    <LocalizationProvider
                      dateAdapter={DateFnsAdapter}
                      locale={ruLocale}
                    >
                      <DatePicker
                        todayText="Сегодня"
                        mask={'__.__.____'}
                        minDate={new Date()}
                        showTodayButton={true}
                        cancelText="Закрыть"
                        okText="ок"
                        label="Желаемая дата посещения"
                        value={query['date']}
                        showToolbar={true}
                        onChange={handleDateChange}
                        renderInput={props => <TextField {...props} />}
                      />
                    </LocalizationProvider>
                    <Styles.Input
                      style={{ color: 'transparent' }}
                      disabled
                      type="text"
                    />
                    <Styles.Error>
                      {errorsQuery['date'] && 'Дата обязательна'}
                    </Styles.Error>
                  </Styles.InputWrapper>

                  <Styles.InputWrapper query={query['time']}>
                    <LocalizationProvider
                      dateAdapter={DateFnsAdapter}
                      locale={ruLocale}
                    >
                      <TimePicker
                        label="Желаемое время посещения"
                        value={query['time']}
                        cancelText="Закрыть"
                        okText="ок"
                        showToolbar={true}
                        minTime={new Date(0, 0, 0, 8)}
                        maxTime={new Date(0, 0, 0, 23)}
                        onChange={handleTimeChange}
                        renderInput={props => <TextField {...props} />}
                      />
                    </LocalizationProvider>
                    <Styles.Input disabled />

                    <Styles.Error>
                      {errorsQuery['time'] && 'Время обязательно'}
                    </Styles.Error>
                  </Styles.InputWrapper>

                  <Styles.InputWrapper query={query['guests']}>
                    <Styles.Input
                      {...register('guests')}
                      id="guests"
                      type="text"
                      name="guests"
                      value={query['guests']}
                      onChange={onChange}
                    />
                    <Styles.Label>Количество гостей</Styles.Label>
                    <Styles.Error>
                      {formState.errors['guests']?.message}
                    </Styles.Error>
                  </Styles.InputWrapper>

                  <Styles.InputWrapper query={query['phone']}>
                    <InputMask
                      required
                      id="phone"
                      name="phone"
                      value={query['phone']}
                      onChange={onChange}
                      type="tel"
                      mask="+7\(999)-999-99-99"
                    />
                    <Styles.Label>Введите телефон</Styles.Label>
                    <Styles.Error>
                      {errorsQuery['phone'] && 'Телефон обязателен'}
                    </Styles.Error>
                  </Styles.InputWrapper>

                  <Styles.InputWrapper query={query['commentBooking']}>
                    <Styles.Input
                      {...register('commentBooking')}
                      onChange={onChange}
                      id="commentBooking"
                      value={query['commentBooking']}
                      name="commentBooking"
                      type="text"
                    />
                    <Styles.Label>Комментарии и пожелания</Styles.Label>
                    <Styles.Error>
                      {formState.errors['commentBooking']?.message}
                    </Styles.Error>
                  </Styles.InputWrapper>
                </Styles.InputsWrapper>

                <Styles.Info>
                  <Styles.Socials>
                    <Styles.ErrorSocial>
                      Удобный для вас способ связи:
                    </Styles.ErrorSocial>
                    {socials.map((social, i) => (
                      <Styles.Social
                        style={
                          active === i
                            ? { background: '#F25003' }
                            : { background: 'transparent' }
                        }
                        key={social.msg}
                        onClick={() => {
                          setQuery(prev => ({
                            ...prev,
                            social: social.msg,
                          }));
                          setActive(i);
                        }}
                      >
                        <img
                          src={`/images/home/booking/${social.img}.svg`}
                          alt=""
                        />
                        <div
                          className="popup"
                          style={
                            active === i &&
                            document.documentElement.clientWidth < 768
                              ? { opacity: 1 }
                              : { opacity: 0 }
                          }
                        >
                          <Styles.Popup>{social.msg}</Styles.Popup>
                        </div>
                      </Styles.Social>
                    ))}
                  </Styles.Socials>
                  <div>
                    <Button
                      additionalStyles=" margin-left: 83px;@media(max-width:1440px){margin-left:0;}@media(max-width:480px){margin-top:20px;   width: 100% !important;}"
                      type="submit"
                      hoverBackground="#f89577"
                      typeButton="normal"
                      content={setContentBtn()}
                      disabled={loading}
                    />
                    <Styles.Checkbox>
                      <Styles.CheckboxWrapper>
                        <input
                          type="checkbox"
                          name="checkboxG5"
                          id="css"
                          onChange={e => setCheckbox(e.target.checked)}
                          checked={checkbox}
                          className="formtocons-form-checkbox"
                        />
                        <label
                          htmlFor="css"
                          className="formtocons-form-checkbox-text"
                        >
                          <div>
                            Я прочитал{' '}
                            <Link
                              style={{ color: '#f25003', opacity: 0.8 }}
                              to="/privacy"
                            >
                              условия
                            </Link>{' '}
                            и согласен на обработку персональных данных
                          </div>
                        </label>
                      </Styles.CheckboxWrapper>
                      <Styles.Error style={{ height: '10px' }}>
                        {!checkbox &&
                          'Вы не согласились с политикой конфиденциальности'}
                      </Styles.Error>
                    </Styles.Checkbox>
                  </div>
                </Styles.Info>
              </form>
            </Styles.Part2>
          </Styles.BookingWrapper>
        </Container>
      </Styles.Booking>
    </>
  );
};
