import React, { useEffect, useRef, useState } from 'react'

import * as Styles from './NewsSection.styles'

import { Card } from '../Card'
import { MyLoader } from '../Preloader'
import { DataContext } from '../../../../Context/DataContext'
import { CardsContainer } from '../CardsContainer'
import { Container } from '../../../../UIHelpers/Container'
import { IPost } from '../../../../store/ducks/posts/contracts/state'

interface Props {}

export const NewsSection = ({}: Props) => {
  const { isLoadingPosts, postData } = React.useContext(DataContext)

  const el1 = useRef<any>()
  const el2 = useRef<any>()
  const el3 = useRef<any>()

  const path = window.location.href
  useEffect(() => {
    const currentElementId = path.split('#')[1]

    switch (currentElementId) {
      case 'news':
        el1?.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'sales':
        el2?.current?.scrollIntoView({ behavior: 'smooth' })
        break
      case 'events':
        el3?.current?.scrollIntoView({ behavior: 'smooth' })
        break
      default:
        window.scrollTo(0, 0)
        break
    }
  }, [path])

  const [activeCategory1, setActiveCategory1] = useState({
    name: 'новость',
  })
  const [activeCategory2, setActiveCategory2] = useState({
    name: 'акция',
  })
  const [activeCategory3, setActiveCategory3] = useState({
    name: 'мероприятие',
  })

  function renderCards(activeCategory: any) {
    return postData?.map((post: IPost) => {
      if (
        post?.type?.value.toLowerCase() === activeCategory.name.toLowerCase()
      ) {
        return (
          <Card
            post={post}
            key={post._id + activeCategory.name.toLowerCase()}
          />
        )
      }
    })
  }

  return (
    <>
      <Styles.NewsSection>
        <Container>
          <Styles.ScrollBlock ref={el1} id="news"></Styles.ScrollBlock>
          <Styles.Title>Новости</Styles.Title>
          <Styles.CardsContainer>
            {isLoadingPosts ? (
              new Array(3)
                .fill('')
                .map((_, i) => <MyLoader key={i * Math.random()} />)
            ) : (
              <CardsContainer
                categoryName={'Новостей'}
                children={renderCards(activeCategory1)}
              />
            )}
          </Styles.CardsContainer>

          <Styles.ScrollBlock ref={el2} id="sales"></Styles.ScrollBlock>
          <Styles.Title>Акции</Styles.Title>
          <Styles.CardsContainer>
            {isLoadingPosts ? (
              new Array(3)
                .fill('')
                .map((_, i) => <MyLoader key={i * Math.random()} />)
            ) : (
              <CardsContainer
                categoryName={'Акций'}
                children={renderCards(activeCategory2)}
              />
            )}
          </Styles.CardsContainer>

          <Styles.ScrollBlock ref={el3} id="events"></Styles.ScrollBlock>
          <Styles.Title>Мероприятия</Styles.Title>
          <Styles.CardsContainer>
            {isLoadingPosts ? (
              new Array(3)
                .fill('')
                .map((_, i) => <MyLoader key={i * Math.random()} />)
            ) : (
              <CardsContainer
                categoryName={'Мероприятий'}
                children={renderCards(activeCategory3)}
              />
            )}
          </Styles.CardsContainer>
        </Container>
      </Styles.NewsSection>
    </>
  )
}
