import { createSelector } from 'reselect'
import { LoadingStatus } from '../../types'
import { CategorysState } from './contracts/state'
import { RootState } from '../../store'

export const selectCategorys = (state: RootState): CategorysState =>
  state.categorys

export const selectCategorysItems = createSelector(
  selectCategorys,
  categorys => categorys.items
)

export const selectLoadingStatus = (state: RootState) =>
  selectCategorys(state).loadingState

export const selectIsCategorysLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING
