import styled from '@emotion/styled'
export const AboutUs = styled.section`
  padding: 222px 0 160px 0;
  color: #171819;
  @media (max-width: 1200px) {
    padding: 80px 0 80px 0;
  }
  @media (max-width: 1200px) {
    padding: 60px 0 60px 0;
  }
`
export const AboutUsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: #171819;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
export const InfoBlock = styled.div`
  max-width: 655px;
  @media (max-width: 1600px) {
    max-width: 550px;
  }
  @media (max-width: 1200px) {
    max-width: 100%;
    margin-bottom: 100px;
  }
`
export const SupTitle = styled.div`
  text-transform: uppercase;
  margin: 0 0 40px 0;
  color: rgba(23, 24, 25, 0.4);
  font-weight: bold;
  font-size: 14px;
  @media (max-width: 1200px) {
    font-size: 12px;
    margin: 0 0 30px 0;
  }
  @media (max-width: 480px) {
    font-size: 10px;
    margin: 0 0 20px 0;
  }
`
export const Title = styled.h2`
  margin: 0 0 20px 0;
  @media (max-width: 1200px) {
    margin: 0 0 25px 0;
  }
`
export const AboutText = styled.p`
  margin: 0 0 18px 0;
  &:last-of-type {
    margin: 0 0 60px 0;
  }
  @media (max-width: 480px) {
    margin: 0 0 40px 0;
    font-size: 14px;
  }
`
export const Advantages = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const Advantage = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 100px;
  max-width: 235px;
  &:first-of-type {
    a {
      text-decoration: underline;
      color: #171819;
    }
  }
  &:last-of-type {
    margin-right: 0;
  }
  @media (max-width: 1200px) {
    margin-right: 150px;
    max-width: 240px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  @media (max-width: 480px) {
    margin: 0 15px 0 0;
  }
`
export const AdvantageNumber = styled.div`
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 48px;

  span {
    font-weight: 400;
  }
  @media (max-width: 1200px) {
    font-size: 42px;
  }
  @media (max-width: 480px) {
    font-size: 34px;
  }
`
export const AdvantageText = styled.div`
  font-size: 14px;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`

export const ImageWrapper = styled.div`
  z-index: 3;
  position: relative;
  margin-right: 30px;
  margin-top: 30px;
  max-width: 750px;
  &:after {
    z-index: -1;
    position: absolute;
    content: '';
    width: 520px;
    height: 387px;
    right: -30px;
    top: -30px;
    background: #f25003;
  }
  @media (max-width: 1600px) {
    max-width: 660px;
  }
  @media (max-width: 1200px) {
    max-width: 100%;
    margin: 0;
    &:after {
      width: 600px;
      height: 500px;
      right: calc(50% - 290px);
      top: -30px;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
    width: auto;
    height: auto;
    margin-right: 20px;
    margin-top: 20px;
    &:after {
      z-index: -1;
      position: absolute;
      content: '';
      width: 520px;
      height: 290px;
      right: -20px;
      top: -20px;
      background: #f25003;
    }
  }
  @media (max-width: 480px) {
    margin-right: 10px;
    margin-top: 10px;
    &:after {
      z-index: -1;
      position: absolute;
      content: '';
      width: 260px;
      height: 180px;
      right: -10px;
      top: -10px;
      background: #f25003;
    }
  }
`
export const Image = styled.img`
  overflow: hidden;
  max-width: 100%;
`
export const Suptitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  color: rgba(23, 24, 25, 0.4);
  text-transform: uppercase;
  margin-bottom: 40px;
`
