import styled from '@emotion/styled'

export const Title = styled.h1`
  margin-top: 100px;
  font-size: 24px;
  font-weight: 600;
  color: #333;
`

export const Loading = styled.div`
  margin-top: 100px;
`
