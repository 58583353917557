import React from 'react'

interface Props {}
import * as Styles from './Contacts.styles'
import { Container } from '../../../../UIHelpers/Container'
import { Button } from '../../../../UIHelpers/Buttons'
import { Link } from 'react-scroll'

export const Contacts = () => {
  return (
    <>
      <Styles.Contacts>
        <Container>
          <Styles.ContactsWrapper>
            <Styles.SupTitle>Контакты</Styles.SupTitle>
            <Styles.ContactsBlocks>
              <Styles.ContactsBlock>
                <Styles.Title>Мы всегда вам рады!</Styles.Title>
                <Styles.BookingText>
                  Ваши вечеринки — наша забота. <br /> Мы возьмем на себя
                  организацию праздника,
                  <br /> пока вы будете отдыхать вместе с друзьями.
                </Styles.BookingText>
                <a
                  href="tel:+7(964)-322-48-49"
                  style={{ textDecoration: 'none' }}
                >
                  <Styles.PhoneBlock>
                    <Button
                      additionalStyles="margin-right:40px;height:60px;width:60px;@media  @media (max-width: 480px) {
                        margin-right: 20px;
                      }"
                      background='#f25003 url("/icons/shared/phone-white.svg") center no-repeat'
                      hoverBackground="#171819 url('/icons/shared/phone-white.svg') center no-repeat"
                      typeButton="rounded"
                    />
                    <Styles.PhoneText>+7 (964) 322-48-49</Styles.PhoneText>
                  </Styles.PhoneBlock>
                </a>
              </Styles.ContactsBlock>
              <Styles.ContactsBlock>
                <Styles.SocialMedia>
                  <a href="https://vk.com/brick_spb" target="_blank">
                    <Button
                      typeButton="rounded"
                      background="#F25003 url('/icons/shared/vk-logo-white.svg') center no-repeat"
                      additionalStyles="
                        width: 60px;height: 60px;"
                      backgroundSize="23px 12px"
                      hoverBackground="#171819  url('/icons/shared/vk-logo-white.svg') center no-repeat;"
                      backgroundSizeMobile="15px 8px"
                    />
                  </a>
                  <a href="https://www.facebook.com/BrickSt.P" target="_blank">
                    <Button
                      typeButton="rounded"
                      background="#F25003 url('/icons/shared/facebook-logo-white.svg') center no-repeat"
                      additionalStyles="width: 60px;height: 60px;"
                      backgroundSize="23px 23px"
                      hoverBackground="#171819 url('/icons/shared/facebook-logo-white.svg') center no-repeat;"
                      backgroundSizeMobile="15px 15px"
                    />
                  </a>
                </Styles.SocialMedia>
                <Styles.MobilePhoneBlock>
                  <a
                    href="tel:+7(964)-322-48-49"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      additionalStyles="margin-right:60px;height:60px;width:60px; @media (max-width: 760px){margin-right:30px;};@media (max-width: 480px){margin-right: 20px;}"
                      background='#f25003 url("/icons/shared/phone-white.svg") center no-repeat'
                      hoverBackground="#171819 url('/icons/shared/phone-white.svg') center no-repeat"
                      typeButton="rounded"
                      backgroundSizeMobile="15px 15px"
                    />
                  </a>
                  <a
                    href="tel:+7(964)-322-48-49"
                    style={{ textDecoration: 'none' }}
                  >
                    <Styles.MobilePhoneText>
                      +7 (964) 322-48-49
                    </Styles.MobilePhoneText>
                  </a>
                </Styles.MobilePhoneBlock>
                <Styles.AdditionalInfoBlocks>
                  <Styles.AdditionalInfoBlock>
                    <Styles.BlockSup>Адрес</Styles.BlockSup>
                    <Styles.BlockText>
                      г. Санкт-Петербург, ул. Жуковского, д. 7-9
                    </Styles.BlockText>
                    <Styles.BlockText>
                      пн-чт, вс с 15 до 01
                    </Styles.BlockText>
                    <Styles.BlockText>
                      пт-сб с 15 до …
                    </Styles.BlockText>
                  </Styles.AdditionalInfoBlock>
                  <Styles.AdditionalInfoBlock>
                    <Styles.BlockSup>Эл. почта</Styles.BlockSup>
                    <Styles.BlockText>admin@brickbar.ru</Styles.BlockText>
                  </Styles.AdditionalInfoBlock>
                </Styles.AdditionalInfoBlocks>
              </Styles.ContactsBlock>
            </Styles.ContactsBlocks>
          </Styles.ContactsWrapper>
        </Container>
      </Styles.Contacts>
    </>
  )
}
