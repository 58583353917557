import { Action } from 'redux'
import { SalesState } from './state'
import { LoadingStatus } from '../../../types'

export enum SalesActionType {
  SET_SALES = 'sales/SET_SALES',
  FETCH_SALES = 'sales/FETCH_SALES',
  SET_LOADING_STATE = 'sales/SET_LOADING_STATE',
}

export interface ISetSalesAction extends Action<SalesActionType> {
  type: SalesActionType.SET_SALES
  payload: SalesState['items']
}

export interface IFetchSalesAction extends Action<SalesActionType> {
  type: SalesActionType.FETCH_SALES
}

export interface ISetSalesLoadingAction extends Action<SalesActionType> {
  type: SalesActionType.SET_LOADING_STATE
  payload: LoadingStatus
}

export type SalesActions =
  | ISetSalesAction
  | ISetSalesLoadingAction
  | IFetchSalesAction
