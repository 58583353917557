import styled from '@emotion/styled'

export const Card = styled.div`
  width: 902px;
  max-height: none;
  padding-bottom: 50px;
  top: 20%;
  left: calc(50% - 451px);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  background: url('/images/shared/default-background.webp');

  @media (max-width: 768px) {
    width: 100%;
    padding-bottom: 40px;
    max-height: none;
  }
  @media (max-width: 480px) {
    padding-bottom: 30px;
  }
`
export const CardWrapper = styled.div`
  position: relative;
  @media (max-width: 768px) {
    width: calc(100% - 40px);
    max-height: none;
  }
`
export const Blackening = styled.div`
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(23, 24, 25, 0.6);
`
export const CloseBtn = styled.div`
  cursor: pointer;
  position: absolute;
  right: -40px;
  top: -28px;
  box-sizing: border-box;
  span,
  span:after,
  span:before {
    display: block;
    background: #a1a1a1;
    content: '';
    width: 24px;
    transition: 0.3s;
    height: 2px;
  }
  span {
    position: relative;
    &:after,
    &:before {
      position: absolute;
      left: calc(50% - 12px);
      top: calc(50% - 1px);
    }
    &:after {
      margin: 0;
      transform: translateY(5px);
      transform: rotate(135deg);
    }
    &:before {
      margin: 0;
      transform: translateY(-5px);
      transform: rotate(-135deg);
    }
    & {
      height: 0;

      background: transparent;
    }
  }

  &:hover {
    span:after,
    span:before {
      width: 27px;
      background: #fff;
    }
  }
  @media (max-width: 768px) {
    top: -45px;
    right: 0;
  }
  @media (max-width: 480px) {
    top: -30px;
    right: 0;
  }
`
export const Img = styled.img`
  max-width: none;
  max-height: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  @media (max-width: 480px) {
    max-width: none;
  }
`
export const ImgWrapper = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  height: 340px;
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 40px;
    height: 260px;
  }
  @media (max-width: 480px) {
    margin-bottom: 30px;
    height: 180px;
  }
`
export const Title = styled.div`
  font-style: normal;
  font-size: 30px;
  line-height: 140%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #171819;
  overflow: hidden;
  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 480px) {
    margin-bottom: 5px;
    font-size: 18px;
  }
`
export const Info = styled.span`
  display: block;
  color: #171819;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 480px) {
    font-size: 10px;
  }
`

export const Price = styled.div`
  text-transform: uppercase;
  color: #f25003;
  font-weight: normal;
  font-size: 30px;
  height: fit-content;
  @media (max-width: 768px) {
    font-size: 24px;
  }
  @media (max-width: 480px) {
    font-size: 18px;
  }
`
export const PriceBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    width: 300px;
    margin-bottom: 33px;
  }
  @media (max-width: 480px) {
    width: auto;
    margin-bottom: 20px;
    align-items: flex-start;
    flex-direction: column;
  }
`
export const InfoBlock = styled.div`
  padding: 0px 61px;
  @media (max-width: 768px) {
    padding: 0px 20px;
  }
  @media (max-width: 480px) {
    padding: 0px 15px;
  }
`
