import styled from '@emotion/styled'
export const Map = styled.section`
  overflow: hidden;
  width: 100%;
  height: 578px;
  @media (max-width: 768px) {
    height: 380px;
  }
  @media (max-width: 768px) {
    height: 320px;
  }
`
