import { call, put, takeLatest } from 'redux-saga/effects'
import { SalesApi } from '../../../services/api/salesApi'
import { setSales, setSalesLoadingStatus } from './actionCreators'
import { LoadingStatus } from '../../types'
import { SalesActionType } from './contracts/actionTypes'

export function* fetchSalesRequest() {
  try {
    const items = yield call(SalesApi.fetchSales)
    yield put(setSales(items))
  } catch (error) {
    yield put(setSalesLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* salesSaga(): any {
  yield takeLatest(SalesActionType.FETCH_SALES, fetchSalesRequest)
}
