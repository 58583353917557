import React from 'react'
import * as Styles from './Slide.styles'
import { IGood } from '../../../../../store/ducks/goods/contracts/state'

interface Props {
  slide: IGood
}

export const Slide = ({ slide }: Props) => {
  const { title, description, photo, price } = slide
  return (
    <>
      <Styles.Slide>
        <Styles.ImgWrapper>
          <Styles.Img src={photo} />
        </Styles.ImgWrapper>
        <Styles.Title>{title}</Styles.Title>
        <Styles.Info>{description}</Styles.Info>
        <Styles.PriceWrapper>
          <Styles.Price>{price} ₽</Styles.Price>
        </Styles.PriceWrapper>
      </Styles.Slide>
    </>
  )
}
