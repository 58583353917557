import React, { useState } from 'react'
import Slider from 'react-slick'
import * as Styles from './MenuSlider.styles'
import { Button } from '../../../../UIHelpers/Buttons'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Slide } from './Slide'

import { Container } from '../../../../UIHelpers/Container'
import { MyLoader } from './Preloader/Preloader'
import { Link } from 'react-router-dom'
import { DataContext } from '../../../../Context/DataContext'
export interface ISlide {
  title: string
  info: string
  btnText: string
}
interface Props {}

export const MenuSlider = ({}: Props) => {
  const { isLoadingGoods, goodData } = React.useContext(DataContext)

  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    afterChange: setCurrentIndex,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const slideToShow = document?.documentElement?.clientWidth <= 768 ? 2 : 3
  return (
    <>
      <Styles.MenuSlider>
        <Container>
          <Styles.SliderWrapper>
            <Styles.Preslider>
              <Styles.SupTitle>Популярные блюда</Styles.SupTitle>
              <Styles.Block>
                <Styles.Title>
                  ШЕДЕВРЫ НАШИХ ПОВАРОВ — ТО, ЧТО НУЖНО ОБЯЗАТЕЛЬНО ПОПРОБОВАТЬ!
                </Styles.Title>

                <Link to="menu">
                  <Button
                    typeButton="normal"
                    background="#F25003"
                    color="#F1F1F1"
                    content="СМОТРЕТЬ МЕНЮ"
                    hoverBackground="#ff8763"
                  />
                </Link>
              </Styles.Block>
            </Styles.Preslider>
            <Slider {...settings}>
              {isLoadingGoods
                ? new Array(3).fill('').map((_, i) => <MyLoader key={i} />)
                : goodData?.map((good: any, i) => {
                    if (i > 14) {
                      return
                    }
                    return <Slide slide={good} key={good.title} />
                  })}
            </Slider>
          </Styles.SliderWrapper>
          {!isLoadingGoods && (
            <Styles.ProgressBar
              value={currentIndex + slideToShow}
              max={14}
            ></Styles.ProgressBar>
          )}
        </Container>
      </Styles.MenuSlider>
    </>
  )
}
