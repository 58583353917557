import React, { useEffect, useRef } from 'react'
import { Link } from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom'

import { Button } from '../../../../UIHelpers/Buttons'
import { Container } from '../../../../UIHelpers/Container'
import * as Styles from './Main.styles'

interface Props {}

export const Main = ({}: Props) => {
  return (
    <>
      <Styles.MainBg>
        <Styles.Main>
          <Container>
            <Styles.MainWrapper>
              <Styles.BgImg></Styles.BgImg>
              <Styles.Title>Кажется, что-то пошло не так...</Styles.Title>
              <Styles.Info>
                Страница, которую вы запрашиваете, не существует. Возможно,
                ссылка устарела или допущена ошибка в адресной строке
              </Styles.Info>
              <Button
                href="/"
                typeButton="normal"
                background="##f25003"
                color="#F1F1F1"
                content="Вернуться на главную"
                additionalStyles="@media(max-width:480px){margin-bottom:30px;font-size:8px;width:180px;height:45px;}"
                hoverBackground="#F1F1F1"
                hoverColor="#f25003"
              />
            </Styles.MainWrapper>
          </Container>
        </Styles.Main>
      </Styles.MainBg>
    </>
  )
}
