import { createSelector } from 'reselect'
import { SalesState } from './contracts/state'
import { LoadingStatus } from '../../types'
import { RootState } from '../../store'

export const selectSales = (state: RootState): SalesState => state.sales

export const selectSalesItems = createSelector(
  selectSales,
  sales => sales.items
)

export const selectLoadingStatus = (state: RootState) =>
  selectSales(state).loadingState

export const selectIsSalesLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING
