import styled from '@emotion/styled'

export const MainDishes = styled.div``

export const CardsContainer = styled.div`
  position: relative;
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Selector = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: 110px;
  flex-wrap: wrap;
  &:after {
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: rgba(23, 24, 25, 0.2);
    z-index: 0;
    @media (max-width: 1487px) {
      display: none;
    }
  }

  .no-active {
    &:after {
      transition: 0.4s;
      z-index: 0;
      content: '';
      position: absolute;
      height: 4px;
      width: 0px;
      opacity: 0;
      bottom: -30px;
      right: 0;
      background: #f25003;
    }
  }

  .active {
    &:after {
      transition: 0.4s;
      z-index: 1;
      opacity: 1;
      content: '';
      position: absolute;
      height: 4px;
      width: 100%;
      bottom: -30px;
      left: 0;
      background: #f25003;
      @media (max-width: 1487px) {
        height: 3px;
        bottom: -5px;
      }
    }
  }
  @media (max-width: 1487px) {
    flex-wrap: wrap;
  }
`
export const SelectorItem = styled.div`
  margin-right: 60px;
  word-spacing: 6px;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 30px;
  margin-top: 20px;
  &:last-of-type {
    margin-right: 0;
  }
  position: relative;

  @media (max-width: 1487px) {
    margin: 0px 25px 25px 0;
  }
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  margin: 0;
  line-height: 150%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #171819;
  margin-bottom: 100px;
  @media (max-width: 480px) {
    margin-bottom: 60px;
  }
  @media (max-width: 480px) {
    margin-bottom: 40px;
  }
`
export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 100px;
  font-weight: bold;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 80px;
    align-items: flex-start;
  }
`
export const ScrollBlock = styled.div`
  @media (max-width: 768px) {
    height: 80px;
  }
`

export const BottomBlock = styled.div`
  margin-bottom: 20px;
  margin-top: 60px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  span {
    color: rgba(23, 24, 25, 0.6);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
  img {
    margin-right: 20px;
  }
`

export const BottomBlock2 = styled.div`
  margin-bottom: 150px;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 20px;
  }
  span {
    color: rgba(23, 24, 25, 0.6);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
  img {
    margin-right: 20px;
  }
`

export const PDFDownload = styled.a`
  color: #333;
`
