import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Select from 'react-select'
import { fetchCategorys } from '../../../../store/ducks/categorys/actionCreators'
import { selectCategorysItems } from '../../../../store/ducks/categorys/selectors'
import * as Styles from './Select.styles'

export interface ISelect {
  value: string
  label: string
}

interface Props {
  setSelect: any
  select: ISelect[] | ISelect | null
  errorCategory?: any
  setErrorCategory?: any
  isMulti?: boolean
  title?: string
  name: string
  disabledValue?: string
}

export const SelectBlock: React.FC<Props> = ({
  setSelect,
  select,
  errorCategory,
  setErrorCategory,
  isMulti = true,
  title,
  name,
  disabledValue,
}: Props) => {
  const dispatch = useDispatch()
  const categorysData = useSelector(selectCategorysItems)
  const categorys = [
    ...categorysData.map(category => {
      return {
        parent: category.parent,
        label: category.label,
        value: category.value,
        isDisabled: name === 'parent' && category.value === disabledValue,
      }
    }),
  ]

  useEffect(() => {
    dispatch(fetchCategorys())
  }, [dispatch])

  return (
    <Styles.SelectBlock>
      <Styles.Label>{title}</Styles.Label>
      <Select
        isMulti={isMulti}
        name={name}
        onChange={e => {
          if (setErrorCategory) {
            !e ? setErrorCategory(true) : setErrorCategory(false)
          }
          setSelect((prev: ISelect) => ({
            ...prev,
            [name]: e,
          }))
        }}
        value={select}
        placeholder="Выберите категории..."
        options={categorys}
        className="basic-multi-select"
        classNamePrefix="select"
      />
      <p>{errorCategory ? 'Категория обязательна' : ''}</p>
    </Styles.SelectBlock>
  )
}
