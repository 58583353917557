import React from 'react'
import ContentLoader from 'react-content-loader'

export const MyLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={460}
    height={484}
    viewBox="0 0 460 484"
    backgroundColor="#eeeeee"
    foregroundColor="#fafafa"
    {...props}
  >
    <rect x="0" y="0" rx="0" ry="0" width="460" height="320" />
    <rect x="0" y="361" rx="0" ry="0" width="228" height="23" />
    <rect x="126" y="413" rx="0" ry="0" width="4" height="0" />
    <rect x="0" y="399" rx="0" ry="0" width="510" height="16" />
    <rect x="0" y="455" rx="10" ry="10" width="112" height="21" />
  </ContentLoader>
)
