import styled from '@emotion/styled'

export const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 140px;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  @media (max-width: 480px) {
    margin-bottom: 30px;
    justify-content: space-between;
  }
  &:after {
    content: '';
    flex: auto;
    max-width: 460px;
    min-width: 200px;
  }
`

export const ShowMore = styled.div`
  position: absolute;

  font-style: normal;
  cursor: pointer;
  font-weight: normal;
  font-size: 22px;
  line-height: 150%;
  color: #171819;
  transition: 0.5s;
  display: flex;
  align-items: center;

  .show-more {
    width: 30px;
    height: 30px;
    fill: #171819;
    margin-right: 20px;
    transition: 0.5s;
  }

  left: calc(50% - 174px);
  bottom: 100px;
  z-index: 100;

  @media (max-width: 768px) {
    left: calc(50% - 75px);
    margin-bottom: -100px;
    font-size: 14px;
    justify-content: space-between;
    .show-more {
      margin-right: 10px;
    }
  }
`
