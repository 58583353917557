import { axios } from '../../core/axios';
import { IGood } from '../../store/ducks/goods/contracts/state';

interface Response<T> {
  status: string;
  data: T;
}

export const GoodsApi = {
  async fetchGoods(): Promise<IGood[]> {
    const { data } = await axios.get<Response<IGood[]>>('/goods');
    return data.data;
  },
  async fetchGoodsByCategory(payload: string): Promise<IGood[]> {
    const { data } = await axios.get<Response<IGood[]>>(
      `/goods/categorys/${payload}`
    );
    return data.data;
  },

  async fetchGoodData(id: string): Promise<IGood> {
    const { data } = await axios.get<Response<IGood>>('/goods/' + id);
    return data.data;
  },

  async addGood(payload: IGood): Promise<IGood> {
    const { data } = await axios.post<Response<IGood>>('/goods', payload);
    return data.data;
  },

  async updateGoodData(payload: IGood, id: string): Promise<Response<IGood>> {
    const { data } = await axios.patch('/goods/' + id, payload);
    return data;
  },

  async deleteGood(id: string): Promise<Response<IGood>> {
    const { data } = await axios.delete('/goods/' + id);
    return data;
  },
};
