import styled from '@emotion/styled'

export const MainBg = styled.div`
  background: url('/images/contacts/main-bg-pc.webp') center no-repeat;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 768px) {
    background: url('/images/contacts/main-bg-tablet.webp') center no-repeat;
    background-size: cover;
  }
  @media (max-width: 480px) {
    background: url('/images/contacts/main-bg-phone.webp') bottom -20px center no-repeat;
    background-size: cover;
  }
`
export const Main = styled.section`
  padding: 80px 0 100px 0;
  @media (max-width: 768px) {
    padding: 144px 0 131px 0;
  }
  @media (max-width: 480px) {
    padding: 110px 0 151px 0;
  }
`
export const MainWrapper = styled.div`
  @media (max-width: 768px) {
    text-align: center;
  }
`
export const RouteItems = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin: 0 auto 40px auto;
    max-width: fit-content;
  }
  @media (max-width: 480px) {
    margin: 0 auto 30px auto;
  }
`
export const RouteItem = styled.div`
  &:first-of-type {
    margin-right: 14px;
  }
  a {
    @media (min-width: 1024px) {
      &:hover {
        color: #f25003;
      }
      transition: 0.2s;
    }
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: #f1f1f1;
    text-decoration: none;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`
export const RouteArrow = styled.img`
  margin-right: 14px;
  width: 20px;
  height: 15px;
  margin-top: 5px;
`
export const Title = styled.h1`
  text-transform: uppercase;
  line-height: 150%;
  font-size: 42px;
  color: #f25003;
  margin: 0 0 30px 0;
  @media (max-width: 768px) {
    font-size: 34px;
  }
  @media (max-width: 480px) {
    margin: 0 auto 15px auto;
    font-size: 24px;
  }
`
export const Info = styled.div`
  font-size: 24px;
  line-height: 150%;
  color: #f1f1f1;
  margin-bottom: 135px;
  max-width: 780px;
  @media (max-width: 768px) {
    max-width: 655px;
    margin: 0 auto 80px auto;
    font-size: 18px;
  }
  @media (max-width: 480px) {
    margin: 0 auto 40px auto;
    font-size: 14px;
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1120px;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    width: 100%;
  }
  @media (max-width: 320px) {
    justify-content: center;
  }
`
