import styled from '@emotion/styled';

export const Preloader = styled.div`
  .container {
    .spinner {
      --animation-duration: 700ms;
      .spinner-item {
        --item-size: calc(var(--size) / 4);
        width: 10px;
        height: 10px;
        display: inline-block;
        margin: 0 3px;
        border-radius: 50%;
        border: 1px solid #1adbad;
        animation: spinner4 var(--animation-duration) ease-in-out infinite;

        @keyframes spinner4 {
          0%,
          100% {
            transform: translateY(35%);
          }

          50% {
            transform: translateY(-35%);
          }
        }
      }

      .spinner-item:nth-of-type(1) {
        background: #1adbad;
        animation-delay: calc(var(--animation-duration) / 6 * -1);
      }

      .spinner-item:nth-of-type(2) {
        background: #1adbad;
        animation-delay: calc(var(--animation-duration) / 6 * -2);
      }

      .spinner-item:nth-of-type(3) {
        background: #1adbad;
        animation-delay: calc(var(--animation-duration) / 6 * -3);
      }
    }
  }
`;
