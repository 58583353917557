import React from 'react';
import { ISale } from '../../../../../../store/ducks/sales/contracts/state';
import { useHistory } from 'react-router';

interface Props {
  sale: ISale;
  setId: any;
}

import * as Styles from './SalePreview.styles';
import { formatDate } from '../../../../../../utils/formatDate';
import { Link } from 'react-router-dom';
import { getMediaLink } from '../../../../../../utils/mediaHelper';

export const Sale: React.FC<Props> = ({ sale, setId }: Props) => {
  const onChange = (e: any): void => {
    const checked = (e.target as HTMLInputElement).checked;
    if (checked) {
      setId(() => ({
        id: sale._id,
        type: 'add',
      }));
    } else {
      setId(() => ({
        id: sale._id,
        type: 'delete',
      }));
    }
  };

  return (
    <Styles.Sale>
      <div>
        <Styles.TitleWrap>
          <Link to={`/admin/sale/edit/${sale._id}`}>
            <Styles.Title>{sale.title}</Styles.Title>
          </Link>
          <Styles.InputWrap>
            <Styles.Input id={sale._id} onChange={onChange} type="checkbox" />
            <Styles.Delete htmlFor={sale._id}>Удалить</Styles.Delete>
          </Styles.InputWrap>
        </Styles.TitleWrap>
        <Link to={`/admin/sale/edit/${sale._id}`}>
          <Styles.Img src={getMediaLink(sale.photo)} alt="" />
        </Link>
      </div>
      <div>
        <Styles.CreatedAt>
          Создано {formatDate(new Date(sale.createdAt || '1'))} назад
        </Styles.CreatedAt>

        <Styles.UpdatedAt>
          Обновлено {formatDate(new Date(sale.updatedAt || '1'))} назад
        </Styles.UpdatedAt>
      </div>
    </Styles.Sale>
  );
};
