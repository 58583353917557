import { ISale } from '../sales/contracts/state'
import { LoadingStatus } from '../../types'
import {
  FetchSaleDataActionInterface,
  SetSaleDataActionInterface,
  SetSaleLoadingStatusActionInterface,
  SaleActionsType,
  UpdateSaleDataActionInterface,
  AddSaleActionInterface,
  DeleteSaleDataActionInterface,
  SetDeleteSaleDataActionInterface,
} from './contracts/actionTypes'
import { SaleState } from './contracts/state'

export const setSaleData = (
  payload: SaleState['data']
): SetSaleDataActionInterface => ({
  type: SaleActionsType.SET_SALE_DATA,
  payload,
})

export const setSaleLoadingStatus = (
  payload: LoadingStatus
): SetSaleLoadingStatusActionInterface => ({
  type: SaleActionsType.SET_LOADING_STATE,
  payload,
})

export const fetchSaleData = (
  payload: string
): FetchSaleDataActionInterface => ({
  type: SaleActionsType.FETCH_SALE_DATA,
  payload,
})

export const addSale = (payload: ISale): AddSaleActionInterface => ({
  type: SaleActionsType.ADD_SALE,
  payload,
})

export const deleteSaleData = (
  payload: string
): DeleteSaleDataActionInterface => ({
  type: SaleActionsType.DELETE_SALE_DATA,
  payload,
})

export const setDeleteSaleData = (): SetDeleteSaleDataActionInterface => ({
  type: SaleActionsType.SET_LOADING_STATE,
})

export const updateSaleData = (
  payload: ISale,
  id: string
): UpdateSaleDataActionInterface => ({
  type: SaleActionsType.UPDATE_SALE_DATA,
  payload,
  id,
})

export type SaleActions =
  | SetSaleDataActionInterface
  | FetchSaleDataActionInterface
  | SetSaleLoadingStatusActionInterface
  | UpdateSaleDataActionInterface
  | DeleteSaleDataActionInterface
  | AddSaleActionInterface
