import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import * as Styles from './Cookies.styles'

export function Cookies() {
  const [confirmation, setConfirmation] = useState<boolean>(
    getCookie('check') ? true : false
  )

  function handleConfirm() {
    const expires = new Date(Date.now() + 2592e9).toUTCString()
    document.cookie = `check=true; path=*; expires=${expires}`
    setConfirmation(true)
  }

  function getCookie(name: string) {
    let matches = document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  }

  return (
    <Styles.Popup style={{ visibility: confirmation ? 'hidden' : 'visible' }}>
      <Styles.ContentWrapper>
        <Styles.CookiesImg src="/images/shared/cookie.svg" alt="cookies" />
        <Styles.Info>
          Здесь используются cookies, оставаясь на нашем сайте вы подтверждаете
          своё согласие с нашей{' '}
          <Styles.Link>
            <Link to="/privacy">
              политикой обработки данных и использования cookies.
            </Link>
          </Styles.Link>
        </Styles.Info>
      </Styles.ContentWrapper>

      <Styles.ButtonWrapper>
        <Styles.BtnConfirm onClick={handleConfirm}>
          <img src="/images/shared/close.svg" alt="Закрыть" />
        </Styles.BtnConfirm>
      </Styles.ButtonWrapper>
    </Styles.Popup>
  )
}
