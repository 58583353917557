import React from 'react'

interface Props {}
import * as Styles from './Contacts.styles'
import { Container } from '../../../UIHelpers/Container'
import { Button } from '../../../UIHelpers/Buttons'
import { Link } from 'react-scroll'

export const Contacts = () => {
  return (
    <>
      <Styles.Contacts id="contacts">
        <Container>
          <Styles.ContactsWrapper>
            <Styles.SupTitle>Контакты</Styles.SupTitle>
            <Styles.ContactsBlocks>
              <Styles.ContactsBlock>
                <Styles.Title>Мы вСЕГДА ВАМ РАДЫ!</Styles.Title>
                <Styles.BookingText>
                  Ваши вечеринки — наша забота. <br /> Мы возьмем на себя
                  организацию праздника,
                  <br /> пока вы будете отдыхать вместе с друзьями.
                </Styles.BookingText>

                <Link to="booking">
                  <Button
                    typeButton="normal"
                    content="Забронировать столик"
                    hoverBackground="#f89577"
                  />
                </Link>
              </Styles.ContactsBlock>
              <Styles.ContactsBlock>
                <a
                  href="tel:+7(964)-322-48-49"
                  style={{ textDecoration: 'none' }}
                >
                  <Styles.PhoneBlock>
                    <Button
                      additionalStyles="margin-right:40px;height:60px;width:60px;@media  @media (max-width: 480px) {
                        margin-right: 20px;
                      }"
                      background='#f25003 url("/icons/shared/phone-white.svg") center no-repeat'
                      hoverBackground="#F33B01 url('/icons/shared/phone-white.svg') center no-repeat"
                      typeButton="rounded"
                    />
                    <Styles.PhoneText>+7 (964) 322-48-49</Styles.PhoneText>
                  </Styles.PhoneBlock>
                </a>
                <Styles.AdditionalInfoBlocks>
                  <Styles.AdditionalInfoBlock>
                    <Styles.BlockSup>Адрес</Styles.BlockSup>
                    <Styles.BlockText>
                      г. Санкт-Петербург, ул. Жуковского, д. 7-9
                    </Styles.BlockText>
                    <Styles.BlockText>
                      пн-чт, вс с 15 до 01
                    </Styles.BlockText>
                    <Styles.BlockText>
                      пт-сб с 15 до …
                    </Styles.BlockText>
                  </Styles.AdditionalInfoBlock>
                  <Styles.AdditionalInfoBlock>
                    <Styles.BlockSup>Эл. почта</Styles.BlockSup>
                    <Styles.BlockText>admin@brickbar.ru</Styles.BlockText>
                  </Styles.AdditionalInfoBlock>
                </Styles.AdditionalInfoBlocks>
              </Styles.ContactsBlock>
            </Styles.ContactsBlocks>
          </Styles.ContactsWrapper>
        </Container>
      </Styles.Contacts>
    </>
  )
}
