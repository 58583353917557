import { call, put, takeLatest } from 'redux-saga/effects';
import { GoodsApi } from '../../../services/api/goodsApi';
import { setGoods, setGoodsLoadingStatus } from './actionCreators';
import { LoadingStatus } from '../../types';
import {
  GoodsActionType,
  IFetchGoodsByCategoryAction,
} from './contracts/actionTypes';

export function* fetchGoodsRequest(): any {
  try {
    const items = yield call(GoodsApi.fetchGoods);
    yield put(setGoods(items));
  } catch (error) {
    yield put(setGoodsLoadingStatus(LoadingStatus.ERROR));
  }
}
export function* fetchGoodsByCategoryRequest({
  payload,
}: IFetchGoodsByCategoryAction): any {
  try {
    const items = yield call(GoodsApi.fetchGoodsByCategory, payload);
    yield put(setGoods(items));
  } catch (error) {
    yield put(setGoodsLoadingStatus(LoadingStatus.ERROR));
  }
}

export function* goodsSaga(): any {
  yield takeLatest(
    GoodsActionType.FETCH_GOODS_BY_CATEGORY,
    fetchGoodsByCategoryRequest
  );
  yield takeLatest(GoodsActionType.FETCH_GOODS, fetchGoodsRequest);
}
