import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import React from 'react'

interface NotificationProps {
  children: (callback: (text: string, type: any) => void) => React.ReactElement
}

export const Notification: React.FC<NotificationProps> = ({
  children,
}): React.ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false)
  const [notificationObj, setNotificationObj] = React.useState<{
    text: string
    type: any
  }>()

  const openNotification = (text: string, type: any) => {
    setNotificationObj({
      text,
      type,
    })
    setOpen(true)
  }

  return (
    <>
      {children(openNotification)}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={notificationObj?.type}>
          {notificationObj?.text}
        </Alert>
      </Snackbar>
    </>
  )
}
