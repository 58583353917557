import styled from '@emotion/styled'

export const Slide = styled.div`
  max-width: 513px;
  max-height: none;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 1600px) {
    max-width: 460px;
    max-height: none;
  }
  @media (max-width: 1200px) {
    max-width: 320px;
    max-height: none;
  }
  @media (max-width: 768px) {
    max-width: 354px;
    max-height: none;
  }
  @media (max-width: 480px) {
    max-width: 151px;
    max-height: none;
  }
  @media (max-width: 350px) {
    max-width: 130px;
    max-height: none;
  }
`

export const Img = styled.img`
  max-width: none;
  max-height: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  @media (max-width: 480px) {
    max-width: none;
    max-height: 120px;
  }
`
export const ImgWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;

  height: 320px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    margin-bottom: 15px;
    height: 120px;
  }
`
export const Title = styled.div`
  text-transform: uppercase;
  min-height: 28px;
  max-height: 56px;
  margin-bottom: 15px;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.08em;
  color: #f25003;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  box-orient: vertical;
  @media (max-width: 768px) {
    min-height: 24px;
    max-height: 24px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    min-height: 33.5px;
    max-height: 33.5px;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    margin-bottom: 5px;
    font-size: 12px;
  }
`
export const Info = styled.span`
  min-height: 81px;
  display: block;
  color: #171819;
  font-size: 18px;
  margin-bottom: 50px;
  line-height: 150%;
  font-weight: 400;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  box-orient: vertical;
  @media (max-width: 768px) {
    min-height: 42px;
    font-size: 14px;
    margin-bottom: 40px;
  }
  @media (max-width: 480px) {
    -webkit-line-clamp: 3;
    line-clamp: 3;
    margin-bottom: 30px;
    font-size: 10px;
    min-height: 45px;
    max-height: 45px;
  }
`
export const PriceWrapper = styled.div``

export const Price = styled.div`
  color: #f25003;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
  }
`
