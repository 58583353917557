import React, { useEffect } from 'react'
import { Link, Link as RouterLink } from 'react-router-dom'
import { Link as LinkScroll } from 'react-scroll'
import { Header } from '../../../../shared/containers/Header/Header'
import { Button } from '../../../../UIHelpers/Buttons'
import { Container } from '../../../../UIHelpers/Container'
import * as Styles from './Main.styles'

interface Props {}

export const Main = ({}: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Header />
      <Styles.MainBg>
        <Styles.Main>
          <Container>
            <Styles.MainWrapper>
              <Styles.RouteItems>
                <Styles.RouteItem>
                  <RouterLink to="/">Главная</RouterLink>
                </Styles.RouteItem>
                <Styles.RouteArrow src="/icons/menu/arrow.svg" />
                <Styles.RouteItem>
                  <RouterLink to="/contacts">Контакты</RouterLink>
                </Styles.RouteItem>
              </Styles.RouteItems>
              <Styles.Title>Контакты</Styles.Title>
              <Styles.Info>
                Ваши вечеринки — наша забота. <br /> Мы возьмем на себя
                организацию праздника,
                <br /> пока вы будете отдыхать вместе с друзьями.
              </Styles.Info>
              <LinkScroll to="booking">
                <Button
                  typeButton="normal"
                  background="##f25003"
                  color="#F1F1F1"
                  content="Забронировать столик"
                  additionalStyles="@media(max-width:768px){width:166px; height:45px;font-size:9px;};@media(max-width:480px){margin-bottom:30px;font-size:8px;width:162px;height:45px;}"
                  hoverBackground="#F1F1F1"
                  hoverColor="#f25003"
                />
              </LinkScroll>
            </Styles.MainWrapper>
          </Container>
        </Styles.Main>
      </Styles.MainBg>
    </>
  )
}
