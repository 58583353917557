import React, { useEffect } from 'react';
import { Main } from '../components/Main/Main';
import { AboutUs } from '../components/AboutUs/AboutUs';
import { Menu } from '../components/Menu';
import { MenuSlider } from '../components/MenuSlider/MenuSlider';
import { Booking } from '../../../shared/containers/Booking/Booking';
import { Footer } from '../../../shared/containers/Footer/Footer';
import { NewsSlider } from '../../../shared/containers/NewsSlider/NewsSlider';
import { Contacts } from '../../../shared/containers/Contacts/Contacts';
import { Map } from '../../../shared/containers/Map/Map';
import { Instagram } from '../../../shared/containers/Instagram';
import MetaTags from 'react-meta-tags';

interface Props {}

import * as Styles from './HomePage.styles';
export const HomePage: React.FC = ({}: Props) => {
  const path = window.location.href;

  useEffect(() => {
    const currentElementId = path.split('#')[1];

    switch (currentElementId) {
      case 'booking':
        document.getElementById('booking')?.scrollIntoView();
        break;
      default:
        window.scrollTo(0, 0);
        break;
    }
  }, [path]);
  return (
    <>
      <MetaTags>
        <title>BRICK GASTRO BAR | Гастробар на Жуковского</title>
      </MetaTags>

      <Styles.Wrapper>
        <Main />
        <AboutUs />
        <Menu />
        <MenuSlider />
        <Booking />
        <NewsSlider />
        <Contacts />
        <Map />
        <Instagram />
        <Footer />
      </Styles.Wrapper>
    </>
  );
};
