import styled from '@emotion/styled'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export const Post = styled.form`
  margin-top: 120px;
  margin-bottom: 20px;
`
export const InputFileWrapper = styled.div`
  box-shadow: rgba(208, 208, 208, 0.5) 4px 4px 10px;
  position: relative;
  border-radius: 5px;
  padding-left: 14px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  img {
    max-width: 400px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
`

export const Title = styled.h1`
  color: #42a5f5;
  margin: 0;
  font-weight: 500;
  margin-bottom: 40px;
`

export const HeaderPost = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    button {
      margin-left: 10px;
    }
  }
`

export const Loading = styled.div`
  margin-top: 120px;
`
export const ReactQuillWrapper = styled.div`
  .error {
    margin-top: 8px;
    font-size: 12px;
    color: tomato;
  }
  margin-bottom: 60px;
`

export const ReactQuillStyled = styled(ReactQuill)`
  min-height: 600px;

  box-shadow: rgba(208, 208, 208, 0.5) 4px 4px 10px;
  .ql-container {
    border: none;
  }
  .ql-toolbar {
    border: none;
    border-bottom: 1px solid #333;
  }
  border-radius: 3px;
  margin-top: 40px;
  img {
    width: 100%;
    max-width: 100%;
    margin: 20px 0;
  }
`

export const SelectBlock = styled.div`
  margin-bottom: 28px;
  p {
    margin-top: 8px;
    font-size: 12px;
    color: tomato;
  }
  width: 100%;
  .basic-multi-select {
    margin-top: 10px;
    .select__control {
      border: none;
      min-height: 60px;
      box-shadow: 4px 4px 10px rgb(208 208 208 / 50%);
      @media (max-width: 768px) {
        min-height: 32px;
      }
    }
    .select__menu {
      z-index: 1000 !important;
    }
    .select__placeholder {
      color: #bcc2c7;
      font-size: 16px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .select__single-value {
      font-size: 16px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .select__indicator-separator {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`

export const Label = styled.label``
