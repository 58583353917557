import produce, { Draft } from 'immer'
import { LoadingStatus } from '../../types'
import { CategoryActions } from './actionCreators'
import { CategoryActionsType } from './contracts/actionTypes'
import { CategoryState } from './contracts/state'

const initialCategoryState: CategoryState = {
  data: undefined,
  LoadingStatus: LoadingStatus.NEVER,
}

export const categoryReducer = produce(
  (draft: Draft<CategoryState>, action: CategoryActions) => {
    switch (action.type) {
      case CategoryActionsType.SET_CATEGORY_DATA:
        draft.data = action.payload
        draft.LoadingStatus = LoadingStatus.LOADED
        break

      case CategoryActionsType.FETCH_CATEGORY_DATA:
        draft.data = undefined
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      case CategoryActionsType.UPDATE_CATEGORY_DATA:
        draft.data = undefined
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      case CategoryActionsType.SET_LOADING_STATE:
        draft.LoadingStatus = action.payload
        break
      case CategoryActionsType.DELETE_CATEGORY_DATA:
        draft.LoadingStatus = LoadingStatus.LOADING
        break
      case CategoryActionsType.ADD_CATEGORY:
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      default:
        break
    }
  },
  initialCategoryState
)
