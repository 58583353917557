import {
  IFetchSalesAction,
  ISetSalesLoadingAction,
  ISetSalesAction,
  SalesActionType,
} from './contracts/actionTypes'
import { SalesState } from './contracts/state'
import { LoadingStatus } from '../../types'

export const setSales = (payload: SalesState['items']): ISetSalesAction => ({
  type: SalesActionType.SET_SALES,
  payload,
})

export const setSalesLoadingStatus = (
  payload: LoadingStatus
): ISetSalesLoadingAction => ({
  type: SalesActionType.SET_LOADING_STATE,
  payload,
})

export const fetchSales = (): IFetchSalesAction => ({
  type: SalesActionType.FETCH_SALES,
})
