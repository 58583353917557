import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Suptitle = styled.div`
  color: rgba(23, 24, 25, 0.4);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    margin-bottom: 20px;
    font-size: 10px;
  }
`
