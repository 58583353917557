import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { CategoryState } from './ducks/category/contracts/state'
import { CategorysState } from './ducks/categorys/contracts/state'
import { GoodState } from './ducks/good/contracts/state'
import { GoodsState } from './ducks/goods/contracts/state'
import { PostState } from './ducks/post/contracts/state'
import { PostsState } from './ducks/posts/contracts/state'
import { SaleState } from './ducks/sale/contracts/state'
import { SalesState } from './ducks/sales/contracts/state'
import { UserState } from './ducks/user/contracts/state'
import { rootReducer } from './rootReducer'

import rootSaga from './saga'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
  }
}

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const sagaMiddleware = createSagaMiddleware()

export interface RootState {
  posts: PostsState
  post: PostState
  goods: GoodsState
  good: GoodState
  sales: SalesState
  sale: SaleState
  user: UserState
  category: CategoryState
  categorys: CategorysState
}

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(rootSaga)
