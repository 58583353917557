import styled from '@emotion/styled'

export const Card = styled.div`
  width: 513px;
  max-height: none;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.2s;
  cursor: pointer;
  @media (min-width: 1024px) {
    &:hover {
      transform: translateY(-5px);
    }
    margin-right: 20px;
    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }
  }

  @media (max-width: 1600px) {
    width: 440px;
    max-height: none;
  }
  @media (max-width: 1366px) {
    width: 420px;
    max-height: none;
  }
  @media (max-width: 1200px) {
    width: 310px;
    max-height: none;
  }
  @media (max-width: 768px) {
    width: 354px;
    max-height: none;
    margin-bottom: 60px;
    &:nth-of-type(3n) {
      margin-right: none;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
  @media (max-width: 480px) {
    width: 180px;
    max-height: none;
    margin-bottom: 40px;
  }
  @media (max-width: 400px) {
    width: 160px;
    max-height: none;
  }
  @media (max-width: 353px) {
    width: 130px;
    max-height: none;
  }
  @media (max-width: 350px) {
    width: 130px;
    max-height: none;
  }
`

export const Img = styled.img`
  max-width: none;
  max-height: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  @media (max-width: 480px) {
    max-width: none;
    max-height: 120px;
  }
`
export const ImgWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  height: 320px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    margin-bottom: 15px;
    height: 120px;
  }
`
export const Title = styled.div`
  text-transform: uppercase;
  min-height: 28px;
  max-height: 56px;
  margin-bottom: 15px;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.08em;
  color: #f25003;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  box-orient: vertical;
  @media (max-width: 768px) {
    min-height: 26px;
    max-height: 26px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    min-height: 33px;
    max-height: 33px;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    margin-bottom: 5px;
    font-size: 12px;
  }
`
export const Info = styled.span`
  min-height: 54px;
  display: block;
  color: #171819;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  box-orient: vertical;
  @media (max-width: 768px) {
    min-height: 42px;
    font-size: 14px;
    margin-bottom: 40px;
  }
  @media (max-width: 480px) {
    -webkit-line-clamp: 3;
    line-clamp: 3;
    margin-bottom: 10px;
    font-size: 10px;
    min-height: 45px;
    max-height: 45px;
  }
`
export const PriceWrapper = styled.div`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`
export const PriceWrapperAlco = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  @media (max-width: 480px) {
    font-size: 10px;
  }
`

export const Price = styled.div`
  color: #f25003;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`
export const CardAlco = styled.div`
  justify-self: space-between;
  padding: 0 31px 43px 0;
  margin: 0 0 30px 0;
  width: 48%;
  max-height: none;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 2px solid rgba(23, 24, 25, 0.2);
  &:nth-of-type(2n) {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    margin: 0 0 15px 0;
    padding: 0 40px 20px 0;
    width: 100%;
  }
  @media (max-width: 480px) {
    margin: 0 0 20px 0;
    padding: 0 10px 15px 0;
  }
`
export const Price2 = styled.div`
  color: #f25003;
  font-weight: normal;
  font-size: 20px;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
  }
`
export const TitleAlco = styled.div`
  text-transform: uppercase;
  max-height: 28px;
  max-width: 580px;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.08em;
  color: #f25003;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  box-orient: vertical;
  @media (max-width: 768px) {
    max-height: 22px;
    font-size: 16px;
    margin-bottom: 15px;
  }
  @media (max-width: 480px) {
    max-height: 17px;
    margin-bottom: 5px;
    font-size: 12px;
  }
`
export const InfoAlco = styled.span`
  max-width: 580px;
  display: block;
  color: #171819;
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  box-orient: vertical;
  @media (max-width: 1440px) {
    max-width: 400px;
  }

  @media (max-width: 1024px) {
    max-width: 300px;
  }
  @media (max-width: 768px) {
    max-width: 450px;
    max-height: 50px;
    font-size: 14px;
  }
  @media (max-width: 480px) {
    max-width: 230px;
    font-size: 10px;
    max-height: 50px;
  }
`
