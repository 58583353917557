import styled from '@emotion/styled'

export const Slide = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  @media (max-width: 1200px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`
export const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 516px;
  align-self: center;
  @media (max-width: 1200px) {
    align-self: flex-start;
    max-width: 100%;
    margin-bottom: 72px;
  }
  @media (max-width: 480px) {
    margin-bottom: 40px;
  }
`
export const ImgWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  margin: 30px 30px 0 0;
  justify-content: center;
  align-items: center;
  width: 780px;
  height: 590px;
  position: relative;
  &:after {
    position: absolute;
    content: '';
    width: 69.33%;
    height: 69.33%;
    max-width: 69.33%;
    max-height: 69.33%;
    top: -30px;
    right: -30px;
    z-index: 1;
    background: #f25003;
  }
  @media (max-width: 1200px) {
    align-self: flex-start;
    margin-bottom: 63px;
    width: calc(100% - 20px);
    margin: 20px 20px 0 0;
    height: 420px;
    max-height: 420px;
    &:after {
      top: -20px;
      right: -20px;
    }
  }
  @media (max-width: 480px) {
    height: 222px;
    max-height: 222px;
    width: calc(100% - 10px);
    margin: 10px 10px 0 0;
    &:after {
      top: -10px;
      right: -10px;
    }
  }
`
export const Img = styled.img`
  overflow: hidden;
  object-fit: cover;
  max-width: none;
  max-height: 100%;
  height: 100%;
  width: fill-available;
  z-index: 10;
  position: relative;
`
export const Date = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.08em;
  color: rgba(23, 24, 25, 0.4);
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    font-size: 12px;
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    margin-bottom: 20px;
    font-size: 10px;
    margin-bottom: 10px;
  }
`
export const Title = styled.div`
  text-transform: uppercase;

  margin-bottom: 40px;
  font-style: normal;
  font-size: 30px;
  line-height: 140%;
  letter-spacing: 0.08em;
  color: #171819;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  @media (max-width: 1200px) {
    font-size: 26px;
    margin-bottom: 30px;
    min-height: 75px;
  }
  @media (max-width: 480px) {
    min-height: 75px;
    margin-bottom: 20px;
    font-size: 18px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
`
export const Info = styled.p`
  width: content-fit;
  line-height: 150%;
  margin: 0 0 40px 0;
  min-height: 300px;
  color: rgba(23, 24, 25, 0.8);
  font-size: 20px;
  font-weight: 400;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  line-clamp: 11;

  -webkit-box-orient: vertical;
  box-orient: vertical;
  @media (max-width: 1200px) {
    font-size: 16px;
    margin-bottom: 30px;
    min-height: 160px;
    -webkit-line-clamp: 7;
    line-clamp: 7;
  }
  @media (max-width: 480px) {
    margin-bottom: 20px;
    font-size: 14px;
    min-height: 252px;
    -webkit-line-clamp: 12;
    line-clamp: 12;
  }
`

export const LearnMore = styled.div`
  height: 30px;
  width: fit-content;
  border-bottom: 1px solid rgba(23, 24, 25, 0.8);
  line-height: 150%;
  a {
    color: rgba(23, 24, 25, 0.8);
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    text-decoration: none;
  }
  @media (max-width: 1200px) {
    font-size: 20px;
  }
  @media (max-width: 480px) {
    font-size: 14px;
    height: 26px;
  }
`
