import styled from '@emotion/styled'

export const NewsSection = styled.div``

export const CardsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
  @media (max-width: 480px) {
    margin-bottom: 0;
  }
`

export const Selector = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: 110px;
  &:after {
    content: '';
    position: absolute;
    height: 4px;
    width: 100%;
    bottom: -30px;
    left: 0;
    background: rgba(23, 24, 25, 0.2);
    z-index: 3;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .no-active {
    &:after {
      transition: 0.4s;
      z-index: 5;
      content: '';
      position: absolute;
      height: 4px;
      width: 0px;
      opacity: 0;
      bottom: -30px;
      right: 0;
      background: #f25003;
    }
  }

  .active {
    &:after {
      transition: 0.4s;
      z-index: 5;
      opacity: 1;
      content: '';
      position: absolute;
      height: 4px;
      width: 100%;
      bottom: -30px;
      left: 0;
      background: #f25003;
      @media (max-width: 768px) {
        height: 3px;
        bottom: -5px;
      }
    }
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
`
export const SelectorItem = styled.div`
  margin-right: 60px;
  text-transform: uppercase;
  cursor: pointer;
  &:last-of-type {
    margin-right: 0;
  }
  position: relative;
  @media (max-width: 768px) {
    margin: 0px 25px 25px 0;
  }
`

export const Title = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  margin: 0;
  line-height: 150%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #171819;
  margin-bottom: 100px;
  @media (max-width: 480px) {
    margin-bottom: 60px;
  }
  @media (max-width: 480px) {
    margin-bottom: 40px;
  }
`
export const ScrollBlock = styled.div`
  @media (max-width: 768px) {
    height: 80px;
  }
`
