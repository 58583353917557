import React from 'react';

interface Props {}
import * as Styles from './Menu.styles';
import { Container } from '../../../../UIHelpers/Container';
import { Link } from 'react-router-dom';

export const Menu = ({}: Props) => {
  const menus = [
    {
      name: 'Пивная карта',
      link: 'beer',
      img: '1',
    },
    {
      name: 'Кухня и закуски',
      link: 'kitchen',
      img: '2',
    },
    {
      name: 'Крепкий алкоголь',
      link: 'alcohol',
      img: '3',
    },
  ];

  return (
    <>
      <Styles.Menu>
        <Container>
          <Styles.MenuWrapper>
            <Styles.SupTitle>Наше меню</Styles.SupTitle>
            <Styles.Title>
              МЫ ДЕЛАЕМ ВСЁ ДЛЯ ТОГО, ЧТОБЫ НАШИ ГОСТИ ПОЛУЧИЛИ ГАСТРОНОМИЧЕСКОЕ
              УДОВОЛЬСТВИЕ
            </Styles.Title>
            <Styles.MenuItems>
              {menus.map((menu) => (
                <Styles.MenuItem key={menu.name}>
                  <Link to={`menu#${menu.link}`}>
                    <Styles.ItemImgWrapper>
                      <Styles.ItemImg
                        src={`/images/home/menu-${menu.img}.webp`}
                      />
                    </Styles.ItemImgWrapper>
                    <Styles.ItemText>{menu.name}</Styles.ItemText>
                  </Link>
                </Styles.MenuItem>
              ))}
            </Styles.MenuItems>
          </Styles.MenuWrapper>
        </Container>
      </Styles.Menu>
    </>
  );
};
