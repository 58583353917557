import { combineReducers } from 'redux'
import { categoryReducer } from './ducks/category/reducer'
import { categorysReducer } from './ducks/categorys/reducer'
import { goodReducer } from './ducks/good/reducer'
import { goodsReducer } from './ducks/goods/reducer'
import { postReducer } from './ducks/post/reducer'
import { postsReducer } from './ducks/posts/reducer'
import { saleReducer } from './ducks/sale/reducer'
import { salesReducer } from './ducks/sales/reducer'
import { userReducer } from './ducks/user/reducer'

export const rootReducer = combineReducers({
  posts: postsReducer,
  post: postReducer,
  goods: goodsReducer,
  good: goodReducer,
  sales: salesReducer,
  sale: saleReducer,
  categorys: categorysReducer,
  category: categoryReducer,
  user: userReducer,
})
