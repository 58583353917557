import { LinearProgress } from '@material-ui/core'
import React, { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { selectIsDeleteCategoryLoading } from '../../../../../store/ducks/category/selectors'
import { fetchCategorys } from '../../../../../store/ducks/categorys/actionCreators'
import {
  selectIsCategorysLoading,
  selectCategorysItems,
} from '../../../../../store/ducks/categorys/selectors'
import { Button } from '../../../components/Buttons'
import { Container } from '../../../components/Container'
import { Header } from '../../../components/Header'
import { Category } from '../components/CategoryPreview/CategoryPreview'

import MetaTags from 'react-meta-tags'

import * as Styles from './CategoryList.styles'

interface Props {}

interface ISetId {
  type: string
  id: string
}

export const CategoryList = ({}: Props) => {
  const dispatch = useDispatch()
  const categorys = useSelector(selectCategorysItems)
  const isLoading = useSelector(selectIsCategorysLoading)
  const deleteLoading = useSelector(selectIsDeleteCategoryLoading)

  const [id, setId] = useState<ISetId>({
    type: '',
    id: '',
  })

  const [idDeleted, setIdDeleted] = useState<string[]>([])

  const [_, forceUpdate] = useReducer(x => x + 1, 0)

  useEffect(() => {
    dispatch(fetchCategorys())
  }, [dispatch, deleteLoading])

  useEffect(() => {
    if (id.type === 'add') {
      idDeleted.push(id.id)
    } else {
      idDeleted.forEach((idD: string, i: number) => {
        if (idD === id.id) {
          idDeleted.splice(i, 1)
        }
      })
    }
    setIdDeleted(idDeleted)
    forceUpdate()
  }, [id])

  if (isLoading) {
    return (
      <>
        <MetaTags>
          <title>Админка | Загрузка...</title>
        </MetaTags>

        <Header collection={'category'} />

        <Container>
          <Styles.Loading>
            <p>{id.id ? 'Удаление категорий' : 'Загрузка категорий'}</p>
            <LinearProgress />
          </Styles.Loading>
        </Container>
      </>
    )
  }

  if (!categorys.length) {
    return (
      <>
        <Header collection={'category'} />

        <Container>
          <Styles.Loading>
            <h1>Категорий нет, добавьте категории</h1>
            <Link to={`/admin/category/add`}>
              <Button type={'add'} />
            </Link>
          </Styles.Loading>
        </Container>
      </>
    )
  }

  return (
    <>
      <MetaTags>
        <title>Админка | Категории</title>
      </MetaTags>

      <Header
        idDeleted={idDeleted}
        setIdDeleted={setIdDeleted}
        collection={'category'}
      />

      <Container>
        <Styles.List>
          {categorys.map(category => (
            <Category setId={setId} key={category._id} category={category} />
          ))}
        </Styles.List>
      </Container>
    </>
  )
}
