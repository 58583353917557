import styled from '@emotion/styled'

export const Instagram = styled.section`
  padding: 140px 0 124px 0;
  color: #171819;
  @media (max-width: 768px) {
    padding: 80px 0 80px 0;
  }
  @media (max-width: 768px) {
    padding: 60px 0 60px 0;
  }
`
export const InstagramWrapper = styled.div`
  color: #f1f1f1;
`
export const SupTitle = styled.div`
  color: rgba(23, 24, 25, 0.4);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-bottom: 40px;
  color: rgba(23, 24, 25, 0.4);
  @media (max-width: 480px) {
    margin: 0 0 20px 0;
    font-size: 10px;
  }
`
export const Title = styled.h2`
  line-height: 150%;
  font-weight: 600;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  max-width: 655px;
  margin: 0 0 0 0;
  color: #171819;
  a {
    text-decoration: none;
    line-height: 150%;
    font-weight: 600;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #171819;
  }
  @media (max-width: 768px) {
    margin: 0 0 40px 0;
  }
`
export const TitleBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    margin: 0 0 80px 0;
  }
  @media (max-width: 480px) {
    margin: 0 0 60px 0;
  }
`

export const PhotosContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    justify-content: space-between;
  }
`
export const PhotoWrapper = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;
  width: 354px;
  height: 354px;
  justify-content: center;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 354px;
    height: 354px;
    &:nth-of-type(5n) {
      display: none;
    }
    &:nth-of-type(6n) {
      display: none;
    }
    &:nth-of-type(7n) {
      display: none;
    }
    &:nth-of-type(8n) {
      display: none;
    }
  }
  @media (max-width: 480px) {
    width: 180px;
    height: 180px;
  }
  @media (max-width: 380px) {
    width: 160px;
    height: 160px;
  }
  @media (max-width: 320px) {
    width: 130px;
    height: 130px;
  }
  @media (min-width: 1400px) {
    margin-right: 23px;
    height: 450px;
    width: 450px;
    @media (max-width: 1600px) {
      height: 350px;
      width: 350px;
    }
    &:nth-of-type(2n) {
      align-self: flex-end;
      height: 299px;
      width: 299px;
    }
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    &:nth-of-type(5n) {
      height: 299px;
      width: 299px;
      align-self: flex-start;
    }
    &:nth-of-type(6n) {
      height: 450px;
      width: 450px;
      @media (max-width: 1600px) {
        height: 350px;
        width: 350px;
      }
    }
    &:nth-of-type(7n) {
      height: 299px;
      width: 299px;
      align-self: flex-start;
    }
    &:nth-of-type(8n) {
      height: 450px;
      width: 450px;
      @media (max-width: 1600px) {
        height: 350px;
        width: 350px;
      }
    }
  }
`
export const Photo = styled.img`
  max-width: none;
  max-height: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
`
