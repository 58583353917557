import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './App'
import { store } from './store/store'

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { ruRU } from '@material-ui/core/locale'

const theme = createMuiTheme(
  {
    palette: {
      primary: {
        main: '#3d3d3d',
      },
      secondary: {
        main: '#f25003',
      },
    },
  },
  ruRU
)

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
