import produce, { Draft } from 'immer'
import { SalesActions, SalesActionType } from './contracts/actionTypes'
import { SalesState } from './contracts/state'
import { LoadingStatus } from '../../types'

const initialSalesState: SalesState = {
  items: [],
  loadingState: LoadingStatus.NEVER,
}

export const salesReducer = produce(
  (draft: Draft<SalesState>, action: SalesActions) => {
    switch (action.type) {
      case SalesActionType.SET_SALES:
        draft.items = action.payload
        draft.loadingState = LoadingStatus.LOADED
        break

      case SalesActionType.FETCH_SALES:
        draft.items = []
        draft.loadingState = LoadingStatus.LOADING
        break

      case SalesActionType.SET_LOADING_STATE:
        draft.loadingState = action.payload
        break

      default:
        break
    }
  },
  initialSalesState
)
