import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Header } from "../../../../shared/containers/Header/Header";
import { Button } from "../../../../UIHelpers/Buttons";
import { Container } from "../../../../UIHelpers/Container";
import * as Styles from "./Main.styles";

interface Props {}

export const Main = ({}: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Styles.MainBg>
        <Styles.Main>
          <Container>
            <Styles.MainWrapper>
              <Styles.RouteItems>
                <Styles.RouteItem>
                  <RouterLink to="/">Главная</RouterLink>
                </Styles.RouteItem>
                <Styles.RouteArrow src="/icons/menu/arrow.svg" />
                <Styles.RouteItem>
                  <RouterLink to="/bonus">Бонусная программа</RouterLink>
                </Styles.RouteItem>
              </Styles.RouteItems>
              <Styles.Title>Бонусная программа</Styles.Title>
              <Styles.Info>
                Присоединяйтесь к бонусной программе BRICK GASTRO BAR!
                <br /> С нашей картой лояльности вы сможете накапливать бонусы с
                каждого заказа в баре и экономить при следующих визитах к нам.
                Также для держателей карт лояльности предусмотрены особые акции
                и уникальные скидки.
              </Styles.Info>
              <a href="https://iiko.biz/L/087584">
                <Button
                  typeButton="normal"
                  background="##f25003"
                  color="#F1F1F1"
                  content="Зарегистрироваться"
                  additionalStyles="@media(max-width:768px){width:166px; height:45px;font-size:9px;};@media(max-width:480px){margin-bottom:30px;font-size:8px;width:162px;height:45px;}"
                  hoverBackground="#F1F1F1"
                  hoverColor="#f25003"
                />
              </a>
            </Styles.MainWrapper>
          </Container>
        </Styles.Main>
      </Styles.MainBg>
    </>
  );
};
