import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import * as Styles from './Slider.styles'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Slide } from './Slide'

import { MyLoader } from './Preloader'
import { ISale } from '../../../../../store/ducks/sales/contracts/state'
export interface ISlide {
  title: string
  info: string
  btnText: string
}
interface Props {
  data: ISale[]
  loadingData: boolean
}

export const SliderBlock = ({ data, loadingData }: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: setCurrentIndex,
    dotsClass: 'current-page-dots',
  }
  return (
    <>
      <Styles.SliderWrapper>
        {loadingData ? (
          <MyLoader />
        ) : (
          <>
            <Slider {...settings}>
              {data.map((slide) => (
                <Slide slide={slide} key={slide.description} />
              ))}
            </Slider>
            <Styles.CurrentPageNumber>
              {currentIndex + 1 < 10 ? '0' : ''}
              {currentIndex + 1} / {data.length < 10 ? '0' : ''}
              {data.length}
            </Styles.CurrentPageNumber>
          </>
        )}
      </Styles.SliderWrapper>
    </>
  )
}
