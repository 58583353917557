import React from 'react'

interface Props {}

import * as Styles from './BlogPage.styles'

export const BlogPage: React.FC = ({}: Props) => {
  return (
    <>
      <Styles.Title>Blog</Styles.Title>
    </>
  )
}
