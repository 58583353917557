import { axios } from '../../core/axios'

interface Response<T> {
  status: string
  data: T
}

export const bookingApi = {
  async sendBooking(bookingData: any): Promise<Response<any>> {
    const { data } = await axios.post<Response<any>>('/booking', bookingData)
    return data
  },
}

export const questionApi = {
  async sendBooking(questionData: any): Promise<Response<any>> {
    const { data } = await axios.post<Response<any>>('/question', questionData)
    return data
  },
}
