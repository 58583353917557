import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as Styles from './CardsContainer.styles'

interface Props {
  children: any
  nameFilter: string
}

export const CardsContainer = ({ children, nameFilter }: Props) => {
  const [activeMore, setActiveMore] = useState<boolean>(true)
  const history = useHistory()

  const childrenReact = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return child
    }
  })

  function generateRandomHash(prevHash: string) {
    const hash = Math.round(Math.random() * 10).toString()
    if (prevHash === hash) {
      generateRandomHash(hash)
      return
    }
    return hash
  }

  function checkHeight() {
    const height = !childrenReact[0]?.props?.good?.photo ? 370 : 1130
    return height
  }

  function showMoreHandler() {
    setActiveMore(() => !activeMore)
    if (activeMore === false) {
      const path = window.location.href
      const currentElementId = path.split('#')[1]?.split('-')[1]
      history.push(
        `/menu/#${nameFilter}-${generateRandomHash(currentElementId)}`
      )
    }
  }

  return (
    <>
      {childrenReact.length > 6 && (
        <Styles.ShowMore
          style={
            childrenReact.length > 6 && !activeMore
              ? {
                  color: '#f25003',
                }
              : {}
          }
          onClick={showMoreHandler}
        >
          <svg
            className="show-more"
            style={
              childrenReact.length > 6 && !activeMore
                ? {
                    transform: 'rotate(180deg)',
                    fill: '#f25003',
                  }
                : {}
            }
          >
            <use href="/icons/menu/sprite.svg#show-more"></use>
          </svg>
          {activeMore ? 'Показать больше' : 'Показать меньше'}
        </Styles.ShowMore>
      )}
      <Styles.CardsContainer
        style={
          childrenReact.length > 6 && activeMore
            ? { overflow: 'hidden', maxHeight: `${checkHeight()}px` }
            : {}
        }
      >
        {childrenReact.length ? (
          childrenReact
        ) : (
          <div style={{ marginTop: '-70px' }}>Товары скоро появятся!</div>
        )}
      </Styles.CardsContainer>
    </>
  )
}
