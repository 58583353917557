import React, { useState, useRef, useEffect } from 'react';
import { Preloader } from './Preloader';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Notification } from '../../components/Notification';

import * as Styles from './Auth.styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSignIn } from '../../../../store/ducks/user/actionCreators';
import { selectUserStatus } from '../../../../store/ducks/user/selectors';
import { LoadingStatus } from '../../../../store/types';

export interface LoginFormProps {
  username: string;
  password: string;
}

const authFormSchema = yup.object().shape({
  username: yup.string().required('Логин обязателен'),
  password: yup.string().required('Пароль обязателен'),
});

export const Auth: React.FC = () => {
  const dispatch = useDispatch();
  let loadingStatus = useSelector(selectUserStatus);

  const [empty, setEmpty] = useState({
    username: 'empty',
    password: 'empty',
  });

  const [query, setQuery] = useState({
    username: '',
    password: '',
  });
  const [preloader, setPreloader] = useState(false);

  const openNotificationRef = React.useRef<(text: string, type: any) => void>(() => {});

  const onChange = (e: any): string => {
    if (e.target.value) {
      setEmpty({
        ...empty,
        [e.target.name]: 'no-empty',
      });
      return '';
    }

    setEmpty({
      ...empty,
      [e.target.name]: 'empty',
    });
    return '';
  };

  const { register, handleSubmit, formState } = useForm<LoginFormProps>({
    // resolver: yupResolver(authFormSchema),
  });
  const onSubmit = async (data: LoginFormProps) => {
    try {
      setPreloader(() => true);
      dispatch(fetchSignIn(query));
    } catch (error) {
      setPreloader(() => false);
    }
  };

  useEffect(() => {
    if (preloader && loadingStatus === LoadingStatus.SUCCESS) {
      openNotificationRef.current('Авторизация успешна!', 'success');
      setPreloader(false);
    } else if (preloader && loadingStatus === LoadingStatus.ERROR) {
      openNotificationRef.current('Неверный логин или пароль', 'error');
      setPreloader(false);
    }
  });

  return (
    <Notification>
      {callback => {
        openNotificationRef.current = callback;
        return (
          <Styles.Layout>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Styles.FormLayout>
                <Styles.Title>Вход в админ панель</Styles.Title>
                <Styles.FormInputBlock>
                  <Styles.FormInput
                    {...register('username')}
                    id="username"
                    name="username"
                    type="text"
                    onChange={e => setQuery({ ...query, username: e.target.value })}
                  />
                  <Styles.FormInputLabel className={empty.username} htmlFor={`username`}>
                    Логин
                  </Styles.FormInputLabel>
                  <p>{formState.errors.username?.message}</p>
                </Styles.FormInputBlock>

                <Styles.FormInputBlock>
                  <Styles.FormInput
                    {...register('password')}
                    id="password"
                    name="password"
                    onChange={e => setQuery({ ...query, password: e.target.value })}
                    type="password"
                  />
                  <Styles.FormInputLabel className={empty.password} htmlFor={`password`}>
                    Пароль
                  </Styles.FormInputLabel>
                  <p>{formState.errors.password?.message}</p>
                </Styles.FormInputBlock>

                <Styles.FormButton type="submit">{preloader ? <Preloader /> : 'Войти'}</Styles.FormButton>
              </Styles.FormLayout>
            </form>
          </Styles.Layout>
        );
      }}
    </Notification>
  );
};
