import React, { useState } from 'react';
import InputMask from 'react-input-mask';

interface Props {}

import 'date-fns';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import * as Styles from './QuestionsForm.styles';
import { Container } from '../../../UIHelpers/Container';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button } from '../../../UIHelpers/Buttons';
import { CircularProgress } from '@material-ui/core';
import { questionApi } from '../../../services/api/emailApi';
import { Link } from 'react-router-dom';

interface IQuestionsForm {
  name: string;
  phone: string;
  comment: string;
  social: string;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      color: '#fff !important',
    },
  })
);

export const QuestionForm = () => {
  const classes = useStyles();
  const socials = [
    {
      img: 's-1',
      msg: 'Телефон',
    },
    {
      img: 's-2',
      msg: 'Whatsapp',
    },
    {
      img: 's-3',
      msg: 'Viber',
    },
    {
      img: 's-4',
      msg: 'Telegram',
    },
  ];

  const name: any = document.getElementById('name');
  const comment: any = document.getElementById('comment');

  const [query, setQuery] = useState<IQuestionsForm>({
    name: '',
    phone: '',
    comment: '',
    social: 'Телефон',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<boolean>(false);
  const [checkbox, setCheckbox] = useState<boolean>(true);
  const [errorsQuery, setErrorsQuery] = useState({
    phone: false,
  });

  const onFieldChange = (id: string, value: any): void => {
    setQuery({
      ...query,
      [id]: value,
    });
  };

  const onChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    onFieldChange(event.target.id, event.target.value);
  };

  const bookingFormSchema = yup.object().shape({
    name: yup.string().required('Имя обязательно'),
  });

  const { register, handleSubmit, formState } = useForm<IQuestionsForm>({
    resolver: yupResolver(bookingFormSchema),
  });

  const onSubmit = async (_: IQuestionsForm) => {
    if (
      !query['phone'] ||
      query['phone'].split('').filter(el => el === '_').length != 0
    ) {
      setErrorsQuery(prev => ({
        ...prev,
        phone: true,
      }));
      return;
    } else {
      setErrorsQuery(prev => ({
        ...prev,
        phone: false,
      }));
    }

    if (!checkbox) {
      return;
    }

    setLoading(true);
    const response: any = await questionApi.sendBooking(query);
    setLoading(false);
    setMessage(response.success === true ? true : false);
    setTimeout(() => {
      setMessage(false);
    }, 3000);

    name.value = '';
    comment.value = '';

    setQuery(prev => ({
      ...prev,
      name: '',
      phone: '',
      comment: '',
    }));
  };

  const [active, setActive] = useState(0);

  function setContentBtn() {
    if (loading) {
      return <CircularProgress className={classes.root} />;
    }
    if (message) {
      return <div style={{ color: '#000000' }}>Отправлено!</div>;
    }
    return 'Отправить';
  }

  return (
    <>
      <Styles.QuestionForm id="question">
        <Container>
          <Styles.BookingWrapper>
            <Styles.Part1>
              <Styles.Title>Остались вопросы?</Styles.Title>
              <Styles.PartText>
                Заполните все поля в форме и выберите удобный для вас способ
                связи, наш менеджер свяжется с вами
              </Styles.PartText>
            </Styles.Part1>

            <Styles.Part2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Styles.InputsWrapper>
                  <Styles.InputWrapper query={query['name']}>
                    <Styles.Input
                      {...register('name')}
                      id="name"
                      type="text"
                      name="name"
                      onChange={onChange}
                    />
                    <Styles.Label>Ваше имя</Styles.Label>
                    <Styles.Error>
                      {formState.errors['name']?.message}
                    </Styles.Error>
                  </Styles.InputWrapper>

                  <Styles.InputWrapper query={query['phone']}>
                    <InputMask
                      required
                      id="phone"
                      name="phone"
                      onChange={onChange}
                      type="tel"
                      value={query['phone']}
                      mask="+7\(999)-999-99-99"
                    />
                    <Styles.Label>Введите телефон</Styles.Label>
                    <Styles.Error>
                      {errorsQuery['phone'] && 'Телефон обязателен'}
                    </Styles.Error>
                  </Styles.InputWrapper>

                  <Styles.CommentWrapper
                    style={{ width: '100%' }}
                    query={query['comment']}
                  >
                    <Styles.Input
                      {...register('comment')}
                      id="comment"
                      type="text"
                      name="comment"
                      onChange={onChange}
                    />
                    <Styles.Label>Комментарии и пожелания</Styles.Label>
                    <Styles.Error>
                      {formState.errors['comment']?.message}
                    </Styles.Error>
                  </Styles.CommentWrapper>
                </Styles.InputsWrapper>

                <Styles.Info>
                  <Styles.Socials>
                    <Styles.ErrorSocial>
                      Удобный для вас способ связи:
                    </Styles.ErrorSocial>
                    {socials.map((social, i) => (
                      <Styles.Social
                        style={
                          active === i
                            ? { background: '#F25003', border: 'none' }
                            : { background: 'transparent' }
                        }
                        key={social.msg}
                        onClick={() => {
                          setQuery(prev => ({
                            ...prev,
                            social: social.msg,
                          }));
                          setActive(i);
                        }}
                      >
                        <svg
                          className="icons"
                          style={active === i ? { fill: '#F1F1F1' } : {}}
                        >
                          <use
                            href={`/images/home/booking/sprite.svg#${social.img}`}
                          ></use>
                        </svg>

                        <div
                          className="popup"
                          style={
                            active === i &&
                            document.documentElement.clientWidth < 768
                              ? { opacity: 1 }
                              : { opacity: 0 }
                          }
                        >
                          <Styles.Popup>{social.msg}</Styles.Popup>
                        </div>
                      </Styles.Social>
                    ))}
                  </Styles.Socials>
                  <div>
                    <Button
                      additionalStyles=" margin-left: 83px;@media(max-width:1440px){margin-left:0;}@media(max-width:480px){margin-top:20px;   width: 100% !important;}"
                      type="submit"
                      hoverBackground="#f89577"
                      typeButton="normal"
                      content={setContentBtn()}
                      disabled={loading}
                    />
                    <Styles.Checkbox>
                      <Styles.CheckboxWrapper>
                        <input
                          type="checkbox"
                          name="checkboxG4"
                          id="checkboxG4"
                          onChange={e => setCheckbox(e.target.checked)}
                          checked={checkbox}
                          className="formtocons-form-checkbox"
                        />
                        <label
                          htmlFor="checkboxG4"
                          className="formtocons-form-checkbox-text"
                        >
                          <div>
                            Я прочитал{' '}
                            <Link style={{ color: '#f25003' }} to="/privacy">
                              условия
                            </Link>{' '}
                            и согласен на обработку персональных данных
                          </div>
                        </label>
                      </Styles.CheckboxWrapper>
                      <Styles.Error style={{ height: '10px' }}>
                        {!checkbox &&
                          'Вы не согласились с политикой конфиденциальности'}
                      </Styles.Error>
                    </Styles.Checkbox>
                  </div>
                </Styles.Info>
              </form>
            </Styles.Part2>
          </Styles.BookingWrapper>
        </Container>
      </Styles.QuestionForm>
    </>
  );
};
