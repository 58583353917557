import { call, put, takeLatest } from 'redux-saga/effects'
import { CategorysApi } from '../../../services/api/categorysApi'
import { setCategorys, setCategorysLoadingStatus } from './actionCreators'
import { LoadingStatus } from '../../types'
import { CategorysActionType } from './contracts/actionTypes'

export function* fetchCategorysRequest(): any {
  try {
    const items = yield call(CategorysApi.fetchCategorys)
    yield put(setCategorys(items))
  } catch (error) {
    yield put(setCategorysLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* categorysSaga(): any {
  yield takeLatest(CategorysActionType.FETCH_CATEGORYS, fetchCategorysRequest)
}
