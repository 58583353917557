import styled from '@emotion/styled'
export const Header = styled.header`
  position: relative;
  z-index: 5;
  @media (max-width: 768px) {
    position: fixed;
    z-index: 10000;
    background: url('/images/shared/default-background.webp');
    width: 100%;
  }
`
export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: #171819;
  padding: 30px 0;

  @media (max-width: 1440px) {
    padding: 20px 0;
  }
`
export const BurgerButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
  @media (min-width: 769px) {
    display: none;
  }
`
export const BurgerSocial = styled.div`
  display: flex;
  width: 156px;
  justify-content: space-between;
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 480px) {
    width: 130px;
  }
`
export const LogoWrapper = styled.div`
  max-width: 121px;
  max-height: 56px;
  margin-right: 50px;
  @media (max-width: 1600px) {
    margin-right: 20px;
  }
  @media (max-width: 1300px) {
    margin-right: 20px;
  }
  @media (max-width: 768px) {
    max-width: 95px;
    max-height: 44px;
  }
  @media (max-width: 480px) {
    max-width: 86px;
    max-height: 40px;
  }
`
export const HeaderPart = styled.div`
  display: flex;
  align-items: center;
  &:first-of-type {
    max-width: 816px;
    @media (max-width: 1600px) {
      max-width: 600px;
    }
    @media (max-width: 1300px) {
      max-width: 550px;
    }
  }
  &:last-of-type {
    max-width: 610px;
    @media (max-width: 1300px) {
      max-width: 400px;
    }
  }
`
export const Logo = styled.img`
  max-width: 100%;
  width: 121px;
  height: 56px;
  @media (max-width: 768px) {
    width: 86px;
    height: 40px;
  }
`
export const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 645px;
  font-size: 14px;
  @media (max-width: 1600px) {
    font-size: 12px;
  }
  @media (max-width: 1300px) {
    width: 550px;
    font-size: 11px;
  }
  @media (max-width: 768px) {
    transition: 0.5s;
    top: -100vh;
    padding: 60px 20px;
    box-sizing: border-box;
    left: 0;
    max-width: 100%;
    width: 100%;
    background: #171819;
    z-index: 10000;
    position: fixed;
    flex-direction: column;
    height: 457px;
  }
`
export const NavbarItem = styled.div`
  a {
    color: #171819;
    text-decoration: none;
    @media (min-width: 1024px) {
      transition: 0.1s;
      &:hover {
        color: #f25003;
      }
    }
    @media (max-width: 768px) {
      font-size: 16px;
      color: #f1f1f1;
    }
  }
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.08em;
  line-height: 150%;
  @media (max-width: 768px) {
    justify-content: flex-start;
    height: 55px;
    border-bottom: 1px solid rgba(241, 241, 241, 0.1);
  }
`
export const PhoneNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: normal;
  margin-right: 40px;
  a {
    transition: 0.2s;
    &:hover {
      color: #f25003;
    }
    text-decoration: none;
    color: #171819;
  }
  @media (max-width: 1300px) {
    display: none;
  }
`
