import styled from '@emotion/styled'

export const Slide = styled.div`
  width: 513px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 462px;
  margin-bottom: 100px;
  @media (min-width: 1024px) {
    transition: 0.2s;
    &:hover {
      transform: translateY(-5px);
    }
  }
  @media (max-width: 1600px) {
    width: 460px;
    max-height: 460px;
  }
  @media (max-width: 1200px) {
    width: 320px;
    max-height: 389px;
    margin-bottom: 80px;
  }
  @media (max-width: 768px) {
    width: 354px;
    max-height: 462px;
    margin-bottom: 60px;
  }
  @media (max-width: 480px) {
    width: 150px;
    max-height: none;
    margin-bottom: 40px;
  }
  @media (max-width: 320px) {
    width: 130px;
    max-height: none;
  }
`

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 209px;
  overflow: hidden;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    margin-bottom: 15px;
    height: 120px;
  }
`
export const Img = styled.img`
  object-fit: cover;
  max-width: none;
  max-height: 100%;
  height: 100%;
  width: 100%;
  @media (max-width: 480px) {
    max-height: 120px;
  }
`
export const Date = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: 0.08em;
  color: #171819;
  margin-bottom: 23px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  margin-bottom: 20px;
  @media (max-width: 480px) {
    font-size: 10px;
    margin-bottom: 10px;
  }
`
export const Title = styled.div`
  text-transform: uppercase;
  min-height: 56px;
  max-height: 56px;
  margin-bottom: 15px;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: 0.08em;
  color: #f25003;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
  box-orient: vertical;
  @media (max-width: 768px) {
    min-height: 44px;
    max-height: 44px;
    font-size: 16px;
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    min-height: 48px;
    margin-bottom: 5px;
    font-size: 12px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
`
export const Info = styled.p`
  line-height: 130%;
  margin: 0 0 30px 0;
  min-height: 54px;
  color: #171819;
  font-size: 18px;
  font-weight: 400;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  box-orient: vertical;
  @media (max-width: 1366px) {
    line-height: 150%;
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
  @media (max-width: 768px) {
    line-height: 150%;

    min-height: 63px;
    max-height: 63px;
    font-size: 14px;
    margin-bottom: 20px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
  @media (max-width: 480px) {
    line-height: 150%;

    margin-bottom: 15px;
    font-size: 10px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    min-height: 45px;
  }
`

export const LearnMore = styled.div`
  height: 25px;
  width: fit-content;
  border-bottom: 1px solid #171819;
  line-height: 150%;
  a {
    color: #171819;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }
`
