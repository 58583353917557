import React from 'react'
import ContentLoader from 'react-content-loader'

import * as Styles from './Preloader.styles'
const width = document.documentElement.clientWidth < 768 ? 'mobile' : 'desktop'
export const MyLoader = (props: any) => {
  if (width === 'mobile') {
    return (
      <ContentLoader
        speed={2}
        width={375}
        height={667}
        viewBox="0 0 375 667"
        backgroundColor="#eeeeee"
        foregroundColor="#fafafa"
        {...props}
      >
        <rect x="82" y="33" rx="4" ry="4" width="198" height="22" />
        <rect x="17" y="90" rx="4" ry="4" width="335" height="15" />
        <rect x="49" y="115" rx="4" ry="4" width="278" height="15" />
        <rect x="99" y="140" rx="4" ry="4" width="181" height="13" />
        <rect x="52" y="220" rx="0" ry="0" width="258" height="8" />
        <rect x="39" y="236" rx="0" ry="0" width="289" height="8" />
        <rect x="106" y="253" rx="0" ry="0" width="152" height="8" />
        <rect x="95" y="335" rx="25" ry="25" width="180" height="45" />
        <rect x="23" y="551" rx="0" ry="0" width="57" height="11" />
        <circle cx="336" cy="558" r="7" />
        <circle cx="315" cy="558" r="7" />
      </ContentLoader>
    )
  }

  return (
    <Styles.Wrapper>
      <ContentLoader
        speed={3}
        width={655}
        height={600}
        viewBox="0 0 655 600"
        backgroundColor="#eeeeee"
        foregroundColor="#fafafa"
        {...props}
      >
        <rect x="0" y="60" rx="5" ry="10" width="555" height="100" />
        <rect x="237" y="128" rx="0" ry="0" width="13" height="0" />
        <rect x="0" y="240" rx="5" ry="10" width="335" height="55" />
        <rect x="0" y="350" rx="10" ry="10" width="250" height="56" />
        <rect x="0" y="497" rx="10" ry="10" width="56" height="19" />
        <circle cx="103" cy="645" r="10" />
        <circle cx="131" cy="645" r="10" />
      </ContentLoader>
    </Styles.Wrapper>
  )
}
