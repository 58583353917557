import { axios } from '../../core/axios'
import { IPost } from '../../store/ducks/posts/contracts/state'

interface Response<T> {
  status: string
  data: T
}

export const PostsApi = {
  async fetchPosts(): Promise<IPost[]> {
    const { data } = await axios.get<Response<IPost[]>>('/posts')
    return data.data
  },

  async fetchPostData(id: string): Promise<IPost> {
    const { data } = await axios.get<Response<IPost>>('/posts/' + id)
    return data.data
  },

  async addPost(payload: IPost): Promise<IPost> {
    const { data } = await axios.post<Response<IPost>>('/posts', payload)
    return data.data
  },

  async updatePostData(payload: IPost, id: string): Promise<Response<IPost>> {
    const { data } = await axios.patch('/posts/' + id, payload)
    return data
  },

  async deletePost(id: string): Promise<Response<IPost>> {
    const { data } = await axios.delete('/posts/' + id)
    return data
  },
}
