import { Action } from 'redux'
import { LoadingStatus } from '../../../types'
import { PostsState } from './state'

export enum PostsActionType {
  SET_POSTS = 'posts/SET_POSTS',
  FETCH_POSTS = 'posts/FETCH_POSTS',
  SET_LOADING_STATE = 'posts/SET_LOADING_STATE',
}

export interface ISetPostsAction extends Action<PostsActionType> {
  type: PostsActionType.SET_POSTS
  payload: PostsState['items']
}

export interface IFetchPostsAction extends Action<PostsActionType> {
  type: PostsActionType.FETCH_POSTS
}

export interface ISetPostsLoadingAction extends Action<PostsActionType> {
  type: PostsActionType.SET_LOADING_STATE
  payload: LoadingStatus
}

export type PostsActions =
  | ISetPostsAction
  | ISetPostsLoadingAction
  | IFetchPostsAction
