import React from 'react'
import { Booking } from '../../../shared/containers/Booking'
import { Footer } from '../../../shared/containers/Footer'
import { Map } from '../../../shared/containers/Map'
import { NewsSlider } from '../../../shared/containers/NewsSlider'
import { Contacts } from '../components/Contacts/Contacts'
import { Main } from '../components/Main'
import MetaTags from 'react-meta-tags'

interface Props {}

import * as Styles from './ContactsPage.styles'

export const ContactsPage: React.FC = ({}: Props) => {
  return (
    <>
      <MetaTags>
        <title>BRICK GASTRO BAR | Контакты</title>
      </MetaTags>

      <Styles.Wrapper>
        <Main />
        <Contacts />
        <Map />
        <Booking />
        <NewsSlider />
        <Footer />
      </Styles.Wrapper>
    </>
  )
}
