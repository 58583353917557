import { RootState } from '../../store'
import { IGood } from '../goods/contracts/state'
import { LoadingStatus } from '../../types'
import { GoodState } from './contracts/state'

export const selectGood = (state: RootState): GoodState => state.good

export const selectLoadingStatus = (state: RootState): LoadingStatus =>
  selectGood(state).LoadingStatus

export const selectIsGoodLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING

export const selectIsGoodLoaded = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADED

export const selectIsDeleteGoodLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING

export const selectGoodData = (state: RootState): IGood | undefined => {
  return selectGood(state).data
}
