import React from 'react'

interface Props {}
import * as Styles from './Instagram.styles'
import { Container } from '../../../UIHelpers/Container'
import { Button } from '../../../UIHelpers/Buttons'

export const Instagram = () => {
  return (
    <>
      <Styles.Instagram>
        <Container>
          <Styles.InstagramWrapper>
            <Styles.SupTitle>НАШ VK</Styles.SupTitle>
            <Styles.TitleBlock>
              <Styles.Title>
                <a target="_blank" href="https://www.instagram.com/brick_stp/">
                  Подписывайтесь на нас в VK — BRICK_SPB
                </a>
              </Styles.Title>
              <a href="https://vk.com/brick_spb" target="_blank">
                <Button
                  typeButton="normal"
                  hoverBackground="#f89577"
                  content="Перейти в VK"
                />
              </a>
            </Styles.TitleBlock>
            <Styles.PhotosContainer>
              <Styles.PhotoWrapper>
                <Styles.Photo src="/images/home/instagram1.webp" />
              </Styles.PhotoWrapper>
              <Styles.PhotoWrapper>
                <Styles.Photo src="/images/home/instagram2.webp" />
              </Styles.PhotoWrapper>
              <Styles.PhotoWrapper>
                <Styles.Photo src="/images/home/instagram3.webp" />
              </Styles.PhotoWrapper>
              <Styles.PhotoWrapper>
                <Styles.Photo src="/images/home/instagram4.webp" />
              </Styles.PhotoWrapper>
              <Styles.PhotoWrapper>
                <Styles.Photo src="/images/home/instagram5.webp" />
              </Styles.PhotoWrapper>
              <Styles.PhotoWrapper>
                <Styles.Photo src="/images/home/instagram6.webp" />
              </Styles.PhotoWrapper>
              <Styles.PhotoWrapper>
                <Styles.Photo src="/images/home/instagram7.webp" />
              </Styles.PhotoWrapper>
              <Styles.PhotoWrapper>
                <Styles.Photo src="/images/home/instagram8.webp" />
              </Styles.PhotoWrapper>
            </Styles.PhotosContainer>
          </Styles.InstagramWrapper>
        </Container>
      </Styles.Instagram>
    </>
  )
}
