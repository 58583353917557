import { createSelector } from 'reselect'
import { LoadingStatus } from '../../types'
import { PostsState } from './contracts/state'
import { RootState } from '../../store'

export const selectPosts = (state: RootState): PostsState => state.posts

export const selectPostsItems = createSelector(
  selectPosts,
  posts => posts.items
)

export const selectLoadingStatus = (state: RootState) =>
  selectPosts(state).loadingState

export const selectIsPostsLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING
