import { call, put, takeEvery } from 'redux-saga/effects'
import { SalesApi } from '../../../services/api/salesApi'
import { LoadingStatus } from '../../types'
import { ISale } from '../sales/contracts/state'
import {
  setDeleteSaleData,
  setSaleData,
  setSaleLoadingStatus,
} from './actionCreators'
import {
  AddSaleActionInterface,
  DeleteSaleDataActionInterface,
  FetchSaleDataActionInterface,
  SaleActionsType,
  UpdateSaleDataActionInterface,
} from './contracts/actionTypes'

export function* fetchSaleDataRequest({
  payload: saleId,
}: FetchSaleDataActionInterface): any {
  try {
    const data: ISale = yield call(SalesApi.fetchSaleData, saleId)
    yield put(setSaleData(data))
  } catch (error) {
    yield put(setSaleLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* updateSaleDataRequest({
  id: saleId,
  payload: ISale,
}: UpdateSaleDataActionInterface): any {
  try {
    const data: ISale = yield call(SalesApi.updateSaleData, ISale, saleId)
    yield put(setSaleData(data))
  } catch (error) {
    yield put(setSaleLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* addSaleRequest({ payload: ISale }: AddSaleActionInterface) {
  try {
    const data: ISale = yield call(SalesApi.addSale, ISale)
    yield put(setSaleData(data))
  } catch (error) {
    yield put(setSaleLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* deleteSaleDataRequest({
  payload: saleId,
}: DeleteSaleDataActionInterface) {
  try {
    yield call(SalesApi.deleteSale, saleId)
    yield put(setDeleteSaleData())
  } catch (error) {
    yield put(setSaleLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* saleSaga() {
  yield takeEvery(SaleActionsType.FETCH_SALE_DATA, fetchSaleDataRequest)
  yield takeEvery(SaleActionsType.UPDATE_SALE_DATA, updateSaleDataRequest)
  yield takeEvery(SaleActionsType.ADD_SALE, addSaleRequest)
  yield takeEvery(SaleActionsType.DELETE_SALE_DATA, deleteSaleDataRequest)
}
