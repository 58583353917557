import { Container } from '../components/Container'
import React, { useEffect, useState } from 'react'
import { Auth } from '../pages/Auth'

import * as Styles from './AdminPage.styles'
import { Route, Switch } from 'react-router-dom'
import { PostList } from '../pages/PostList/containers'
import { SaleList } from '../pages/SaleList/containers'
import { GoodList } from '../pages/GoodList/containers'

import { Post as PostAdmin } from '../pages/Post/containers'
import { Good as GoodAdmin } from '../pages/Good/containers'
import { Sale as SaleAdmin } from '../pages/Sale/containers'
import { Category as CategoryAdmin } from '../pages/Category/containers'

import { useDispatch, useSelector } from 'react-redux'
import { selectIsAuth } from '../../../store/ducks/user/selectors'
import { AuthApi } from '../../../services/api/authApi'
import { setUserData } from '../../../store/ducks/user/actionCreators'
import { Header } from '../components/Header/'
import { LinearProgress } from '@material-ui/core'
import { CategoryList } from '../pages/CategoryList/containers'

import Metatags from 'react-meta-tags'
import { ErrorPage } from '../../404/containers'

interface Props {}

export const AdminPage: React.FC<Props> = ({}: Props) => {
  const dispatch = useDispatch()
  const isAuth = useSelector(selectIsAuth)

  const [loading, setLoading] = useState<boolean>(false)

  const checkAuth = async () => {
    try {
      setLoading(true)
      const { data } = await AuthApi.getMe()
      dispatch(setUserData(data))
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    checkAuth()
  }, [])

  if (loading) {
    return (
      <>
        <Header collection="none" />
        <Container>
          <Styles.Loading>
            <p>Проверка авторизации</p>
            <LinearProgress />
          </Styles.Loading>
        </Container>
      </>
    )
  }

  if (!isAuth) {
    return (
      <Container>
        <Auth />
      </Container>
    )
  }

  return (
    <>
      <Metatags>
        <title>BRICK GASTRO BAR | Админка</title>
      </Metatags>

      <Switch>
        <Route exact path="/admin">
          <Header collection={'none'} />
          <Container>
            <Styles.Title>
              Выберите список, который хотите редактировать
            </Styles.Title>
          </Container>
        </Route>

        <Route exact path="/admin/post/list">
          <PostList />
        </Route>

        <Route exact path="/admin/post/edit/:id">
          <PostAdmin type="update" />
        </Route>

        <Route exact path="/admin/post/add">
          <PostAdmin type="add" />
        </Route>

        <Route exact path="/admin/good/list">
          <GoodList />
        </Route>

        <Route exact path="/admin/good/edit/:id">
          <GoodAdmin type="update" />
        </Route>

        <Route exact path="/admin/good/add">
          <GoodAdmin type="add" />
        </Route>

        <Route exact path="/admin/sale/list">
          <SaleList />
        </Route>

        <Route exact path="/admin/sale/edit/:id">
          <SaleAdmin type="update" />
        </Route>

        <Route exact path="/admin/sale/add">
          <SaleAdmin type="add" />
        </Route>

        <Route exact path="/admin/category/list">
          <CategoryList />
        </Route>

        <Route exact path="/admin/category/edit/:id">
          <CategoryAdmin type="update" />
        </Route>

        <Route exact path="/admin/category/add">
          <CategoryAdmin type="add" />
        </Route>

        <Route component={ErrorPage} />
      </Switch>
    </>
  )
}
