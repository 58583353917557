import React from 'react'

import * as Styles from './Button.styles'

interface Props {
  type?: string
  onClick?: any
  typeButton?: any
}

export const Button = ({ type, onClick, typeButton }: Props) => {
  switch (type) {
    case 'delete':
      return (
        <Styles.BtnDelete type={typeButton} onClick={onClick}>
          Удалить
        </Styles.BtnDelete>
      )
    case 'update':
      return (
        <Styles.BtnUpdate type={typeButton} onClick={onClick}>
          Обновить
        </Styles.BtnUpdate>
      )
    case 'add':
      return (
        <Styles.BtnAdd type={typeButton} onClick={onClick}>
          Добавить
        </Styles.BtnAdd>
      )
    case 'create':
      return (
        <Styles.BtnAdd type={typeButton} onClick={onClick}>
          Создать
        </Styles.BtnAdd>
      )
    default:
      return (
        <Styles.BtnDefault type={typeButton} onClick={onClick}>
          Добавить
        </Styles.BtnDefault>
      )
  }
}
