import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { deleteCategoryData } from '../../../../store/ducks/category/actionCreators'
import { deleteGoodData } from '../../../../store/ducks/good/actionCreators'
import { deletePostData } from '../../../../store/ducks/post/actionCreators'
import { deleteSaleData } from '../../../../store/ducks/sale/actionCreators'
import { Button } from '../Buttons'
import { Container } from '../Container'

interface Props {
  collection: string
  idDeleted?: string[]
  setIdDeleted?: any
}

import * as Styles from './Header.styles'

const links = [
  {
    link: '/admin/good/list',
    name: 'Меню',
  },
  {
    link: '/admin/post/list',
    name: 'Посты',
  },
  {
    link: '/admin/sale/list',
    name: 'Акции',
  },
  {
    link: '/admin/category/list',
    name: 'Категории',
  },
]

export const Header: React.FC<Props> = ({
  collection,
  idDeleted = [],
  setIdDeleted,
}: Props) => {
  const dispatch = useDispatch()
  const currentRoute = window.location.pathname

  useEffect(() => {}, [dispatch])

  const deleteHandler = (): void => {
    const confirmed = confirm(`Удалить ${collection}ы?`) || false
    if (confirmed) {
      idDeleted?.forEach((id: string) => {
        switch (collection) {
          case 'post':
            dispatch(deletePostData(id))
            break
          case 'good':
            dispatch(deleteGoodData(id))
            break
          case 'sale':
            dispatch(deleteSaleData(id))
            break
          case 'category':
            dispatch(deleteCategoryData(id))
            break
          default:
            break
        }
      })
      setIdDeleted([])
    }
  }

  const logoutHandler = (): void => {
    window.localStorage.setItem('token', '')
  }

  return (
    <Styles.Header>
      <Container>
        <Styles.HeaderWrapper>
          <Styles.HeaderLinks>
            {links.map(link => {
              const active = currentRoute === link.link ? true : false
              return (
                <Styles.HeaderLink key={link.name} active={active}>
                  <Link to={link.link}>{link.name}</Link>
                </Styles.HeaderLink>
              )
            })}
          </Styles.HeaderLinks>
          <Styles.ButtonWrapper>
            {!!idDeleted?.length && (
              <Button onClick={deleteHandler} type={'delete'} />
            )}
            {collection !== 'none' ? (
              <Link to={`/admin/${collection}/add`}>
                <Button type={'add'} />
              </Link>
            ) : (
              ''
            )}
            <a href={`/admin`}>
              <Styles.Logout onClick={logoutHandler}>
                <img src="/icons/admin/logout.svg" alt="logout" />
              </Styles.Logout>
            </a>
          </Styles.ButtonWrapper>
        </Styles.HeaderWrapper>
      </Container>
    </Styles.Header>
  )
}
