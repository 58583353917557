import styled from '@emotion/styled'

export const CurrentPageNumber = styled.div`
  position: absolute;
  bottom: 80px;
  left: 170px;
  font-size: 16px;
  line-height: 150%;
  @media (max-width: 1440px) {
    bottom: 20px;
    left: 20px;
  }
`

export const SliderWrapper = styled.div`
  position: relative;
  .slick-track {
    height: auto !important;
    background: none !important;
  }
  .slick-slide {
    height: 850px;
    @media (max-width: 1440px) {
      height: 730px;
    }
    @media (max-width: 768px) {
      height: 940px;
    }
    @media (max-width: 480px) {
      height: 587px;
    }
  }
  .current-page-dots {
    margin: 0 auto;
    max-width: 1580px;
    max-height: 20px;
    left: 282px;
    bottom: 82px;
    position: absolute;
    z-index: 500;
    list-style: none;
    display: flex !important;
    align-items: center;
    margin: 0;
    padding: 0;
    @media (max-width: 1440px) {
      left: 132px;
      bottom: 22px;
    }
    @media (max-width: 768px) {
      left: auto;
      right: 20px;
    }

    li {
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-right: 9px;
      width: 18px;
      height: 18px;
      display: flex;
      &:last-of-type {
        margin-right: 0px;
      }
    }
    button {
      border-radius: 50% !important;
      background: transparent;
      border-radius: 50%;
      border: 2px solid #f1f1f1;
      width: 12px;
      height: 12px;
      padding: 0;
      font-size: 0;
      cursor: pointer;
      outline: none;
    }
    .slick-active {
      button {
        position: relative;
        border: 1px solid #f1f1f1;
        width: 18px;
        height: 18px;
        min-width: 18px;
        min-height: 18px;
        &:before {
          position: absolute;
          top: calc(50% - 5px);
          left: calc(50% - 5px);
          background: #f25003;
          border-radius: 50%;
          content: '';
          width: 10px;
          height: 10px;
        }
      }
    }
  }
`
