import React from 'react'

import * as Styles from './App.styles'

export const App: React.FC = () => {
  return (
    <>
      <Styles.Global />
    </>
  )
}
