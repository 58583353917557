import styled from '@emotion/styled'
export const GeneralNewsSlider = styled.section`
  padding: 140px 0 280px 0;
  @media (max-width: 768px) {
    padding: 80px 0 181px 0;
  }
`
export const ProgressBar = styled.progress`
  position: absolute;
  right: 10px;
  bottom: -105px;
  width: 1360px;
  height: 4px;
  transition: 0.4s;
  ::-webkit-progress-bar {
    transition: 0.4s;
    background: rgba(23, 24, 25, 0.2);
  }
  ::-webkit-progress-value {
    transition: 0.4s;
    background: #171819;
  }
  background: #e5e5e5;
  @media (max-width: 1600px) {
    width: 1200px;
    right: 28px;
  }
  @media (max-width: 1200px) {
    width: 800px;
    bottom: -50px;
  }
  @media (max-width: 768px) {
    width: 584px;
  }
  @media (max-width: 480px) {
    width: 150px;
    right: calc(50% - 75px);
  }
`

export const SliderWrapper = styled.div`
  position: relative;
  .slick-prev,
  .slick-next,
  .slick-arrow {
    display: block !important;
    top: auto;
    bottom: -163px;
    &:before {
      display: none;
    }
    @media (max-width: 1200px) {
      bottom: -90px;
    }
  }
  .slick-prev,
  .slick-next {
    width: 60px;
    height: 60px;
    @media (max-width: 1200px) {
      width: 42px;
      height: 42px;
    }
  }
  .slick-prev {
    left: 0;
    background: url('/icons/slider/left.svg') center no-repeat;
    background-size: cover;
    @media (min-width: 1024px) {
      &:hover {
        background: url('/icons/slider/left-active.svg') center no-repeat;
        background-size: cover;
      }
    }
    @media (max-width: 768px) {
      left: 4px;
    }
  }
  .slick-next {
    left: 100px;
    background: url('/icons/slider/right.svg') center no-repeat;
    background-size: cover;
    @media (min-width: 1024px) {
      &:hover {
        background: url('/icons/slider/right-active.svg') center no-repeat;
        background-size: cover;
      }
    }
    @media (max-width: 768px) {
      left: 60px;
    }
    @media (max-width: 480px) {
      left: unset;
      right: 4px;
    }
  }
`
