import styled from '@emotion/styled';

export const Good = styled.div`
  margin-bottom: 30px;
  background: #fff;
  box-shadow: rgba(208, 208, 208, 0.5) 4px 4px 10px;
  padding: 30px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 5px;
  a {
    text-decoration: none;
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CreatedAt = styled.div`
  font-size: 14px;
  color: #bdbdbd;
`;

export const UpdatedAt = styled.div``;

export const Price = styled.div`
  font-size: 14px;
  color: #00c853;
`;

export const BottomWrap = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const Delete = styled.label`
  margin-right: 5px;
  font-size: 10px;
  user-select: none;
  cursor: pointer;
`;

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
  input[type='checkbox'] {
    background: red;
    order: 1;
  }
  input[type='checkbox']:checked ~ label {
    color: tomato;
    cursor: pointer;
  }
`;

export const Input = styled.input``;

export const Title = styled.h2`
  cursor: pointer;
  margin: 0;
  font-weight: 500;
  color: #424242;
  font-size: 18px;
`;

export const Img = styled.img`
  max-width: 70px;
  height: 55px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const WrapperRow = styled.div`
  display: flex;
`;
