import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const Main = styled.section`
  overflow: hidden;
  padding: 0;
  .slick-slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  @media (max-width: 768px) {
    padding-top: 90px;
  }
  @media (max-width: 480px) {
    padding-top: 80px;
  }
`
export const MainScreenBg = styled.div<{
  background: string
}>`
  background: #333;
  ${({ background }) => {
    return css`
      background-image: url(${background});
    `
  }}
  height: 1000px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1440px) {
    height: 900px;
  }
  @media (max-width: 768px) {
    height: 1024px;
  }
  @media (max-width: 480px) {
    height: 670px;
  }
`

export const MainWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: #f1f1f1;
  .slick-slider {
    position: unset;
  }
`

export const SuptitleWrapper = styled.div`
  .container {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export const SocialMedia = styled.div`
  position: absolute;
  bottom: 70px;
  right: 170px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1440px) {
    right: 20px;
    bottom: 25px;
  }
  a {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`
