import { call, put, takeEvery } from 'redux-saga/effects'
import { CategorysApi } from '../../../services/api/categorysApi'
import { LoadingStatus } from '../../types'
import { ICategory } from '../categorys/contracts/state'
import {
  setDeleteCategoryData,
  setCategoryData,
  setCategoryLoadingStatus,
} from './actionCreators'
import {
  AddCategoryActionInterface,
  DeleteCategoryDataActionInterface,
  FetchCategoryDataActionInterface,
  CategoryActionsType,
  UpdateCategoryDataActionInterface,
} from './contracts/actionTypes'

export function* fetchCategoryDataRequest({
  payload: categoryId,
}: FetchCategoryDataActionInterface): any {
  try {
    const data: ICategory = yield call(
      CategorysApi.fetchCategoryData,
      categoryId
    )
    yield put(setCategoryData(data))
  } catch (error) {
    yield put(setCategoryLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* updateCategoryDataRequest({
  id: categoryId,
  payload: ICategory,
}: UpdateCategoryDataActionInterface) {
  try {
    const data: ICategory = yield call(
      CategorysApi.updateCategoryData,
      ICategory,
      categoryId
    )
    yield put(setCategoryData(data))
  } catch (error) {
    yield put(setCategoryLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* addCategoryRequest({
  payload: ICategory,
}: AddCategoryActionInterface) {
  try {
    const data: ICategory = yield call(CategorysApi.addCategory, ICategory)
    yield put(setCategoryData(data))
  } catch (error) {
    yield put(setCategoryLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* deleteCategoryDataRequest({
  payload: categoryId,
}: DeleteCategoryDataActionInterface) {
  try {
    yield call(CategorysApi.deleteCategory, categoryId)
    yield put(setDeleteCategoryData())
  } catch (error) {
    yield put(setCategoryLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* categorySaga() {
  yield takeEvery(
    CategoryActionsType.FETCH_CATEGORY_DATA,
    fetchCategoryDataRequest
  )
  yield takeEvery(
    CategoryActionsType.UPDATE_CATEGORY_DATA,
    updateCategoryDataRequest
  )
  yield takeEvery(CategoryActionsType.ADD_CATEGORY, addCategoryRequest)
  yield takeEvery(
    CategoryActionsType.DELETE_CATEGORY_DATA,
    deleteCategoryDataRequest
  )
}
