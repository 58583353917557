import styled from '@emotion/styled';

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
`;

export const Filter = styled.div`
  margin-top: 120px;
  margin-right: 50px;
  width: 330px;
  background: #343c59;
  border-radius: 20px;
  font-family: Inter;
  color: #fff;
  padding: 24px;
  div {
    margin-bottom: 20px;
  }
  p {
    margin-left: 5px;
    margin: 0;
    margin-bottom: 3px;
  }
  h3 {
    font-weight: 500;
    margin: 0;
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 0.7);
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Loading = styled.div`
  margin-top: 100px;
  h1 {
    font-weight: 500;
    color: #42a5f5;
  }
`;

export const Select = styled.div`
  margin-top: 100px;
  margin-bottom: 50px;
`;

export const SelectBlock = styled.div`
  margin-bottom: 28px;
  p {
    margin-top: 8px;
    font-size: 12px;
    color: tomato;
  }
  width: 100%;
  .basic-multi-select {
    margin-top: 10px;
    .select__control {
      border: none;
      min-height: 60px;
      box-shadow: 4px 4px 10px rgb(208 208 208 / 50%);
      @media (max-width: 768px) {
        min-height: 32px;
      }
    }
    .select__menu {
      z-index: 1000 !important;
    }
    .select__placeholder {
      color: #bcc2c7;
      font-size: 16px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .select__single-value {
      font-size: 16px;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .select__indicator-separator {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`;

export const Label = styled.label``;

export const HeaderGood = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    button {
      margin-left: 10px;
    }
  }
`;

export const Title = styled.h1`
  color: #42a5f5;
  margin: 0;
  font-weight: 500;
  margin-bottom: 40px;
`;
