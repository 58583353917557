import { IGood } from '../goods/contracts/state'
import { LoadingStatus } from '../../types'

import {
  FetchGoodDataActionInterface,
  SetGoodDataActionInterface,
  SetGoodLoadingStatusActionInterface,
  GoodActionsType,
  UpdateGoodDataActionInterface,
  AddGoodActionInterface,
  DeleteGoodDataActionInterface,
  SetDeleteGoodDataActionInterface,
} from './contracts/actionTypes'
import { GoodState } from './contracts/state'

export const setGoodData = (
  payload: GoodState['data']
): SetGoodDataActionInterface => ({
  type: GoodActionsType.SET_GOOD_DATA,
  payload,
})

export const setGoodLoadingStatus = (
  payload: LoadingStatus
): SetGoodLoadingStatusActionInterface => ({
  type: GoodActionsType.SET_LOADING_STATE,
  payload,
})

export const fetchGoodData = (
  payload: string
): FetchGoodDataActionInterface => ({
  type: GoodActionsType.FETCH_GOOD_DATA,
  payload,
})

export const addGood = (payload: IGood): AddGoodActionInterface => ({
  type: GoodActionsType.ADD_GOOD,
  payload,
})

export const deleteGoodData = (
  payload: string
): DeleteGoodDataActionInterface => ({
  type: GoodActionsType.DELETE_GOOD_DATA,
  payload,
})

export const setDeleteGoodData = (): SetDeleteGoodDataActionInterface => ({
  type: GoodActionsType.SET_LOADING_STATE,
})

export const updateGoodData = (
  payload: IGood,
  id: string
): UpdateGoodDataActionInterface => ({
  type: GoodActionsType.UPDATE_GOOD_DATA,
  payload,
  id,
})

export type GoodActions =
  | SetGoodDataActionInterface
  | FetchGoodDataActionInterface
  | SetGoodLoadingStatusActionInterface
  | UpdateGoodDataActionInterface
  | DeleteGoodDataActionInterface
  | AddGoodActionInterface
