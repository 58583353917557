import React, { useEffect, useState } from "react";

import * as Styles from "./Header.styles";
import { Container } from "../../../UIHelpers/Container";
import { Button } from "../../../UIHelpers/Buttons";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

export const links = [
  {
    name: "МЕНЮ",
    link: "/menu",
  },
  {
    name: "КАРТА БАРА",
    link: "/menu#beer",
  },
  {
    name: "БОНУСНАЯ ПРОГРАММА",
    link: "/bonus",
  },
  {
    name: "НОВОСТИ",
    link: "/news",
  },
  {
    name: "КОНТАКТЫ",
    link: "/contacts",
  },
];

interface Props {
  isErrorPage?: boolean;
}

export const Header = ({ isErrorPage }: Props) => {
  const [navbar, setNavbar] = useState<boolean>(false);

  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  const navbarHandler = () => {
    setNavbar(!navbar);
    document.body.style.overflow = navbar ? "auto" : "hidden";
  };

  return (
    <>
      <Styles.Header>
        <Container>
          <Styles.HeaderWrapper>
            <Styles.HeaderPart>
              <Styles.LogoWrapper>
                <Link to="/">
                  <Styles.Logo src="/icons/shared/logo-black.svg"></Styles.Logo>
                </Link>
              </Styles.LogoWrapper>
              <Styles.Navbar
                style={navbar ? { top: "80px" } : { top: "-100vh" }}
              >
                {links.map((link) => (
                  <Styles.NavbarItem
                    key={link.name}
                    onClick={() => (document.body.style.overflow = "auto")}
                  >
                    <Link
                      style={
                        !link.link
                          ? { color: "#757575", cursor: "default" }
                          : {}
                      }
                      to={link.link}
                    >
                      {link.name}
                    </Link>
                  </Styles.NavbarItem>
                ))}
                <Styles.BurgerButtons>
                  <Styles.BurgerSocial>
                    <a href="tel:+7(964)-322-48-49">
                      <Button
                        typeButton="rounded"
                        background="#F25003 url('/icons/shared/instagram-logo-white.svg') center no-repeat"
                        additionalStyles="(min-width:769px){display:none;};"
                        backgroundSizeMobile="20px 18px"
                      ></Button>
                    </a>
                    <Button
                      typeButton="rounded"
                      background="#F25003 url('/icons/shared/vk-logo-white.svg') center no-repeat"
                      additionalStyles="(min-width:769px){display:none;};"
                      backgroundSizeMobile="16px 8px"
                    ></Button>
                    <Button
                      typeButton="rounded"
                      background="#F25003 url('/icons/shared/facebook-logo-white.svg') center no-repeat"
                      additionalStyles="(min-width:769px){display:none;};"
                      backgroundSizeMobile="16px 16px"
                    ></Button>
                  </Styles.BurgerSocial>
                  <ScrollLink to="booking">
                    <Button
                      onClick={() => {
                        navbarHandler();
                        document.body.style.overflow = "auto";
                      }}
                      typeButton="normal"
                      background="#F25003"
                      color="#f1f1f1"
                      additionalStyles="@media(min-width:769px){display:none;};  @media (max-width: 480px) {
                        width:141px;
                        height:40px;
                      };"
                      hoverBackground="#f89577"
                      content="ЗАБРОНИРОВАТЬ СТОЛИК"
                    ></Button>
                  </ScrollLink>
                </Styles.BurgerButtons>
              </Styles.Navbar>
            </Styles.HeaderPart>

            <Styles.HeaderPart>
              <Styles.PhoneNumber>
                <a href="tel:+7(964)-322-48-49">+7 (964) 322-48-49</a>
              </Styles.PhoneNumber>
              <a href="tel:+7(964)-322-48-49">
                <Button
                  typeButton="rounded"
                  background="transparent url('/icons/shared/phone-orange.svg') center no-repeat"
                  additionalStyles="background-size:16px 16px; margin-right: 20px;border: 2px solid #F25003;"
                  backgroundSize="16px 16px"
                  hoverBackground="#F25003 url('/icons/shared/phone-white.svg') center no-repeat"
                  backgroundSizeMobile="12.5px 12.5px"
                ></Button>
              </a>
              <ScrollLink to="map">
                <Button
                  typeButton="rounded"
                  background="transparent url('/icons/shared/map-mark-orange.svg') center no-repeat"
                  img="/icons/shared/map-mark-orange.svg"
                  additionalStyles="margin-right: 20px;border: 2px solid #F25003;"
                  hoverBackground="#F25003 url('/icons/shared/map-mark-white.svg') center no-repeat"
                  backgroundSize="20px 20px"
                  backgroundSizeMobile="16px 16px"
                ></Button>
              </ScrollLink>
              {isErrorPage ? (
                <Link to="/#booking">
                  <Button
                    typeButton="normal"
                    type="submit"
                    background="transparent"
                    color="#171819"
                    additionalStyles="line-height: 150%; letter-spacing: 0.08em;border: 2px solid #171819; font-weight: 700;@media(max-width:768px){display:none;};"
                    content="ЗАБРОНИРОВАТЬ СТОЛИК"
                    hoverColor="#f1f1f1"
                    hoverBackground="#171819"
                  ></Button>
                </Link>
              ) : (
                <ScrollLink
                  to="booking"
                  onClick={() => {
                    navbarHandler();
                    document.body.style.overflow = "auto";
                  }}
                >
                  <Button
                    typeButton="normal"
                    type="submit"
                    background="transparent"
                    color="#171819"
                    additionalStyles="line-height: 150%; letter-spacing: 0.08em;border: 2px solid #171819; font-weight: 700;@media(max-width:768px){display:none;};"
                    content="ЗАБРОНИРОВАТЬ СТОЛИК"
                    hoverColor="#f1f1f1"
                    hoverBackground="#171819"
                  ></Button>
                </ScrollLink>
              )}
              <Button
                additionalStyles={
                  navbar
                    ? "span:after{ margin:0; transform: translateY(5px); transform: rotate(135deg);};span:before{margin:0; transform: translateY(-5px); transform: rotate(-135deg);};span{height: 0; background:transparent;}"
                    : ""
                }
                typeButton="burger"
                onClick={() => {
                  document.body.style.overflow = navbar ? "auto" : "hidden";
                  navbarHandler();
                }}
                background="#F1F1F1"
              ></Button>
            </Styles.HeaderPart>
          </Styles.HeaderWrapper>
        </Container>
      </Styles.Header>
    </>
  );
};
