import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  addPost,
  deletePostData,
  fetchPostData,
  setPostData,
  updatePostData,
} from '../../../../../store/ducks/post/actionCreators';
import {
  selectIsPostLoading,
  selectPostData,
} from '../../../../../store/ducks/post/selectors';
import { IPost } from '../../../../../store/ducks/posts/contracts/state';
import { Container } from '../../../components/Container';
import { Header } from '../../../components/Header';
import { Input } from '../../../components/Inputs/';
import LinearProgress from '@material-ui/core/LinearProgress';

import * as Styles from './Post.styles';
import { Button } from '../../../components/Buttons';

import MetaTags from 'react-meta-tags';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Select from 'react-select';
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@emotion/react';
import { uploadImage } from '../../../../../utils/uploadImage';
import { getMediaLink } from '../../../../../utils/mediaHelper';
interface Props {
  type: string;
}

interface IPostForm {
  title: string;
  title_preview: string;
  title_photo: string;
  post_content: string;
  description_meta: string;
  keywords_meta: string;
}

const types = [
  {
    value: 'Акция',
    label: 'Акция',
  },
  {
    value: 'Мероприятие',
    label: 'Мероприятие',
  },
  {
    value: 'Новость',
    label: 'Новость',
  },
];

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      color: '#00c853 !important',
      position: 'absolute',
      top: '60%',
    },
  })
);

export const Post: React.FC<Props> = ({ type }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const postData = useSelector(selectPostData);
  const isLoading = useSelector(selectIsPostLoading);
  const history = useHistory();

  const params: { id?: string } = useParams();
  const id = params.id;

  const [preloader, setPreloader] = useState(false);
  const [errorQuill, setErrorQuill] = useState(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [query, setQuery] = useState<IPost>({
    _id: '',
    title: '',
    title_photo: '',
    title_preview: '',
    post_content: '',
    description_meta: '',
    keywords_meta: '',
    type: {
      label: 'Новость',
      value: 'Новость',
    },
  });

  const postFormSchema = yup.object().shape({
    title: yup.string().required('Заголовок обязателен'),
    title_photo: yup
      .mixed()
      .required('Фото обязательно')
      .test('fileFormat', 'Фото обязательно', value => {
        if (src) {
          return true;
        }

        return (
          value &&
          ['image/jpeg', 'image/png', 'image/webp'].includes(value[0]?.type)
        );
      }),
  });

  const { register, handleSubmit, formState } = useForm<IPostForm>({
    resolver: yupResolver(postFormSchema),
  });

  const updateHandler = useCallback(() => {
    if (!query.title_photo) return;
    if (query?._id) {
      dispatch(updatePostData(query, query?._id));
      setUpdate(!update);
    }
  }, [query]);

  const deleteHandler = useCallback(() => {
    const confirmed = confirm('Удалить пост?');
    if (confirmed) {
      dispatch(deletePostData(postData?._id || 'error'));
      setQuery((prev: IPost) => ({
        ...prev,
        title: '',
        title_photo: '',
        post_content: '',
        description_meta: '',
        keywords_meta: '',
        type: {
          label: '',
          value: '',
        },
      }));

      setDeleted(!deleted);
    }
  }, [postData]);

  const addHandler = useCallback(() => {
    if (!query.title_photo) return;
    dispatch(addPost(query));
    setAdd(!add);
  }, [query]);

  useEffect(() => {
    if (id) {
      dispatch(fetchPostData(id));
    }
    return () => {
      dispatch(setPostData(undefined));
    };
  }, [dispatch, id, update]);

  useEffect(() => {
    setQuery((prev: IPost) => ({
      ...prev,
      ...postData,
    }));
  }, [postData]);

  useEffect(() => {
    if (type === 'add' && postData?._id && add) {
      history.push(`/admin/post/edit/${postData?._id}`);
    }

    if (type === 'add') {
      setQuery(() => ({
        title: '',
        title_photo: '',
        post_content: '',
        title_preview: '',
        description_meta: '',
        keywords_meta: '',
        type: {
          label: 'Новость',
          value: 'Новость',
        },
      }));
    }
  }, [add, postData]);

  useEffect(() => {
    if (type === 'update' && deleted) {
      history.push(`/admin/post/list`);
    }
  }, [deleted]);

  const onFieldChange = (id: string, value: any): void => {
    setQuery({
      ...query,
      [id]: value,
    });
  };

  const readImage = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const input = e.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file) {
      setPreloader(true);
      const { name } = await uploadImage(file);
      setPreloader(false);

      setQuery((prev: IPost) => ({
        ...prev,
        title_photo: name,
      }));
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    onFieldChange(e.target.id, e.target.value);
  };

  const src = query['title_photo'];

  const onSubmit = async (_: IPostForm) => {
    if (!query['post_content'] || query['post_content'] === '<p><br></p>') {
      setErrorQuill(true);
      return;
    }
    type === 'update' ? updateHandler() : addHandler();
  };

  if (isLoading) {
    return (
      <>
        <MetaTags>
          <title>Админка | Загрузка...</title>
        </MetaTags>

        <Header collection={'post'} />

        <Container>
          <Styles.Loading>
            <p>{type === 'update' ? 'Обновление поста' : 'Добавление поста'}</p>
            <LinearProgress />
          </Styles.Loading>
        </Container>
      </>
    );
  }

  return (
    <>
      <MetaTags>
        <title>Админка | {postData?.title || 'Добавление поста'}</title>
      </MetaTags>

      <Header collection={'post'} />

      <Container>
        <Styles.Post onSubmit={handleSubmit(onSubmit)}>
          <Styles.HeaderPost>
            <Styles.Title>
              {type === 'add' ? 'Добавление поста' : 'Обновление поста'}
            </Styles.Title>
            <div>
              {type === 'update' && (
                <Button onClick={deleteHandler} type={'delete'} />
              )}
              <Button
                typeButton={'submit'}
                type={type === 'add' ? 'create' : 'update'}
              />
            </div>
          </Styles.HeaderPost>

          <Styles.SelectBlock>
            <Styles.Label>Тип поста</Styles.Label>
            <Select
              name="type"
              onChange={(e: any) => {
                setQuery((prev: IPost) => ({
                  ...prev,
                  type: e,
                }));
              }}
              value={query['type']}
              placeholder="Выберите тип..."
              options={types}
              className="basic-multi-select"
              classNamePrefix="select"
            />
            <p>{!query['type'] ? 'Тип обязателен' : ''}</p>
          </Styles.SelectBlock>

          <Input
            label="Заголовок поста"
            placeholder="Введите заголовок"
            id="title"
            nameProps="title"
            refProp={register}
            errors={formState.errors}
            type="text"
            onChange={onChange}
            value={query['title']}
          />

          <Input
            label="Заголовок поста на превью"
            placeholder="Введите заголовок поста на превью"
            id="title_preview"
            nameProps="title_preview"
            refProp={register}
            errors={formState.errors}
            type="text"
            onChange={onChange}
            value={query['title_preview']}
          />

          <Styles.InputFileWrapper>
            <Input
              label="Картинка превью поста"
              placeholder="Выберите картинку"
              id="title_photo"
              nameProps="title_photo"
              refProp={register}
              errors={formState.errors}
              onChange={readImage}
              type="file"
            />
            {query.title_photo && query.title_photo !== '' && !preloader ? (
              <img src={getMediaLink(query.title_photo)} alt="src" />
            ) : preloader ? (
              <CircularProgress className={classes.root} />
            ) : (
              ''
            )}
          </Styles.InputFileWrapper>

          <Styles.ReactQuillWrapper>
            <Styles.ReactQuillStyled
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [
                    { list: 'ordered' },
                    { list: 'bullet' },
                    { indent: '-1' },
                    { indent: '+1' },
                  ],
                  ['link', 'image', 'video'],
                  ['clean'],
                ],
              }}
              formats={[
                'header',
                'bold',
                'italic',
                'underline',
                'strike',
                'blockquote',
                'list',
                'bullet',
                'indent',
                'link',
                'image',
                'video',
              ]}
              value={query['post_content']}
              onChange={(value: any) => {
                query['post_content'] = value;
                if (
                  query['post_content'] &&
                  query['post_content'] !== '<p><br></p>'
                ) {
                  setErrorQuill(() => false);
                } else {
                  setErrorQuill(() => true);
                }
                setQuery(prev => ({ ...prev }));
              }}
            />
            <p className="error">
              {errorQuill ? 'Описание поста обязательно' : ''}
            </p>
          </Styles.ReactQuillWrapper>

          <Input
            label="Meta keywords"
            placeholder="Введите meta keywords"
            id="keywords_meta"
            type="text"
            value={query['keywords_meta']}
            onChange={onChange}
          />

          <Input
            label="Meta description"
            placeholder="Введите meta description"
            id="description_meta"
            type="text"
            value={query['description_meta']}
            onChange={onChange}
          />
          <Button
            typeButton={'submit'}
            type={type === 'add' ? 'create' : 'update'}
          />
        </Styles.Post>
      </Container>
    </>
  );
};
