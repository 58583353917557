import produce, { Draft } from 'immer'
import { CategorysActions, CategorysActionType } from './contracts/actionTypes'
import { LoadingStatus } from '../../types'
import { CategorysState } from './contracts/state'

const initialCategorysState: CategorysState = {
  items: [],
  loadingState: LoadingStatus.NEVER,
}

export const categorysReducer = produce(
  (draft: Draft<CategorysState>, action: CategorysActions) => {
    switch (action.type) {
      case CategorysActionType.SET_CATEGORYS:
        draft.items = action.payload
        draft.loadingState = LoadingStatus.LOADED
        break

      case CategorysActionType.FETCH_CATEGORYS:
        draft.items = []
        draft.loadingState = LoadingStatus.LOADING
        break

      case CategorysActionType.SET_LOADING_STATE:
        draft.loadingState = action.payload
        break

      default:
        break
    }
  },
  initialCategorysState
)
