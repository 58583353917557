import { Action } from 'redux'
import { LoadingStatus } from '../../../types'
import { IPost } from '../../posts/contracts/state'
import { PostState } from './state'

export enum PostActionsType {
  SET_POST_DATA = 'post/SET_POST_DATA',
  FETCH_POST_DATA = 'post/FETCH_POST_DATA',
  UPDATE_POST_DATA = 'post/UPDATE_POST_DATA',
  DELETE_POST_DATA = 'post/DELETE_POST_DATA',
  ADD_POST = 'post/ADD_POST',
  SET_LOADING_STATE = 'post/SET_LOADING_STATE',
}

export interface SetPostDataActionInterface extends Action<PostActionsType> {
  type: PostActionsType.SET_POST_DATA
  payload: PostState['data']
}

export interface FetchPostDataActionInterface extends Action<PostActionsType> {
  type: PostActionsType.FETCH_POST_DATA
  payload: string
}

export interface UpdatePostDataActionInterface extends Action<PostActionsType> {
  type: PostActionsType.UPDATE_POST_DATA
  payload: IPost
  id: string
}

export interface AddPostActionInterface extends Action<PostActionsType> {
  type: PostActionsType.ADD_POST
  payload: IPost
}

export interface DeletePostDataActionInterface extends Action<PostActionsType> {
  type: PostActionsType.DELETE_POST_DATA
  payload: string
}

export interface SetDeletePostDataActionInterface
  extends Action<PostActionsType> {
  type: PostActionsType.SET_LOADING_STATE
}

export interface SetPostLoadingStatusActionInterface
  extends Action<PostActionsType> {
  type: PostActionsType.SET_LOADING_STATE
  payload: LoadingStatus
}
