import React from 'react'
import { Booking } from '../../../shared/containers/Booking'
import { Contacts } from '../../../shared/containers/Contacts'
import { Footer } from '../../../shared/containers/Footer/Footer'
import { Instagram } from '../../../shared/containers/Instagram'
import { Map } from '../../../shared/containers/Map'
import { GeneralNewsSlider } from '../components/GeneralNewsSlider'
import { Main } from '../components/Main'
import { NewsSection } from '../components/NewsSection'
import MetaTags from 'react-meta-tags'

interface Props {}

import * as Styles from './NewsPage.styles'

export const NewsPage: React.FC = ({}: Props) => {
  return (
    <>
      <MetaTags>
        <title>BRICK GASTRO BAR | Новости</title>
      </MetaTags>

      <Styles.Wrapper>
        <Main />
        <GeneralNewsSlider />
        <NewsSection />
        <Booking />
        <Instagram />
        <Contacts />
        <Map />
        <Footer />
      </Styles.Wrapper>
    </>
  )
}
