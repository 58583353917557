import React from 'react';
import * as Styles from './Slide.styles';
import { IPost } from '../../../../../store/ducks/posts/contracts/state';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import { getMediaLink } from '../../../../../utils/mediaHelper';
interface Props {
  slide: IPost;
}

export const Slide = ({ slide }: Props) => {
  const { title, title_photo, title_preview, createdAt, _id } = slide;
  const dateToFormat = new Date(createdAt || '');
  return (
    <>
      <Styles.Slide>
        <Styles.SlideText>
          <Styles.Date>
            <Moment format="DD.MM.YYYY" date={dateToFormat} />
          </Styles.Date>
          <Link style={{ textDecoration: 'none' }} to={`/news/${_id}`}>
            <Styles.Title>{title}</Styles.Title>
          </Link>
          <Styles.Info>{title_preview}</Styles.Info>
          <Styles.LearnMore>
            <Link to={`/news/${_id}`}>Узнать больше</Link>
          </Styles.LearnMore>
        </Styles.SlideText>
        <Styles.ImgWrapper>
          <Styles.Img src={getMediaLink(title_photo)} />
        </Styles.ImgWrapper>
      </Styles.Slide>
    </>
  );
};
