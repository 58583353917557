import { LoadingStatus } from '../../types'
import {
  IFetchCategorysAction,
  ISetCategorysLoadingAction,
  ISetCategorysAction,
  CategorysActionType,
} from './contracts/actionTypes'
import { CategorysState } from './contracts/state'

export const setCategorys = (
  payload: CategorysState['items']
): ISetCategorysAction => ({
  type: CategorysActionType.SET_CATEGORYS,
  payload,
})

export const setCategorysLoadingStatus = (
  payload: LoadingStatus
): ISetCategorysLoadingAction => ({
  type: CategorysActionType.SET_LOADING_STATE,
  payload,
})

export const fetchCategorys = (): IFetchCategorysAction => ({
  type: CategorysActionType.FETCH_CATEGORYS,
})
