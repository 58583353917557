import React from 'react';
import * as Styles from './Slide.styles';
import { Button } from '../../../../../../UIHelpers/Buttons';
import { ISale } from '../../../../../../store/ducks/sales/contracts/state';
import { Container } from '../../../../../../UIHelpers/Container';
import { Link } from 'react-router-dom';
import { getMediaLink } from '../../../../../../utils/mediaHelper';
interface Props {
  slide: ISale;
}

export const Slide = ({ slide }: Props) => {
  const { title, description, text_button, link_button, photo } = slide;
  return (
    <>
      <Styles.Slide>
        <Container>
          <Styles.SlideContainer>
            <Styles.Suptitle>BRICK GASTRO BAR</Styles.Suptitle>
            <Styles.Title>{title}</Styles.Title>
            <Styles.Info>{description}</Styles.Info>
            <Link to={link_button.trim()}>
              <Button
                color="#F1F1F1"
                background="#F25003"
                typeButton="normal"
                content={text_button}
                additionalStyles="@media(min-width:1024px){
                  &:hover{
                    background:#ff8763;
                  }"
              />
            </Link>
          </Styles.SlideContainer>
        </Container>
        <Styles.SlideBackground
          className="Pizda"
          background={getMediaLink(photo)}
        />
      </Styles.Slide>
    </>
  );
};
