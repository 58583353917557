import styled from '@emotion/styled'

export const InputDefault = styled.input`
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  background: #fff;
`

export const InputBlock = styled.div`
  p {
    margin-top: 8px;
    font-size: 12px;
    color: tomato;
  }
  margin-bottom: 40px;
`

export const Input = styled(InputDefault)`
  &[type='file'] {
    background: none;
    box-shadow: none;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0;
  }
  box-sizing: border-box;
  width: 100%;
  outline: none;
  border: 0;
  border-radius: 5px;
  padding: 8px;
  box-shadow: rgba(208, 208, 208, 0.5) 4px 4px 10px;
  background: #fff;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  margin-top: 10px;
  @media (min-width: 1280px) {
    height: 60px;
    padding: 14px;
    font-size: 16px;
    line-height: 22px;
  }
  @media (max-width: 768px) {
    width: 100%;

    &::placeholder {
      font-size: 12px;
      color: #bcc2c7;
    }
    margin-bottom: 25px;
  }
`
export const Label = styled.label``
