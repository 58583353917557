import React from 'react'
import ContentLoader from 'react-content-loader'

export const MyLoader = (props: any) => {
  const width =
    document.documentElement.clientWidth < 768 ? 'mobile' : 'desktop'
  if (width === 'mobile') {
    return (
      <ContentLoader
        speed={2}
        width={375}
        height={667}
        viewBox="0 0 375 667"
        backgroundColor="#eeeeee"
        foregroundColor="#fafafa"
        {...props}
      >
        <rect x="14" y="17" rx="0" ry="0" width="60" height="7" />
        <rect x="18" y="45" rx="4" ry="4" width="335" height="31" />
        <rect x="18" y="116" rx="4" ry="4" width="335" height="217" />
        <rect x="18" y="375" rx="4" ry="4" width="151" height="17" />
        <rect x="18" y="395" rx="4" ry="4" width="151" height="2" />
        <rect x="18" y="433" rx="4" ry="4" width="335" height="219" />
      </ContentLoader>
    )
  }

  return (
    <ContentLoader
      speed={2}
      width={1580}
      height={600}
      viewBox="0 0 1580 600"
      backgroundColor="#eeeeee"
      foregroundColor="#fafafa"
      {...props}
    >
      <rect x="0" y="88" rx="0" ry="0" width="350" height="35" />
      <rect x="0" y="139" rx="4" ry="4" width="350" height="35" />
      <rect x="1" y="18" rx="4" ry="4" width="86" height="20" />
      <rect x="0" y="238" rx="5" ry="5" width="451" height="255" />
      <rect x="0" y="549" rx="5" ry="5" width="145" height="17" />
      <rect x="900" y="48" rx="10" ry="10" width="670" height="539" />
    </ContentLoader>
  )
}
