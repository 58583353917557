import { RootState } from '../../store'
import { ICategory } from '../categorys/contracts/state'
import { LoadingStatus } from '../../types'
import { CategoryState } from './contracts/state'

export const selectCategory = (state: RootState): CategoryState =>
  state.category

export const selectLoadingStatus = (state: RootState): LoadingStatus =>
  selectCategory(state).LoadingStatus

export const selectIsCategoryLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING

export const selectIsCategoryLoaded = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADED

export const selectIsDeleteCategoryLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING

export const selectCategoryData = (state: RootState): ICategory | undefined => {
  return selectCategory(state).data
}
