import css from '@emotion/css'
import styled from '@emotion/styled'

export const Wrapper = styled.div`
  overflow: hidden;
  * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: url('/images/shared/default-background.webp');
  font-family: 'GTEestiProDisplay' sans-serif;
  h2 {
    font-size: 30px;
    text-transform: uppercase;
    line-height: 150%;
    @media (max-width: 768px) {
      font-size: 26px;
    }
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
`

export const MainBg = styled.div<{ background: string }>`
  ${({ background }) => {
    return css`
      background: url(${background}) center no-repeat;
    `
  }}
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`
export const Main = styled.section`
  padding: 80px 0 100px 0;
  @media (max-width: 768px) {
    padding: 144px 0 131px 0;
  }
  @media (max-width: 480px) {
    padding: 110px 0 151px 0;
  }
`
export const MainWrapper = styled.div`
  @media (max-width: 768px) {
    text-align: center;
  }
`
export const RouteItems = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin: 0 auto 40px auto;
    max-width: fit-content;
  }
  @media (max-width: 480px) {
    margin: 0 auto 30px auto;
  }
`
export const RouteItem = styled.div`
  margin-right: 14px;
  &:last-of-type {
    margin-right: 0;
  }
  a {
    @media (min-width: 1024px) {
      &:hover {
        color: #f25003;
      }
      transition: 0.2s;
    }
    font-weight: 300;
    font-size: 12px;
    line-height: 150%;
    color: #f1f1f1;
    text-decoration: none;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`
export const RouteArrow = styled.img`
  margin-right: 14px;
  width: 20px;
  height: 15px;
  margin-top: 5px;
`
export const PostDate = styled.div`
  text-transform: uppercase;
  line-height: 150%;
  font-size: 14px;
  color: rgba(241, 241, 241, 0.4);
  margin: 0 0 40px 0;
  @media (max-width: 768px) {
    font-size: 10px;
    margin: 0 auto 40px auto;
  }
  @media (max-width: 480px) {
    margin: 0 auto 30px auto;
    font-size: 10px;
  }
`
export const Title = styled.h1`
  text-transform: uppercase;
  line-height: 150%;
  font-size: 42px;
  max-width: 780px;
  color: #f25003;
  margin: 0 0 40px 0;
  @media (max-width: 768px) {
    font-size: 34px;
    margin: 0 auto 30px auto;
  }
  @media (max-width: 480px) {
    margin: 0 auto 15px auto;
    font-size: 24px;
  }
`
export const Info = styled.div`
  font-size: 24px;
  line-height: 150%;
  font-weight: normal;
  color: #f1f1f1;
  margin-bottom: 135px;
  max-width: 780px;
  @media (max-width: 768px) {
    max-width: 655px;
    margin: 0 auto 80px auto;
    font-size: 18px;
  }
  @media (max-width: 480px) {
    margin: 0 auto 40px auto;
    font-size: 14px;
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1120px;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    width: 100%;
  }
  @media (max-width: 320px) {
    justify-content: center;
  }
`
export const ContentSuptitle = styled.div`
  color: rgba(23, 24, 25, 0.4);
  text-transform: uppercase;
  margin-bottom: 40px;
  font-size: 14px;
  text-align: left;
  display: flex;
  font-weight: bold;

  @media (max-width: 768px) {
    margin-bottom: 30px;
    font-size: 12px;
  }
  @media (max-width: 480px) {
    margin-bottom: 20px;
    font-size: 10px;
  }
`
export const PageContent = styled.div`
  padding: 100px 0;
  width: 1580px;
  overflow: hidden;
  img {
    max-width: 100%;
  }
  @media (max-width: 1440px) {
    padding-right: 20px;
    width: 1400px;
  }
  @media (max-width: 1400px) {
    padding: 80px 0;
    width: 95%;
  }
  @media (max-width: 768px) {
    padding: 80px 0;
    width: 95%;
  }
  @media (max-width: 480px) {
    padding: 60px 0;
  }
`

export const PostContent = styled.div``

export const ImgLinkContainer = styled.div``
export const ContentH1 = styled.h1``
export const ContentH2 = styled.h2``
export const ContentH3 = styled.h3``
