import React from 'react'
import { Alert } from '@material-ui/lab'

import * as Styles from './Alert.styles'

interface Props {
  severity: any
  text: string
  visibility: boolean
}

export const AlertBlock: React.FC<Props> = ({
  severity,
  text,
  visibility,
}: Props) => {
  return (
    <>
      {visibility && (
        <Styles.Alert>
          <Alert severity={severity} color={severity}>
            {text}
          </Alert>
        </Styles.Alert>
      )}
    </>
  )
}
