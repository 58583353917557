import styled from '@emotion/styled'
export const CurrentPageNumber = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 16px;
  line-height: 150%;
`
export const MenuSlider = styled.section`
  padding: 140px 0 280px 0;
  @media (max-width: 768px) {
    padding: 80px 0 181px 0;
  }
  @media (max-width: 480px) {
    padding: 60px 0 140px 0;
  }
`
export const Preslider = styled.div`
  padding: 0px 8px;
  @media (max-width: 1440px) {
    padding: 0px 3px;
  }
  @media (max-width: 768px) {
    padding: 0 0 0 3px;
  }
  @media (max-width: 480px) {
    padding: 0 0 0 3px;
  }
`

export const Preloader = styled.div`
  display: flex !important;
  width: 100%;
  flex-direction: row !important;
  justify-content: space-between !important;
`

export const ProgressBar = styled.progress`
  position: absolute;
  right: 6px;
  bottom: -105px;
  width: 1360px;
  height: 4px;
  transition: 0.4s;
  ::-webkit-progress-bar {
    transition: 0.4s;
    background: rgba(23, 24, 25, 0.2);
  }
  ::-webkit-progress-value {
    transition: 0.4s;
    background: #171819;
  }
  background: #e5e5e5;
  @media (max-width: 1600px) {
    width: 1150px;
    right: 28px;
  }
  @media (max-width: 1200px) {
    width: 750px;
    bottom: -90px;
  }
  @media (max-width: 768px) {
    width: 584px;
    bottom: -108px;
  }
  @media (max-width: 480px) {
    width: 150px;
    bottom: -58px;
    right: calc(50% - 75px);
  }
`
export const SliderWrapper = styled.div`
  .slick-slide {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .slick-prev,
  .slick-next,
  .slick-arrow {
    top: auto;
    bottom: -163px;
    &:before {
      display: none;
    }
    @media (max-width: 1200px) {
      bottom: -150px;
    }
    @media (max-width: 480px) {
      bottom: -100px;
    }
  }
  .slick-prev,
  .slick-next {
    width: 60px;
    height: 60px;
    @media (max-width: 768px) {
      width: 42px;
      height: 42px;
    }
  }
  .slick-prev {
    transition: 0.1s;
    left: 6px;
    background: url('/icons/slider/left.svg') center no-repeat;
    background-size: cover;
    @media (min-width: 1024px) {
      &:hover {
        background: url('/icons/slider/left-active.svg') center no-repeat;
        background-size: cover;
      }
    }
    @media (max-width: 768px) {
      left: 4px;
    }
  }
  .slick-next {
    transition: 0.1s;
    left: 106px;
    background: url('/icons/slider/right.svg') center no-repeat;
    background-size: cover;
    @media (min-width: 1024px) {
      &:hover {
        background: url('/icons/slider/right-active.svg') center no-repeat;
        background-size: cover;
      }
    }
    @media (max-width: 768px) {
      left: 60px;
    }
    @media (max-width: 480px) {
      left: unset;
      right: 4px;
    }
  }
`
export const Block = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 100px;
  font-weight: bold;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 80px;
    align-items: flex-start;
  }
`
export const SupTitle = styled.div`
  color: rgba(23, 24, 25, 0.4);
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    margin-bottom: 20px;
    font-size: 10px;
  }
`
export const Title = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 150%;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  max-width: 700px;
  margin: 30px 0 0 0;
  color: #171819;
  @media (max-width: 768px) {
    margin: 0 0 40px 0;
  }
`
