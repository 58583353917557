import React from 'react'
import { Link } from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom'
import { Header } from '../../../../shared/containers/Header/Header'
import { Button } from '../../../../UIHelpers/Buttons'
import { Container } from '../../../../UIHelpers/Container'
import * as Styles from './Main.styles'

interface Props {}

export const Main = (props: Props) => {
  return (
    <>
      <Header />
      <Styles.MainBg>
        <Styles.Main>
          <Container>
            <Styles.MainWrapper>
              <Styles.RouteItems>
                <Styles.RouteItem>
                  <RouterLink to="/">Главная</RouterLink>
                </Styles.RouteItem>
                <Styles.RouteArrow src="/icons/menu/arrow.svg" />
                <Styles.RouteItem>
                  <RouterLink to="/menu">Меню</RouterLink>
                </Styles.RouteItem>
              </Styles.RouteItems>
              <Styles.Title>Меню</Styles.Title>
              <Styles.Info>
                Мы сочетаем гастрономический кэжуал и актуальный дизайн
                интерьера, чтобы у вас был бар "на каждый день"
              </Styles.Info>
              <Styles.ButtonsContainer>
                <Link to="kitchen">
                  <Button
                    typeButton="normal"
                    background="#F1F1F1"
                    color="#171819"
                    content="Кухня и закуски"
                    additionalStyles="@media(max-width:768px){width:166px; height:45px;font-size:9px;};@media(max-width:480px){margin-bottom:30px;font-size:8px;width:162px;height:45px;}"
                    hoverBackground="#f25003"
                    hoverColor="#F1F1F1"
                  />
                </Link>
                <Link to="beer">
                  <Button
                    typeButton="normal"
                    background="#F1F1F1"
                    color="#171819"
                    content="Пивная карта"
                    additionalStyles="@media(max-width:768px){width:166px; height:45px;font-size:9px;};@media(max-width:480px){margin-bottom:30px;font-size:8px;width:162px;height:45px;}"
                    hoverBackground="#f25003"
                    hoverColor="#F1F1F1"
                  />
                </Link>
                <Link to="alcohol">
                  <Button
                    typeButton="normal"
                    background="#F1F1F1"
                    color="#171819"
                    content="Крепкий алкоголь"
                    additionalStyles="@media(max-width:768px){width:166px; height:45px;font-size:9px;};@media(max-width:480px){margin-bottom:30px;font-size:8px;width:162px;height:45px;}"
                    hoverBackground="#f25003"
                    hoverColor="#F1F1F1"
                  />
                </Link>
                <Link to="beverages">
                  <Button
                    typeButton="normal"
                    background="#F1F1F1"
                    color="#171819"
                    content="Безалкогольные напитки"
                    additionalStyles="@media(max-width:768px){width:166px; height:45px;font-size:9px;};@media(max-width:480px){margin-bottom:30px;font-size:8px;width:162px;height:45px;}"
                    hoverBackground="#f25003"
                    hoverColor="#F1F1F1"
                  />
                </Link>
              </Styles.ButtonsContainer>
            </Styles.MainWrapper>
          </Container>
        </Styles.Main>
      </Styles.MainBg>
    </>
  )
}
