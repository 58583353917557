import * as Styles from './Suptitle.styles'

import React from 'react'

interface Props {
  content?: string
}

export const Suptitle = ({ content = '' }: Props) => {
  return (
    <>
      <Styles.Suptitle>{content}</Styles.Suptitle>
    </>
  )
}
