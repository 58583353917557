import { call, put, takeLatest } from 'redux-saga/effects'
import { PostsApi } from '../../../services/api/postsApi'
import { setPosts, setPostsLoadingStatus } from './actionCreators'
import { LoadingStatus } from '../../types'
import { PostsActionType } from './contracts/actionTypes'

export function* fetchPostsRequest(): any {
  try {
    const items = yield call(PostsApi.fetchPosts)
    yield put(setPosts(items))
  } catch (error) {
    yield put(setPostsLoadingStatus(LoadingStatus.ERROR))
  }
}

export function* postsSaga(): any {
  yield takeLatest(PostsActionType.FETCH_POSTS, fetchPostsRequest)
}
