import { RootState } from '../../store'
import { LoadingStatus } from '../../types'
import { IPost } from '../posts/contracts/state'
import { PostState } from './contracts/state'

export const selectPost = (state: RootState): PostState => state.post

export const selectLoadingStatus = (state: RootState): LoadingStatus =>
  selectPost(state).LoadingStatus

export const selectIsPostLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING

export const selectIsDeletePostLoading = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADING

export const selectIsPostLoaded = (state: RootState): boolean =>
  selectLoadingStatus(state) === LoadingStatus.LOADED

export const selectPostData = (state: RootState): IPost | undefined => {
  return selectPost(state).data
}
