import React from 'react'
import { Booking } from '../../../shared/containers/Booking'
import { Contacts } from '../../../shared/containers/Contacts'
import { Footer } from '../../../shared/containers/Footer/Footer'
import { Map } from '../../../shared/containers/Map'
import { NewsSlider } from '../../../shared/containers/NewsSlider'
import { Main } from '../components/Main'
import { MainMenu } from '../components/MainMenu'
import MetaTags from 'react-meta-tags'
interface Props {}

import * as Styles from './MenuPage.styles'
import { AdultPopup } from '../../../shared/containers/AdultPopup'

export const MenuPage: React.FC = ({}: Props) => {
  return (
    <>
      <MetaTags>
        <title>BRICK GASTRO BAR | Меню</title>
      </MetaTags>

      <Styles.Wrapper>
        <Main />
        <MainMenu />
        <Booking />
        <NewsSlider />
        <Contacts />
        <Map />
        <Footer />
        <AdultPopup />
      </Styles.Wrapper>
    </>
  )
}
