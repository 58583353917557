import produce, { Draft } from 'immer'
import { LoadingStatus } from '../../types'
import { PostActions } from './actionCreators'
import { PostActionsType } from './contracts/actionTypes'
import { PostState } from './contracts/state'

const initialPostState: PostState = {
  data: undefined,
  LoadingStatus: LoadingStatus.NEVER,
}

export const postReducer = produce(
  (draft: Draft<PostState>, action: PostActions) => {
    switch (action.type) {
      case PostActionsType.SET_POST_DATA:
        draft.data = action.payload
        draft.LoadingStatus = LoadingStatus.LOADED
        break

      case PostActionsType.FETCH_POST_DATA:
        draft.data = undefined
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      case PostActionsType.UPDATE_POST_DATA:
        draft.data = undefined
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      case PostActionsType.SET_LOADING_STATE:
        draft.LoadingStatus = action.payload
        break
      case PostActionsType.DELETE_POST_DATA:
        draft.LoadingStatus = LoadingStatus.LOADING
        break
      case PostActionsType.ADD_POST:
        draft.LoadingStatus = LoadingStatus.LOADING
        break
      default:
        break
    }
  },
  initialPostState
)
