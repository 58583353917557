import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  AdminPage,
  HomePage,
  MenuPage,
  BlogPage,
  ContactsPage,
  ErrorPage,
  NewsPage,
  BonusPage,
  PrivacyPolicy,
  AppPolicy,
  Page,
} from './pages';
import { App as AppSettings } from './app/containers/App';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectGoodsItems,
  selectIsGoodsLoading,
} from './store/ducks/goods/selectors';
import { fetchGoods } from './store/ducks/goods/actionCreators';
import { DataContext } from './Context/DataContext';
import {
  selectIsSalesLoading,
  selectSalesItems,
} from './store/ducks/sales/selectors';
import { fetchSales } from './store/ducks/sales/actionCreators';
import { fetchPosts } from './store/ducks/posts/actionCreators';
import {
  selectIsPostsLoading,
  selectPostsItems,
} from './store/ducks/posts/selectors';
import { Cookies } from './shared/containers/Cookies';

function App() {
  const dispatch = useDispatch();

  const goodData = useSelector(selectGoodsItems);
  const isLoadingGoods = useSelector(selectIsGoodsLoading);

  const saleData = useSelector(selectSalesItems);
  const isLoadingSales = useSelector(selectIsSalesLoading);

  const postData = useSelector(selectPostsItems);
  const isLoadingPosts = useSelector(selectIsPostsLoading);

  useEffect(() => {
    dispatch(fetchGoods());
    dispatch(fetchSales());
    dispatch(fetchPosts());
  }, [dispatch]);

  useEffect(() => {
    localStorage.removeItem('confirm');
  }, []);

  return (
    <>
      <AppSettings />
      <Cookies />
      <DataContext.Provider
        value={{
          isLoadingGoods,
          isLoadingSales,
          isLoadingPosts,
          goodData,
          saleData,
          postData,
        }}
      >
        <Switch>
          <Route exact path="/" component={HomePage} />

          <Route exact path="/menu" component={MenuPage} />

          <Route exact path="/blog" component={BlogPage} />

          <Route exact path="/contacts" component={ContactsPage} />

          <Route exact path="/news" component={NewsPage} />

          <Route path="/admin" component={AdminPage} />

          <Route exact path="/bonus" component={BonusPage} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/app-privacy" component={AppPolicy} />
          <Route path="/news/:id" component={Page} />

          <Route component={ErrorPage} />
        </Switch>
      </DataContext.Provider>
    </>
  );
}

export default App;
