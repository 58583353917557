import { axios } from '../../core/axios'
import { LoginFormProps } from '../../pages/Admin/pages/Auth/Auth'
// import { IUser } from '../../store/ducks/user/contracts/state'

interface Response<T> {
  status: string
  data: T
}

export const AuthApi = {
  async signIn(postData: LoginFormProps): Promise<Response<any>> {
    const { data } = await axios.post<Response<any>>('/auth/login', postData)
    return data
  },
  async getMe(): Promise<Response<any>> {
    const { data } = await axios.get<Response<any>>('/users/me')
    return data
  },
}
