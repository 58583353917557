import styled from '@emotion/styled'

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1580px;
  position: relative;
  @media (max-width: 1600px) {
    padding: 0px 20px;
  }
`
