import React, { useState } from 'react'
import Slider from 'react-slick'
import * as Styles from './NewsSlider.styles'
import { Button } from '../../../UIHelpers/Buttons'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Slide } from './Slide'
import { Container } from '../../../UIHelpers/Container'

import { MyLoader } from './Preloader'
import { DataContext } from '../../../Context/DataContext'
export interface ISlide {
  title: string
  info: string
  btnText: string
}
interface Props {}

export const NewsSlider = ({}: Props) => {
  const { isLoadingPosts, postData } = React.useContext(DataContext)

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    afterChange: setCurrentIndex,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }
  const slideToShow = document?.documentElement?.clientWidth <= 768 ? 2 : 3
  return (
    <>
      <Styles.NewsSlider>
        <Container>
          <Styles.SliderWrapper>
            <Styles.Preslider>
              <Styles.SupTitle>Новости</Styles.SupTitle>
              <Styles.Block>
                <Styles.Title>
                  Следите за нашими новостями, акциями и новинками
                </Styles.Title>
                <Button
                  typeButton="normal"
                  background="#F25003"
                  href="/news"
                  color="#F1F1F1"
                  content="ВСЕ НОВОСТИ"
                  hoverBackground="#f89577"
                />
              </Styles.Block>
            </Styles.Preslider>
            <Slider {...settings}>
              {isLoadingPosts
                ? new Array(3).fill('').map((_, i) => <MyLoader key={i} />)
                : postData.map(post => <Slide slide={post} key={post.title} />)}
            </Slider>
          </Styles.SliderWrapper>
          {!isLoadingPosts && (
            <Styles.ProgressBar
              value={currentIndex + slideToShow}
              max={postData.length}
            ></Styles.ProgressBar>
          )}
        </Container>
      </Styles.NewsSlider>
    </>
  )
}
