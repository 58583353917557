import produce, { Draft } from 'immer'
import { LoadingStatus } from '../../types'
import { GoodActions } from './actionCreators'
import { GoodActionsType } from './contracts/actionTypes'
import { GoodState } from './contracts/state'

const initialGoodState: GoodState = {
  data: undefined,
  LoadingStatus: LoadingStatus.NEVER,
}

export const goodReducer = produce(
  (draft: Draft<GoodState>, action: GoodActions) => {
    switch (action.type) {
      case GoodActionsType.SET_GOOD_DATA:
        draft.data = action.payload
        draft.LoadingStatus = LoadingStatus.LOADED
        break

      case GoodActionsType.FETCH_GOOD_DATA:
        draft.data = undefined
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      case GoodActionsType.UPDATE_GOOD_DATA:
        draft.data = undefined
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      case GoodActionsType.SET_LOADING_STATE:
        draft.LoadingStatus = action.payload
        break
      case GoodActionsType.DELETE_GOOD_DATA:
        draft.LoadingStatus = LoadingStatus.LOADING
        break
      case GoodActionsType.ADD_GOOD:
        draft.LoadingStatus = LoadingStatus.LOADING
        break

      default:
        break
    }
  },
  initialGoodState
)
