import produce, { Draft } from 'immer';
import { GoodsActions, GoodsActionType } from './contracts/actionTypes';
import { LoadingStatus } from '../../types';
import { GoodsState } from './contracts/state';

const initialGoodsState: GoodsState = {
  items: [],
  loadingState: LoadingStatus.NEVER,
};

export const goodsReducer = produce(
  (draft: Draft<GoodsState>, action: GoodsActions) => {
    switch (action.type) {
      case GoodsActionType.SET_GOODS:
        draft.items = action.payload;
        draft.loadingState = LoadingStatus.LOADED;
        break;

      case GoodsActionType.FETCH_GOODS:
        draft.items = [];
        draft.loadingState = LoadingStatus.LOADING;
        break;

      case GoodsActionType.FETCH_GOODS_BY_CATEGORY:
        draft.items = [];
        draft.loadingState = LoadingStatus.LOADING;
        break;

      case GoodsActionType.SET_LOADING_STATE:
        draft.loadingState = action.payload;
        break;

      default:
        break;
    }
  },
  initialGoodsState
);
