import React from 'react'
import { useHistory } from 'react-router'

interface Props {
  category: ICategory
  setId: any
}

import * as Styles from './CategoryPreview.styles'
import { ICategory } from '../../../../../../store/ducks/categorys/contracts/state'
import { Link } from 'react-router-dom'

export const Category: React.FC<Props> = ({ category, setId }: Props) => {
  const history = useHistory()

  const clickHandler = (): void => {
    history.push(`/admin/category/edit/${category._id}`)
  }

  const onChange = (e: any): void => {
    const checked = (e.target as HTMLInputElement).checked
    if (checked) {
      setId(() => ({
        id: category._id,
        type: 'add',
      }))
    } else {
      setId(() => ({
        id: category._id,
        type: 'delete',
      }))
    }
  }

  return (
    <Styles.Category>
      <div>
        <Styles.TitleWrap>
          <Link to={`/admin/category/edit/${category._id}`}>
            <Styles.Title>{category.value}</Styles.Title>
          </Link>
          <Styles.InputWrap>
            <Styles.Input
              id={category._id}
              onChange={onChange}
              type="checkbox"
            />

            <Styles.Delete htmlFor={category._id}>Удалить</Styles.Delete>
          </Styles.InputWrap>
        </Styles.TitleWrap>
      </div>
    </Styles.Category>
  )
}
