import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const ButtonReset = css`
  margin: 0;
  border: 0;
  padding: 0;
  background: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`

export const BtnDefault = styled.button`
  ${ButtonReset}
  background: #f25003;
  color: #f1f1f1;
  transition: 0.3s;
`

export const BtnRounded = styled(BtnDefault)<{
  background: string
  colorStyle: string
  additionalStyles: string
  hoverBackground: string
  backgroundSize: string
  backgroundSizeMobile: string
}>`
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  @media (max-width: 1300px) {
    width: 40px;
    height: 40px;
    font-size: 11px;
  }
  ${({
    background,
    colorStyle,
    additionalStyles,
    hoverBackground,
    backgroundSize,
    backgroundSizeMobile,
  }) => {
    return css`
      background: ${background};
      background-size: ${backgroundSize};
      color: ${colorStyle};
      ${additionalStyles}
      @media(min-width:1024px) {
        &:hover {
          background: ${hoverBackground};
          background-size: ${backgroundSize};
        }
      }
      @media (max-width: 1024px) {
        background-size: ${backgroundSizeMobile};
      }
    `
  }}
`

export const BtnBurger = styled(BtnRounded)<{
  background: string
  colorStyle: string
  additionalStyles: string
}>`
  box-sizing: border-box;
  border: 2px solid #171819;
  span,
  span:after,
  span:before {
    display: block;
    background: #171819;
    content: '';
    width: 24px;
    height: 2px;
  }

  span {
    position: relative;
    &:after,
    &:before {
      position: absolute;
      left: calc(50% - 12px);
      top: calc(50% - 1px);
    }
    &:after {
      transition: 0.5s;
      margin-top: 5px;
    }
    &:before {
      transition: 0.5s;
      margin-top: -5px;
    }
  }

  @media (min-width: 769px) {
    display: none;
  }
  ${({ background, colorStyle, additionalStyles }) => {
    return css`
      ${additionalStyles}
      background: ${background};
      color: ${colorStyle};
    `
  }}
`

export const BtnNormal = styled(BtnDefault)<{
  background: string
  colorStyle: string
  additionalStyles: string
  hoverBackground: string
  hoverColor: string
}>`
  font-weight: 600;
  transition: 0.3s;
  width: 250px;
  height: 56px;
  border-radius: 60px;
  font-size: 12px;
  text-transform: uppercase;

  ${({
    background,
    colorStyle,
    additionalStyles,
    hoverBackground,
    hoverColor,
  }) => {
    return css`
      background: ${background};
      color: ${colorStyle};
      @media (min-width: 1024px) {
        width: 200px;
        height: 40px;
        font-size: 11px;
        &:hover {
          color: ${hoverColor};
          background: ${hoverBackground};
        }
      }
      @media (min-width: 1300px) {
        width: 250px;
        height: 56px;
        font-size: 12px;
      }
      @media (max-width: 480px) {
        width: 180px;
        height: 45px;
        font-size: 10px;
      }
      ${additionalStyles}
    `
  }}
`
