import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import * as Styles from './GeneralNewsSlider.styles'
import { Button } from '../../../../UIHelpers/Buttons'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Slide } from './Slide'
import { useSelector, useDispatch } from 'react-redux'
import { Container } from '../../../../UIHelpers/Container'
import { fetchPosts } from '../../../../store/ducks/posts/actionCreators'
import {
  selectIsPostsLoading,
  selectPostsItems,
} from '../../../../store/ducks/posts/selectors'
import { MyLoader } from './Preloader'
import { DataContext } from '../../../../Context/DataContext'
export interface ISlide {
  title: string
  info: string
  btnText: string
}
interface Props {}

export const GeneralNewsSlider = (props: Props) => {
  const { isLoadingPosts, postData } = React.useContext(DataContext)

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    afterChange: setCurrentIndex,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  return (
    <>
      <Styles.GeneralNewsSlider>
        <Container>
          <Styles.SliderWrapper>
            <Slider {...settings}>
              {isLoadingPosts
                ? new Array(3).fill('').map((_, i) => <MyLoader key={i} />)
                : postData.map(post => <Slide slide={post} key={post.title} />)}
            </Slider>
          </Styles.SliderWrapper>
          {!isLoadingPosts && (
            <Styles.ProgressBar
              value={currentIndex + 1}
              max={postData.length}
            ></Styles.ProgressBar>
          )}
        </Container>
      </Styles.GeneralNewsSlider>
    </>
  )
}
