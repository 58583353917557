import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const Slide = styled.div`
  position: relative;
  max-width: 100%;
  height: 850px;
  @media (max-width: 1440px) {
    height: 730px;
  }
  @media (max-width: 768px) {
    text-align: center;
    max-width: none;
    width: 100%;
    height: 940px;
  }
  @media (max-width: 480px) {
    width: auto;
    height: 587px;
  }
`
export const SlideContainer = styled.div`
  max-width: 655px;
  @media (max-width: 768px) {
    margin: 0 auto;
  }
`
export const Suptitle = styled.div`
  font-size: 24px;
  color: #f25003;
  text-transform: uppercase;
  margin-bottom: 60px;
  padding-top: 100px;
  @media (max-width: 1440px) {
    padding-top: 70px;
    margin-bottom: 30px;
  }
  @media (max-width: 768px) {
    text-align: center;
    margin-bottom: 30px;
    padding-top: 80px;
  }
  @media (max-width: 480px) {
    padding-top: 40px;
    margin-bottom: 15px;
    font-size: 12px;
  }
`
export const SlideBackground = styled.div<{ background: string }>`
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  max-height: 100%;
  object-fit: cover;
  height: 100%;
  width: 100%;
  z-index: -2;
  ${({ background }) => {
    return css`
      background-image: url(${background});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom;
    `
  }}
`

export const ButtonContainer = styled.div``
export const Title = styled.h1`
  color: #f1f1f1;
  font-size: 42px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 60px;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  box-orient: vertical;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
  @media (max-width: 480px) {
    margin-bottom: 15px;
    font-size: 24px;
  }
`
export const Info = styled.div`
  overflow: hidden;
  color: #f1f1f1;
  font-size: 24px;
  max-height: 72px;
  margin-bottom: 60px;
  font-weight: 300;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  @media (max-width: 768px) {
    margin-bottom: 60px;
    font-size: 14px;
  }
  @media (max-width: 480px) {
    -webkit-line-clamp: 3;
    line-clamp: 3;
    max-height: 72px;
  }
`
