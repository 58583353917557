import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  addGood,
  deleteGoodData,
  fetchGoodData,
  setGoodData,
  updateGoodData,
} from '../../../../../store/ducks/good/actionCreators';
import {
  selectIsGoodLoading,
  selectGoodData,
} from '../../../../../store/ducks/good/selectors';
import { IGood } from '../../../../../store/ducks/goods/contracts/state';
import { Container } from '../../../components/Container';
import { Header } from '../../../components/Header';
import { Input } from '../../../components/Inputs';
import LinearProgress from '@material-ui/core/LinearProgress';

import MetaTags from 'react-meta-tags';

import * as Styles from './Good.styles';
import { Button } from '../../../components/Buttons';
import { SelectBlock } from '../../../components/Select';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { uploadImage } from '../../../../../utils/uploadImage';
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@emotion/react';
import { getMediaLink } from '../../../../../utils/mediaHelper';

interface Props {
  type: string;
}

interface IGoodForm {
  title: string;
  photo: string;
  price: string;
  weight: string;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      color: '#00c853 !important',
      position: 'absolute',
      top: '38%',
    },
  })
);

export const Good: React.FC<Props> = ({ type }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const goodData = useSelector(selectGoodData);
  const isLoading = useSelector(selectIsGoodLoading);
  const history = useHistory();

  const params: { id?: string } = useParams();
  const id = params.id;

  const [preloader, setPreloader] = useState(false);
  const [errorCategory, setErrorCategory] = useState(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [query, setQuery] = useState<IGood>({
    title: '',
    description: '',
    photo: '',
    price: '',
    category: {
      label: '',
      value: '',
    },
  });

  const goodFormSchema = yup.object().shape({
    title: yup.string().required('Заголовок обязателен'),
    price: yup.string().required('Цена обязательна'),
  });

  const { register, handleSubmit, formState } = useForm<IGoodForm>({
    resolver: yupResolver(goodFormSchema),
  });

  const updateHandler = useCallback(() => {
    if (query?._id) {
      dispatch(updateGoodData(query, query?._id));
      setUpdate(!update);
    }
  }, [query]);

  const deleteHandler = useCallback(() => {
    const confirmed = confirm('Удалить товар?');
    if (confirmed) {
      dispatch(deleteGoodData(goodData?._id || 'error'));
      setQuery((prev: IGood) => ({
        ...prev,
        title: '',
        description: '',
        photo: '',
        price: '',
        category: {
          label: '',
          value: '',
        },
      }));

      setDeleted(!deleted);
    }
  }, [goodData]);

  const addHandler = useCallback(() => {
    dispatch(addGood(query));
    setAdd(!add);
  }, [query]);

  useEffect(() => {
    if (id) {
      dispatch(fetchGoodData(id));
    }
    return () => {
      dispatch(setGoodData(undefined));
    };
  }, [dispatch, id, update]);

  useEffect(() => {
    setQuery((prev: IGood) => ({
      ...prev,
      ...goodData,
    }));
  }, [goodData]);

  useEffect(() => {
    if (type === 'add' && goodData?._id && add) {
      history.push(`/admin/good/edit/${goodData?._id}`);
    }

    if (type === 'add') {
      setQuery(() => ({
        title: '',
        description: '',
        photo: '',
        price: '',
        category: {
          label: '',
          value: '',
        },
      }));
    }
  }, [add, goodData]);

  useEffect(() => {
    if (type === 'update' && deleted) {
      history.push(`/admin/good/list`);
    }
  }, [deleted]);

  const onFieldChange = (id: string, value: any): void => {
    setQuery({
      ...query,
      [id]: value,
    });
  };

  const readImage = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const input = e.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file) {
      setPreloader(true);
      const { name } = await uploadImage(file);
      setPreloader(false);

      setQuery((prev: IGood) => ({
        ...prev,
        photo: name,
      }));
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    onFieldChange(e.target.id, e.target.value);
  };

  const src = query['photo'];

  const onSubmit = async (_: IGoodForm) => {
    if (!query['category'].value) {
      setErrorCategory(true);
      return;
    }
    type === 'update' ? updateHandler() : addHandler();
  };

  if (isLoading) {
    return (
      <>
        <MetaTags>
          <title>Админка | Загрузка...</title>
        </MetaTags>

        <Header collection={'good'} />

        <Container>
          <Styles.Loading>
            <p>
              {type === 'update' ? 'Обновление товара' : 'Добавление товара'}
            </p>
            <LinearProgress />
          </Styles.Loading>
        </Container>
      </>
    );
  }

  return (
    <>
      <MetaTags>
        <title>Админка | {goodData?.title || 'Добавление товара'}</title>
      </MetaTags>

      <Header collection={'good'} />

      <Container>
        <Styles.Good onSubmit={handleSubmit(onSubmit)}>
          <Styles.HeaderGood>
            <Styles.Title>
              {type === 'add' ? 'Добавление товара' : 'Обновление товара'}
            </Styles.Title>
            <div>
              {type === 'update' && (
                <Button onClick={deleteHandler} type={'delete'} />
              )}
              <Button
                typeButton={'submit'}
                type={type === 'add' ? 'create' : 'update'}
              />
            </div>
          </Styles.HeaderGood>
          <Input
            label="Название товара"
            placeholder="Введите название"
            id="title"
            type="text"
            nameProps="title"
            refProp={register}
            errors={formState.errors}
            onChange={onChange}
            value={query['title']}
          />

          <Styles.InputFileWrapper>
            <Input
              label="Фото товара"
              placeholder="Выберите фото"
              id="photo"
              nameProps="photo"
              refProp={register}
              errors={formState.errors}
              onChange={readImage}
              type="file"
            />
            {query.photo && query.photo !== '' && !preloader ? (
              <img src={getMediaLink(query.photo)} alt="src" />
            ) : preloader ? (
              <CircularProgress className={classes.root} />
            ) : (
              ''
            )}
          </Styles.InputFileWrapper>

          <Input
            label="Описание"
            placeholder="Введите описание товара"
            id="description"
            nameProps="description"
            refProp={register}
            errors={formState.errors}
            type="text"
            value={query['description']}
            onChange={onChange}
          />

          <Input
            label="Цена"
            placeholder="Введите цену товара в рублях"
            id="price"
            nameProps="price"
            refProp={register}
            errors={formState.errors}
            type="text"
            value={query['price']}
            onChange={onChange}
          />

          <SelectBlock
            isMulti={false}
            errorCategory={errorCategory}
            setErrorCategory={setErrorCategory}
            setSelect={setQuery}
            select={query.category.value ? query.category : null}
            title={'Категории'}
            name={'category'}
          />

          <Button
            typeButton={'submit'}
            type={type === 'add' ? 'create' : 'update'}
          />
        </Styles.Good>
      </Container>
    </>
  );
};
