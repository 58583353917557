import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  addSale,
  deleteSaleData,
  fetchSaleData,
  setSaleData,
  updateSaleData,
} from '../../../../../store/ducks/sale/actionCreators';
import {
  selectIsSaleLoading,
  selectSaleData,
} from '../../../../../store/ducks/sale/selectors';
import { ISale } from '../../../../../store/ducks/sales/contracts/state';
import { Container } from '../../../components/Container';
import { Header } from '../../../components/Header';
import { Input } from '../../../components/Inputs';
import LinearProgress from '@material-ui/core/LinearProgress';

import * as Styles from './Sale.styles';
import { Button } from '../../../components/Buttons';
import { AlertBlock } from '../../../components/Alerts';

import MetaTags from 'react-meta-tags';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { uploadImage } from '../../../../../utils/uploadImage';
import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@emotion/react';
import { getMediaLink } from '../../../../../utils/mediaHelper';

interface Props {
  type: string;
}

interface IAlert {
  text: string;
  visibility: boolean;
  severity: string;
}

interface ISaleForm {
  title: string;
  description: string;
  photo: string;
  link_button: string;
  text_button: string;
}

export interface ImageObj {
  blobUrl: string;
  file: File;
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    root: {
      color: '#00c853 !important',
      position: 'absolute',
      top: '38%',
    },
  })
);

export const Sale: React.FC<Props> = ({ type }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const saleData = useSelector(selectSaleData);
  const isLoading = useSelector(selectIsSaleLoading);
  const history = useHistory();

  const params: { id?: string } = useParams();
  const id = params.id;

  const [preloader, setPreloader] = useState(false);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlert>({
    text: '',
    visibility: false,
    severity: '',
  });
  const [add, setAdd] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [query, setQuery] = useState<ISale>({
    title: '',
    description: '',
    photo: '',
    link_button: '',
    text_button: '',
  });

  const saleFormSchema = yup.object().shape({
    title: yup.string().required('Заголовок обязателен'),
    description: yup.string().required('Описание обязательно'),
    link_button: yup.string().required('Ссылка в кнопке обязательна'),
    text_button: yup.string().required('Текст кнопки обязателен'),
    photo: yup
      .mixed()
      .required('Фото обязательно')
      .test('fileFormat', 'Фото обязательно', value => {
        if (src) {
          return true;
        }

        return (
          value &&
          ['image/jpeg', 'image/png', 'image/webp'].includes(value[0]?.type)
        );
      }),
  });

  const { register, handleSubmit, formState } = useForm<ISaleForm>({
    resolver: yupResolver(saleFormSchema),
  });

  const updateHandler = useCallback(() => {
    if (!query.photo) return;
    if (query?._id) {
      dispatch(updateSaleData(query, query?._id));
      setUpdate(!update);
    }
  }, [query]);

  const deleteHandler = useCallback(() => {
    const confirmed = confirm('Удалить пост?');
    if (confirmed) {
      dispatch(deleteSaleData(saleData?._id || 'error'));
      setQuery((prev: ISale) => ({
        ...prev,
        title: '',
        description: '',
        photo: '',
        link_button: '',
        text_button: '',
      }));

      setDeleted(!deleted);
    }
  }, [saleData]);

  const addHandler = useCallback(async () => {
    if (!query.photo) return;
    dispatch(addSale(query));
    setAlert({
      ...alert,
      text: 'Акция успешно добавлена!',
      severity: 'success',
      visibility: true,
    });

    setTimeout(() => {
      setAlert({
        ...alert,
        visibility: false,
      });

      setAdd(!add);
    }, 1000);
  }, [query]);

  useEffect(() => {
    if (id) {
      dispatch(fetchSaleData(id));
    }
    return () => {
      dispatch(setSaleData(undefined));
    };
  }, [dispatch, id, update]);

  useEffect(() => {
    setQuery((prev: ISale) => ({
      ...prev,
      ...saleData,
    }));
  }, [saleData]);

  useEffect(() => {
    if (type === 'add' && saleData?._id && add) {
      history.push(`/admin/sale/edit/${saleData?._id}`);
    }

    if (type === 'add') {
      setQuery(() => ({
        title: '',
        description: '',
        photo: '',
        link_button: '',
        text_button: '',
      }));
    }
  }, [add, saleData]);

  useEffect(() => {
    if (type === 'update' && deleted) {
      history.push(`/admin/sale/list`);
    }
  }, [deleted]);

  const onFieldChange = (id: string, value: any): void => {
    setQuery({
      ...query,
      [id]: value,
    });
  };

  const readImage = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const input = e.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file) {
      setPreloader(true);
      const { name } = await uploadImage(file);
      setPreloader(false);

      setQuery((prev: ISale) => ({
        ...prev,
        photo: name,
      }));
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    onFieldChange(e.target.id, e.target.value);
  };

  const src = query['photo'];

  const onSubmit = async (data: ISaleForm) => {
    type === 'update' ? updateHandler() : addHandler();
  };

  if (isLoading) {
    return (
      <>
        <MetaTags>
          <title>Админка | Загрузка...</title>
        </MetaTags>

        <Header collection={'sale'} />

        <Container>
          <Styles.Loading>
            <p>{type === 'update' ? 'Обновление акции' : 'Добавление акции'}</p>
            <LinearProgress />
          </Styles.Loading>
        </Container>
      </>
    );
  }

  return (
    <>
      <MetaTags>
        <title>Админка | {saleData?.title || 'Добавление акции'}</title>
      </MetaTags>

      <Header collection={'sale'} />

      <Container>
        <AlertBlock
          severity={alert.severity}
          visibility={alert.visibility}
          text={alert.text}
        />
        <Styles.Sale onSubmit={handleSubmit(onSubmit)}>
          <Styles.HeaderSale>
            <Styles.Title>
              {type === 'add' ? 'Добавление акции' : 'Обновление акции'}
            </Styles.Title>
            <div>
              {type === 'update' && (
                <Button onClick={deleteHandler} type={'delete'} />
              )}
              <Button
                typeButton={'submit'}
                type={type === 'add' ? 'create' : 'update'}
              />
            </div>
          </Styles.HeaderSale>
          <Input
            label="Название товара"
            placeholder="Введите название"
            id="title"
            nameProps="title"
            type="text"
            refProp={register}
            onChange={onChange}
            errors={formState.errors}
            value={query['title']}
          />

          <Styles.InputFileWrapper>
            <Input
              label="Фото акции"
              placeholder="Выберите фото"
              id="photo"
              nameProps="photo"
              refProp={register}
              onChange={readImage}
              type="file"
              errors={formState.errors}
            />
            {query.photo && query.photo !== '' && !preloader ? (
              <img src={getMediaLink(query.photo)} alt="src" />
            ) : preloader ? (
              <CircularProgress className={classes.root} />
            ) : (
              ''
            )}
          </Styles.InputFileWrapper>

          <Input
            label="Описание"
            placeholder="Введите описание акции"
            id="description"
            nameProps="description"
            refProp={register}
            type="text"
            value={query['description']}
            onChange={onChange}
            errors={formState.errors}
          />

          <Input
            label="Текст кнопки"
            placeholder="Введите текст кнопки"
            id="text_button"
            nameProps="text_button"
            type="text"
            refProp={register}
            value={query['text_button']}
            onChange={onChange}
            errors={formState.errors}
          />

          <Input
            label="Ссылка в кнопке"
            placeholder="Введите ссылку в кнопке"
            id="link_button"
            nameProps="link_button"
            type="text"
            refProp={register}
            value={query['link_button']}
            onChange={onChange}
            errors={formState.errors}
          />

          <Button
            typeButton={'submit'}
            type={type === 'add' ? 'create' : 'update'}
          />
        </Styles.Sale>
      </Container>
    </>
  );
};
