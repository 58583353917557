import { css } from '@emotion/core'
import styled from '@emotion/styled'

export const Header = styled.div`
  width: 100%;
  background: #333;
  position: fixed;
  top: 0;
  box-shadow: 0px 6px 19px 0px rgba(204, 204, 204, 1);
  z-index: 1000;
`

export const Logout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 38px;
  width: 38px;
  border-radius: 50%;
  background: #42a5f5;
  box-shadow: 3px 9px 26px rgba(35, 168, 225, 0.2);

  img {
    height: 18px;
    margin-left: 5px;
    transition: 0.3s;
  }
  transition: 0.3s;
  &:hover {
    background: #333;
    box-shadow: 3px 9px 26px rgba(35, 168, 225, 0.2);
    img {
      transform: translateX(5px);
    }
  }
  margin-left: 15px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const HeaderWrapper = styled.div`
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  button {
    margin-left: 15px;
  }
`

export const HeaderLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

export const HeaderLink = styled.div<{ active: boolean }>`
  a {
    ${({ active }) => css`
      border-bottom: 2px solid ${active ? '#fff' : 'transparent'};
    `}
    margin-right: 20px;
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    transition: 0.2s;
    color: #fff;

    &:hover {
      border-bottom: 2px solid #fff;
    }
  }
`
