import styled from '@emotion/styled'

export const Post = styled.div`
  margin-bottom: 30px;
  background: #fff;
  box-shadow: rgba(208, 208, 208, 0.5) 4px 4px 10px;
  padding: 30px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 5px;
  max-width: 360px;
  width: 100%;
  height: fit-content;
  a {
    text-decoration: none;
  }
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
`

export const CreatedAt = styled.div`
  font-size: 14px;
  color: #bdbdbd;
`

export const UpdatedAt = styled.div`
  font-size: 14px;
  color: #bdbdbd;
  margin-top: 5px;
`

export const TitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`

export const Delete = styled.label`
  margin-right: 5px;
  font-size: 10px;
  cursor: pointer;
  user-select: none;
`

export const InputWrap = styled.div`
  display: flex;
  align-items: center;
  input[type='checkbox'] {
    background: red;
    order: 1;
    cursor: pointer;
  }
  input[type='checkbox']:checked ~ label {
    color: tomato;
  }
`

export const Input = styled.input``

export const Title = styled.h2`
  cursor: pointer;
  margin: 0;
  font-weight: 500;
  color: #424242;
  font-size: 18px;
`

export const Img = styled.img`
  max-width: 100%;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
`
