import { LinearProgress } from '@material-ui/core';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { fetchCategorys } from '../../../../../store/ducks/categorys/actionCreators';
import { selectCategorysItems } from '../../../../../store/ducks/categorys/selectors';
import {
  selectIsDeleteGoodLoading,
  selectIsGoodLoading,
} from '../../../../../store/ducks/good/selectors';
import {
  fetchGoodsByCategory,
  setGoods,
} from '../../../../../store/ducks/goods/actionCreators';
import {
  selectIsGoodsLoading,
  selectGoodsItems,
} from '../../../../../store/ducks/goods/selectors';
import { Button } from '../../../components/Buttons';
import { Container } from '../../../components/Container';
import { Header } from '../../../components/Header';
import { Good } from '../components/GoodPreview/GoodPreview';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import MetaTags from 'react-meta-tags';

import Select from 'react-select';
import * as Styles from './GoodList.styles';
import { ICategory } from '../../../../../store/ducks/goods/contracts/state';
import { IGood } from '../../../../../store/ducks/goods/contracts/state';
import { updateGoodData } from '../../../../../store/ducks/good/actionCreators';
import { useQuery } from '../../../../../hooks/useQuery';

interface Props {}

interface ISetId {
  type: string;
  id: string;
}

const move = (
  source: any,
  destination: any,
  droppableSource: any,
  droppableDestination: any
) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  //@ts-ignore
  result[droppableSource.droppableId] = sourceClone;
  //@ts-ignore
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  paddingBottom: grid,
  ...draggableStyle,
});
const getListStyle = (isDraggingOver: any) => ({
  width: 100 + '%',
});

const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const GoodList = ({}: Props) => {
  const dispatch = useDispatch();
  const goods = useSelector(selectGoodsItems);
  const categorys = useSelector(selectCategorysItems);
  const isLoading = useSelector(selectIsGoodsLoading);
  const isLoadingGood = useSelector(selectIsGoodLoading);
  const deleteLoading = useSelector(selectIsDeleteGoodLoading);
  const queryParam = useQuery();
  const history = useHistory();
  const location = useLocation();

  const [id, setId] = useState<ISetId>({
    type: '',
    id: '',
  });

  const [query, setQuery] = useState<ICategory>({
    value: '',
    label: '',
  });

  const [idDeleted, setIdDeleted] = useState<string[]>([]);

  const [_, forceUpdate] = useReducer(x => x + 1, 0);

  const getItems = () =>
    goods
      .map((k, i) => ({
        ...goods[i],
        id: `item-${i}-${new Date().getTime()}`,
      }))
      ?.sort((a, b) => (a.order || 0) - (b.order || 0));

  const [state, setState] = useState<Array<IGood[]>>([]);

  useEffect(() => {
    dispatch(fetchCategorys());
  }, [dispatch, deleteLoading]);

  useEffect(() => {
    if (!query.label) {
      setQuery({
        label: queryParam.get('category') || goods[0]?.category.label || '',
        value: queryParam.get('category') || goods[0]?.category.value || '',
      });
    }
    if (goods?.length) {
      setState([getItems()]);
    }
  }, [goods]);

  useEffect(() => {
    if (!query.label) {
      dispatch(setGoods([]));
      return;
    }
    console.log(decodeURI(query.label));

    dispatch(fetchGoodsByCategory(btoa(encodeURI(query.label))));
  }, [query.value]);

  useEffect(() => {
    if (id.type === 'add') {
      idDeleted.push(id.id);
    } else {
      idDeleted.forEach((idD: string, i: number) => {
        if (idD === id.id) {
          idDeleted.splice(i, 1);
        }
      });
    }
    setIdDeleted(idDeleted);
    forceUpdate();
  }, [id]);

  function onDragEnd(result: any) {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      //@ts-ignore
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      //@ts-ignore
      newState[sInd] = result[sInd];
      //@ts-ignore
      newState[dInd] = result[dInd];

      setState(newState.filter(group => group?.length));
    }
  }

  if (isLoading || isLoadingGood) {
    return (
      <>
        <MetaTags>
          <title>Админка | Загрузка...</title>
        </MetaTags>

        <Header collection={'good'} />

        <Container>
          <Styles.Loading>
            <p>{id.id ? 'Удаление товаров' : 'Загрузка товаров'}</p>
            <LinearProgress />
          </Styles.Loading>
        </Container>
      </>
    );
  }

  function onClickUpdate() {
    state[0].forEach((good, i) => {
      delete good.id;
      const data = {
        ...good,
        order: i,
      };
      dispatch(updateGoodData(data, good._id as string));
    });
  }

  if (!goods?.length) {
    return (
      <>
        <Header collection={'good'} />

        <Container>
          <Styles.Select>
            <Styles.SelectBlock>
              <Styles.Label>{'Категории'}</Styles.Label>
              <Select
                isMulti={false}
                options={categorys}
                onChange={(e: any) => {
                  setQuery(e);
                }}
                placeholder="Выберите категории..."
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </Styles.SelectBlock>
          </Styles.Select>

          <Styles.Loading>
            <h1>Товаров нет, добавьте товары</h1>
            <Link to={`/admin/good/add`}>
              <Button type={'add'} />
            </Link>
          </Styles.Loading>
        </Container>
      </>
    );
  }

  return (
    <>
      <MetaTags>
        <title>Админка | Меню</title>
      </MetaTags>

      <Header
        idDeleted={idDeleted}
        setIdDeleted={setIdDeleted}
        collection={'good'}
      />

      <Container>
        <Styles.Select>
          <Styles.SelectBlock>
            <Styles.Label>
              {query.label.toUpperCase() || 'Категории'}
            </Styles.Label>
            <Select
              isMulti={false}
              options={categorys}
              onChange={(e: any) => {
                setQuery(e);
                queryParam.set('category', e.label);
                history.push(location.pathname + '?' + queryParam.toString());
              }}
              defaultValue={query.label ? query : null}
              placeholder="Выберите категории..."
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </Styles.SelectBlock>
        </Styles.Select>

        <Styles.HeaderGood>
          <Styles.Title>{'Обновить порядок'}</Styles.Title>
          <div>
            <Button
              onClick={onClickUpdate}
              typeButton={'button'}
              type={'update'}
            />
          </div>
        </Styles.HeaderGood>

        <Styles.Flex>
          <DragDropContext onDragEnd={onDragEnd}>
            {state.map((el, ind) => (
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {el.map((item: any, index: number) => (
                      <Draggable
                        key={item._id}
                        draggableId={item._id as string}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Good setId={setId} good={item} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </Styles.Flex>
        <Styles.HeaderGood>
          <Styles.Title>{'Обновить порядок'}</Styles.Title>
          <div>
            <Button
              onClick={onClickUpdate}
              typeButton={'button'}
              type={'update'}
            />
          </div>
        </Styles.HeaderGood>
      </Container>
    </>
  );
};
