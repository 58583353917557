import styled from '@emotion/styled'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export const Category = styled.form`
  margin-top: 120px;
  margin-bottom: 20px;
`
export const InputFileWrapper = styled.div`
  box-shadow: rgba(208, 208, 208, 0.5) 4px 4px 10px;
  border-radius: 5px;
  padding-left: 14px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  img {
    max-width: 400px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
`

export const Title = styled.h1`
  color: #42a5f5;
  margin: 0;
  font-weight: 500;
  margin-bottom: 40px;
`

export const HeaderCategory = styled.div`
  display: flex;
  justify-content: space-between;
  div {
    button {
      margin-left: 10px;
    }
  }
`

export const Loading = styled.div`
  margin-top: 120px;
`

export const ReactQuillStyled = styled(ReactQuill)`
  min-height: 600px;
  margin-bottom: 60px;
  box-shadow: rgba(208, 208, 208, 0.5) 4px 4px 10px;
  .ql-container {
    border: none;
  }
  .ql-toolbar {
    border: none;
    border-bottom: 1px solid #333;
  }
  border-radius: 3px;
  margin-top: 40px;
  img {
    width: 100%;
    max-width: 100%;
    margin: 20px 0;
  }
`
