import React from 'react'
import { Link } from 'react-router-dom'

import * as Styles from './Buttons.styles'

interface Props {
  type?: any
  onClick?: any
  typeButton?: any
  content?: any
  color?: string
  disabled?: boolean
  background?: string
  img?: string
  additionalStyles?: string
  href?: string
  backgroundSize?: string
  backgroundSizeMobile?: string
  hoverBackground?: string
  hoverColor?: string
}

export const Button = ({
  type,
  onClick,
  typeButton = '',
  content,
  color = '',
  background = '',
  additionalStyles = '',
  backgroundSize = '',
  backgroundSizeMobile = '',
  hoverBackground = '',
  href = '',
  hoverColor = '',
  disabled = false,
}: Props) => {
  switch (typeButton) {
    case 'rounded':
      return (
        <Styles.BtnRounded
          background={background}
          backgroundSize={backgroundSize}
          backgroundSizeMobile={backgroundSizeMobile}
          hoverBackground={hoverBackground}
          colorStyle={color}
          type={type}
          onClick={onClick}
          additionalStyles={additionalStyles}
        >
          {content}
        </Styles.BtnRounded>
      )
    case 'normal':
      return (
        <>
          {href ? (
            <Link to={href}>
              <Styles.BtnNormal
                background={background}
                colorStyle={color}
                disabled={disabled}
                hoverBackground={hoverBackground}
                hoverColor={hoverColor}
                type={type}
                onClick={onClick}
                additionalStyles={additionalStyles}
              >
                {content}
              </Styles.BtnNormal>
            </Link>
          ) : (
            <Styles.BtnNormal
              background={background}
              colorStyle={color}
              hoverBackground={hoverBackground}
              hoverColor={hoverColor}
              type={type}
              disabled={disabled}
              onClick={onClick}
              additionalStyles={additionalStyles}
            >
              {content}
            </Styles.BtnNormal>
          )}
        </>
      )
    case 'burger':
      return (
        <>
          {href ? (
            <Link to={href}>
              <Styles.BtnBurger
                background={background}
                colorStyle={color}
                type={type}
                onClick={onClick}
                additionalStyles={additionalStyles}
                backgroundSize={backgroundSize}
                backgroundSizeMobile={backgroundSizeMobile}
                hoverBackground={hoverBackground}
              >
                <span></span>
              </Styles.BtnBurger>
            </Link>
          ) : (
            <Styles.BtnBurger
              background={background}
              colorStyle={color}
              type={type}
              onClick={onClick}
              additionalStyles={additionalStyles}
              backgroundSize={backgroundSize}
              backgroundSizeMobile={backgroundSizeMobile}
              hoverBackground={hoverBackground}
            >
              <span></span>
            </Styles.BtnBurger>
          )}{' '}
        </>
      )
    default:
      return (
        <Styles.BtnDefault type={typeButton} onClick={onClick}>
          default
        </Styles.BtnDefault>
      )
  }
}
