import React from 'react'
import { Booking } from '../../../shared/containers/Booking/'
import { Footer } from '../../../shared/containers/Footer'
import { Map } from '../../../shared/containers/Map'
import { QuestionForm } from '../../../shared/containers/QuestionsForm'
import { Main } from '../components/Main'
import { Terms } from '../components/Terms/Terms'
import MetaTags from 'react-meta-tags'

interface Props {}

import * as Styles from './BonusPage.styles'

export const BonusPage: React.FC = ({}: Props) => {
  return (
    <>
      <MetaTags>
        <title>BRICK GASTRO BAR | Бонусная программа</title>
      </MetaTags>

      <Styles.Wrapper>
        <Main />
        <Terms />
        <QuestionForm />
        <Booking />
        <Map />
        <Footer />
      </Styles.Wrapper>
    </>
  )
}
