import { Action } from 'redux'
import { ICategory } from '../../categorys/contracts/state'
import { CategoryState } from './state'
import { LoadingStatus } from '../../../types'

export enum CategoryActionsType {
  SET_CATEGORY_DATA = 'category/SET_CATEGORY_DATA',
  FETCH_CATEGORY_DATA = 'category/FETCH_CATEGORY_DATA',
  UPDATE_CATEGORY_DATA = 'category/UPDATE_CATEGORY_DATA',
  DELETE_CATEGORY_DATA = 'category/DELETE_CATEGORY_DATA',
  ADD_CATEGORY = 'category/ADD_CATEGORY',
  SET_LOADING_STATE = 'category/SET_LOADING_STATE',
}

export interface SetCategoryDataActionInterface
  extends Action<CategoryActionsType> {
  type: CategoryActionsType.SET_CATEGORY_DATA
  payload: CategoryState['data']
}

export interface FetchCategoryDataActionInterface
  extends Action<CategoryActionsType> {
  type: CategoryActionsType.FETCH_CATEGORY_DATA
  payload: string
}

export interface UpdateCategoryDataActionInterface
  extends Action<CategoryActionsType> {
  type: CategoryActionsType.UPDATE_CATEGORY_DATA
  payload: ICategory
  id: string
}

export interface AddCategoryActionInterface
  extends Action<CategoryActionsType> {
  type: CategoryActionsType.ADD_CATEGORY
  payload: ICategory
}

export interface DeleteCategoryDataActionInterface
  extends Action<CategoryActionsType> {
  type: CategoryActionsType.DELETE_CATEGORY_DATA
  payload: string
}

export interface SetDeleteCategoryDataActionInterface
  extends Action<CategoryActionsType> {
  type: CategoryActionsType.SET_LOADING_STATE
}

export interface SetCategoryLoadingStatusActionInterface
  extends Action<CategoryActionsType> {
  type: CategoryActionsType.SET_LOADING_STATE
  payload: LoadingStatus
}
