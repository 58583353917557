import styled from '@emotion/styled'
import { css } from '@emotion/core'

export const ButtonReset = css`
  margin: 0;
  border: 0;
  padding: 0;
  background: none;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`

export const BtnDefault = styled.button`
  ${ButtonReset}
  padding: 10px 15px;
  border-radius: 5px;
  color: #fff;
  transition: 0.2s;
  background: #fff;
  height: 38px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  &:hover,
  &:active {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -1px rgba(0, 0, 0, 0.2);
  }
`

export const BtnDelete = styled(BtnDefault)`
  background: #ff5252;
  &:hover,
  &:active {
    background: #d50000;
  }
`

export const BtnUpdate = styled(BtnDefault)`
  background: #ff9800;
  &:hover,
  &:active {
    background: #f57c00;
  }
`

export const BtnAdd = styled(BtnDefault)`
  background: #00c853;
  &:hover,
  &:active {
    background: #66bb6a;
  }
`
