import React from 'react'
import { IGood } from '../store/ducks/goods/contracts/state'
import { IPost } from '../store/ducks/posts/contracts/state'
import { ISale } from '../store/ducks/sales/contracts/state'

interface Props {
  isLoadingGoods: boolean
  goodData: IGood[]
  isLoadingSales: boolean
  saleData: ISale[]
  isLoadingPosts: boolean
  postData: IPost[]
}

export const DataContext = React.createContext<Props>({
  isLoadingGoods: false,
  goodData: [],
  isLoadingSales: false,
  saleData: [],
  isLoadingPosts: false,
  postData: [],
})
