import React from 'react';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import { IPost } from '../../../../store/ducks/posts/contracts/state';
import { getMediaLink } from '../../../../utils/mediaHelper';
import * as Styles from './Card.styles';

interface Props {
  post: IPost;
}

export const Card = ({ post }: Props) => {
  const { title, title_photo, title_preview, createdAt, _id } = post;
  const dateToFormat = new Date(createdAt || '');
  return (
    <>
      <Styles.Slide>
        <Link to={`/news/${_id}`}>
          <Styles.ImgWrapper>
            <Styles.Img src={getMediaLink(title_photo)} />
          </Styles.ImgWrapper>
        </Link>
        <Styles.Date>
          <Moment format="DD.MM.YYYY" date={dateToFormat} />
        </Styles.Date>
        <Link style={{ textDecoration: 'none' }} to={`/news/${_id}`}>
          <Styles.Title>{title}</Styles.Title>
        </Link>
        <Styles.Info>{title_preview}</Styles.Info>
        <Styles.LearnMore>
          <Link to={`/news/${_id}`}>Узнать больше</Link>
        </Styles.LearnMore>
      </Styles.Slide>
    </>
  );
};
