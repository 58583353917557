import styled from '@emotion/styled'

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 120px;
`

export const Loading = styled.div`
  margin-top: 100px;
  h1 {
    font-weight: 500;
    color: #42a5f5;
  }
`
