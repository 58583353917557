import React from 'react';
import { IGood } from '../../../../store/ducks/goods/contracts/state';
import { getMediaLink } from '../../../../utils/mediaHelper';
import * as Styles from './PopUp.styles';
interface Props {
  good: IGood;
  setPopup: any;
}
export const PopUp = ({ good, setPopup }: Props) => {
  const { title, description, photo, price } = good;

  const closePopup = (e: any): void => {
    if (e.target?.closest('.card')) return;
    document.body.style.overflow = 'auto';
    setPopup(false);
  };
  return (
    <>
      <Styles.Blackening onClick={closePopup}>
        <Styles.CardWrapper>
          <Styles.Card className="card">
            <Styles.ImgWrapper>
              <Styles.Img src={getMediaLink(photo || '')} />
            </Styles.ImgWrapper>
            <Styles.InfoBlock>
              <Styles.PriceBlock>
                <Styles.Title>{title}</Styles.Title>
                <Styles.Price>{price}₽</Styles.Price>
              </Styles.PriceBlock>
              <Styles.Info>{description}</Styles.Info>
            </Styles.InfoBlock>
          </Styles.Card>
          <Styles.CloseBtn>
            <span></span>
          </Styles.CloseBtn>
        </Styles.CardWrapper>
      </Styles.Blackening>
    </>
  );
};
