import React from 'react'
import { Global as EmotionGlobal, css } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'

export const Global: React.FC = () => {
  const globalStyles = css`
    ${emotionNormalize}
    @import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@100;200;300;400;500;600;700;800;900&display=swap');

    html {
      scroll-behavior: smooth;
    }
    *,
    *:after,
    *:before {
      font-family: 'Commissioner', sans-serif;
      font-weight: 500;
      box-sizing: border-box;
    }
  `

  return <EmotionGlobal styles={globalStyles} />
}
